import * as R from "ramda";

import { Brand } from "../types/brand";

import { LabelMapEntry } from "./map-possible-values";
import { getBrandImagePath } from "./resource-paths";

export const getBrandKeyLogoMap = (activeBrands: Brand[]): LabelMapEntry[] => {
  return activeBrands.map(brand => ({
    key: brand.key,
    imageFilePath: getBrandImagePath(brand)
  }));
};

export const getBrandsLabelMap = (activeBrands: Brand[]): LabelMapEntry[] => {
  return activeBrands.map(
    brand =>
      ({
        key: brand.displayName,
        imageFilePath: getBrandImagePath(brand),
        isHighlightedBrand: brand.highlighted
      }) satisfies LabelMapEntry
  );
};

export const sortBrandsAlphabetically = (brands: Brand[]): Brand[] => R.sortBy(R.prop("key"), brands);
