import React from "react";
import { push } from "connected-react-router";
import { FormikErrors, useFormik } from "formik";
import { keyBy } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";
import * as uuid from "uuid";

import { prependServiceBaseUrl } from "../../../../commons/libs/client-utils";
import { getProduct } from "../../../../commons/libs/content-service";
import { ensureError } from "../../../../commons/libs/error";
import {
  formatDateTime,
  formatShareDefaultFilename,
  formatShareProductDataSheetFilename
} from "../../../../commons/libs/formatters";
import { Product, ProductId, ProductKey, ProductSpecKey } from "../../../../commons/specs/product";
import { ModalProps, PrintMailModalContentProps } from "../../../../commons/types/modal";
import { PdfMetaData } from "../../../../commons/types/pdf";
import { PrintType } from "../../../../commons/types/print-mail";
import * as icons from "../../../../resources/icons";
import actions from "../../../commons/actions";
import config from "../../../commons/config";
import ModalContainerWithStatePartial from "../../../commons/container/Modal/ModalContainerWithStatePartial";
import { useTrackingContext } from "../../../commons/container/utils/tracking-context";
import { compareHash } from "../../../commons/libs/externals/hash";
import useFetchData from "../../../commons/libs/hooks/use-fetch-data";
import { sendEmail } from "../../../commons/libs/services/mail-service/mail";
import { getPdfMetadataFromUrl } from "../../../commons/libs/services/pdf-service/pdf";
import { isEmail } from "../../../commons/libs/validator";
import { ROUTES } from "../../../commons/routes";
import Button from "../../components/Button/Button";
import CheckboxField from "../../components/CheckboxField/CheckboxField";
import FlexLayout from "../../components/FlexLayout/FlexLayout";
import Headline from "../../components/Headline/Headline";
import Hr from "../../components/Hr/Hr";
import Icon from "../../components/Icon/Icon";
import IconButton from "../../components/IconButton/IconButton";
import InputField from "../../components/InputField/InputField";
import Link from "../../components/Link/Link";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import Modal from "../../components/Modal/Modal";
import Paragraph from "../../components/Paragraph/Paragraph";
import PdfPage from "../../components/PdfPage/PdfPage";
import PinField from "../../components/PinField/PinField";
import PrintModalLayout from "../../components/PrintModalLayout/PrintModalLayout";
import PrintPreview from "../../components/PrintPreview/PrintPreview";
import { createPdfFromUrlPreview, createPreviewUrl, createProductDataSheetPreview } from "../../libs/print";
import { selectAssortmentPriceSettings, selectInitializedSettings } from "../../libs/selectors";
import { State } from "../../reducers";
import Field from "../partials/Field";
import FormPartial from "../partials/Form";
import LabeledField from "../partials/LabeledField";
import { connect } from "../utils/loop";

import MailPrivacyModalPartial from "./MailPrivacyModalPartial";

interface FormValues {
  toMail: string;
  subject: string;
  cc: boolean;
  consent: boolean;
  code: string;
}

const mapStateToProps = (state: State) => ({
  isMailActive: selectInitializedSettings(state).mail.active,
  mailSettings: selectInitializedSettings(state).mail,
  personalCode: selectInitializedSettings(state).personalCode ?? "",
  assortmentPriceSettings: selectAssortmentPriceSettings(state)
});

const mapDispatchToProps = {
  onPush: push,
  showToast: actions.toasts.showToast
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps extends ModalProps<PrintMailModalContentProps> {
  nodeRef?: React.Ref<HTMLElement>;
  requestKeyboardClose?: () => void;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const MailModalPartial = ({
  data: printData,
  isMailActive,
  mailSettings,
  personalCode,
  close,
  onPush,
  showToast,
  nodeRef,
  requestKeyboardClose,
  assortmentPriceSettings
}: Props) => {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation(["commons"]);

  const previewUrl = React.useMemo(() => createPreviewUrl(printData), [printData]);

  // An id is generated and sent to the mail service to prevent the user from sending the same mail more than once as happened in BCD-6237.
  const requestNonce = React.useMemo(() => uuid.v1(), []);

  const products = useFetchData<Record<ProductId, Product> | undefined>(async () => {
    if (printData.type === PrintType.ProductDataSheet) {
      return await Promise.all(
        printData.items.map(product => getProduct(product.productId, printData.currency, assortmentPriceSettings))
      ).then(products => keyBy(products, ProductKey.ProductId));
    }
  }, [printData]);

  const { mixpanel } = useTrackingContext();

  const formik = useFormik<FormValues>({
    initialValues: {
      toMail: "",
      subject: "",
      code: "",
      cc: true,
      consent: false
    },
    validateOnMount: true,
    validate: values => {
      const errors: FormikErrors<FormValues> = {};

      if (!isEmail(values.toMail)) {
        errors.toMail = t("commons:mailModalPartialApp.enterValidEmailAddress");
      }

      if (!compareHash(values.code, personalCode)) {
        errors.code = "invalid";
      }

      if (!values.subject) {
        errors.subject = t("commons:mailModalPartialApp.missingSubject");
      }

      if (!values.consent) {
        errors.consent = "invalid";
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting, setTouched }) => {
      if (printData.type === PrintType.ProductDataSheet) {
        printData.items.map(item =>
          mixpanel?.trackProductMailed({ type: printData.type, initiator: printData.initiator }, item.product)
        );
      } else {
        mixpanel?.trackMailed({ type: printData.type });
      }

      requestKeyboardClose?.();
      close();

      try {
        await sendEmail(
          {
            to: values.toMail,
            from: {
              name: mailSettings.fromName ?? "",
              email: "noreply@bike.center"
            },
            replyTo: {
              name: mailSettings.fromName ?? "",
              email: mailSettings.fromMail ?? ""
            },
            subject: values.subject,
            text: mailSettings.message ?? "",
            files:
              printData.type === PrintType.ProductDataSheet
                ? printData.items.map(product => ({
                    name: products?.data ? formatShareProductDataSheetFilename(products.data[product.productId]) : "",
                    url: createProductDataSheetPreview(product, printData)
                  }))
                : printData.items.map(item => ({
                    name: formatShareDefaultFilename(item),
                    url: createPdfFromUrlPreview(item)
                  })),
            ...(mailSettings.ccActive && values.cc
              ? {
                  cc: mailSettings.ccTo
                }
              : {})
          },
          requestNonce
        );

        showToast({
          title:
            printData.type === PrintType.ProductDataSheet
              ? t("commons:mailModalPartialApp.shareDataSheetEmailSuccessTitle", { count: printData.items.length })
              : t("commons:mailModalPartialApp.shareEmailSuccessTitle"),
          timeout: 3,
          icon: icons.IconSmallSendArrow
        });
      } catch (error) {
        showToast({
          title: t("commons:mailModalPartialApp.errorSendingEmailTitle"),
          timeout: 5,
          icon: icons.IconSmallSendArrow,
          kind: "negative",
          primaryAction: {
            label: t("commons:mailModalPartialApp.moreInformationButton"),
            callback: () =>
              actions.modal.openErrorModal({
                type: "mail",
                errorMessage: ensureError(error).message,
                headline: t("commons:mailModalPartialApp.moreInformationAboutErrorHeadline"),
                paragraph: t("commons:mailModalPartialApp.documentDeliveryError")
              })
          }
        });

        mixpanel?.trackMailFailed();
      }

      setSubmitting(false);
      setTouched({});
    }
  });

  const previewPdf = useFetchData<PdfMetaData>(
    () => (previewUrl ? getPdfMetadataFromUrl(previewUrl) : Promise.reject()),
    [previewUrl]
  );

  React.useEffect(() => {
    if (printData.type === PrintType.ProductDataSheet && printData.subject) {
      formik.setFieldValue(
        "subject",
        t("commons:mailModalPartialApp.formDefaultValues.bookmarkSubject", {
          subject: printData.subject
        })
      );
    } else if (printData.type === PrintType.ProductDataSheet && products.data) {
      formik.setFieldValue(
        "subject",
        t("commons:mailModalPartialApp.formDefaultValues.productDataSheetSubject", {
          brandName: products.data[printData.items[0].productId][ProductSpecKey.BrandName],
          modelName: products.data[printData.items[0].productId][ProductSpecKey.ModelName]
        })
      );
    } else if (printData.type === PrintType.Default) {
      formik.setFieldValue(
        "subject",
        t("commons:mailModalPartialApp.formDefaultValues.defaultSubject", {
          title: printData.items[0].title,
          date: formatDateTime(printData.items[0].date, locale)
        })
      );
    }
    // formik and t function must not be deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products.data, printData]);

  React.useEffect(() => {
    if (formik.values.code.length === config.shared.personalCodeLength) {
      formik.setFieldTouched("code");
      requestKeyboardClose && requestKeyboardClose();
    }
    // formik and keyboard close function must not be deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.code]);

  const printTypeTitle = {
    [PrintType.ProductDataSheet as string]: t("commons:mailModalPartialApp.shareDataSheetEmailTitle", {
      count: printData.items.length
    }),
    [PrintType.Default as string]: t("commons:mailModalPartialApp.shareEmailTitle")
  };

  const pdfPreviewSource =
    !previewPdf.isLoading && !!previewPdf.data?.pages && previewPdf.data?.pages.length > 0
      ? prependServiceBaseUrl(previewPdf.data.pages[0])
      : "";

  return (
    <Modal
      nodeRef={nodeRef}
      width="xl"
      closeButton={<IconButton onClick={() => close()} icon={<Icon source={icons.IconSmallCross} />} />}
    >
      <PrintModalLayout
        printPreview={
          <PrintPreview loaded={!previewPdf.isLoading}>
            <PdfPage source={pdfPreviewSource} />
          </PrintPreview>
        }
      >
        {isMailActive ? (
          <>
            <Headline kind="m" classNames={["u-space-l"]}>
              {printTypeTitle[printData.type]}
            </Headline>
            <FormPartial formik={formik}>
              <LabeledField
                formik={formik}
                classNames={["u-space-base"]}
                label={t("commons:mailModalPartialApp.customerEmailAddressLabel")}
              >
                <InputField name="toMail" />
              </LabeledField>
              <Paragraph classNames={["u-space-base"]}>
                {t("commons:mailModalPartialApp.emailDataUsageNotice")}
              </Paragraph>
              <LabeledField
                formik={formik}
                classNames={["u-space-base"]}
                label={t("commons:mailModalPartialApp.subjectLabel")}
              >
                <InputField name="subject" />
              </LabeledField>
              {!!mailSettings?.ccActive && (
                <Field formik={formik}>
                  <CheckboxField
                    name="cc"
                    classNames={["u-space-base"]}
                    label={t("commons:mailModalPartialApp.copyToSenderLabel")}
                  />
                </Field>
              )}
              <Hr classNames={["u-space-base"]} />
              <LabeledField
                formik={formik}
                hideErrorText
                classNames={["u-space-l"]}
                label={t("commons:mailModalPartialApp.authorizeWithCodeLabel")}
              >
                <PinField name="code" length={config.shared.personalCodeLength} />
              </LabeledField>
              <ModalContainerWithStatePartial
                modal={(_isOpen, close) => (
                  <MailPrivacyModalPartial onClose={close} backButtonLabel={printTypeTitle[printData.type]} />
                )}
              >
                {(_isOpen, open) => (
                  <FlexLayout>
                    <Field formik={formik}>
                      <CheckboxField
                        classNames={["u-space-l"]}
                        name="consent"
                        label={
                          <Trans
                            t={t}
                            i18nKey="commons:mailModalPartialApp.privacyPolicyNotedLabel"
                            components={[
                              <Link key="privacy-policy" onClick={open}>
                                {/* This text is only a placeholer, which will never be used.
                                 * This is one of two ways to translate text, which includes components,
                                 * that have both attributes and children. */}
                                Privacy Policy
                              </Link>
                            ]}
                          />
                        }
                      />
                    </Field>
                  </FlexLayout>
                )}
              </ModalContainerWithStatePartial>
              <Button
                type="submit"
                disabled={
                  formik.isSubmitting ||
                  !formik.isValid ||
                  !formik.values ||
                  formik.values?.code?.length !== config.shared.personalCodeLength
                }
                variant="accent"
                kind="solid"
                icon={formik.isSubmitting ? <LoadingIndicator size="s" /> : <Icon source={icons.IconSmallSendArrow} />}
                iconRight
              >
                {t("commons:mailModalPartialApp.sendNowButton")}
              </Button>
            </FormPartial>
          </>
        ) : (
          <>
            <Headline kind="m" classNames={["u-space-l"]}>
              {t("commons:mailModalPartialApp.noEmailDeliveryConfiguredHeadline")}
            </Headline>
            <span>
              <Button
                variant="accent"
                kind="solid"
                icon={<Icon source={icons.IconSmallArrowRight} />}
                iconRight
                onClick={() => {
                  close({ preventReopenPreviousModal: true });
                  onPush(ROUTES.SETTINGS.MAIL);
                }}
              >
                {t("commons:mailModalPartialApp.configureEmailDeliveryButton")}
              </Button>
            </span>
          </>
        )}
      </PrintModalLayout>
    </Modal>
  );
};

export default connector(MailModalPartial);
