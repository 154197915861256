import React from "react";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";

import { RangeFilterValue } from "../../../../commons/specs/filters";
import { ForkSuspensionFilter, ForkSuspensionTravel } from "../../../../commons/types/fork-suspension";
import ForkSuspension from "../../components/ForkSuspension/ForkSuspension";
import ForkSuspensionFilterLayout, {
  ForkSuspensionFilterElement
} from "../../components/ForkSuspensionFilterLayout/ForkSuspensionFilterLayout";
import RadioListItem from "../../components/RadioListItem/RadioListItem";
import useOnMount from "../../libs/hooks/use-on-mount";

interface InnerProps {
  onChange: Function;
  rangeFrom: number;
  rangeTo: number;
  forkSuspensionRangeValue: RangeFilterValue;
}

const ForkSuspensionTravelFilterModalPartial = ({
  onChange,
  rangeFrom,
  rangeTo,
  forkSuspensionRangeValue
}: InnerProps) => {
  const { t } = useTranslation(["commons"]);
  const allFilter: ForkSuspensionFilter = {
    label: t("commons:filterModalPartialProductFinder.forkSuspensionTravel.allLabel"),
    range: [rangeFrom, rangeTo]
  };

  // The order of the filters is essential. ForkSuspensionFilterLayout will put them into a two-column grid with grid-flow: column.
  // That means the first filter is placed in the upper-left corner, and the rest is filled down the column until it reaches the column end.
  // It will continue with the next column.
  const filters: ForkSuspensionFilter[] = [
    {
      label: t("commons:filterModalPartialProductFinder.forkSuspensionTravel.noSuspensionLabel"),
      range: [0, 0],
      travel: ForkSuspensionTravel.None
    },
    {
      label: "< 80mm",
      range: [rangeFrom, 79],
      travel: ForkSuspensionTravel.Small
    },
    {
      label: "> 80mm",
      range: [80, rangeTo],
      travel: ForkSuspensionTravel.Small
    },
    {
      label: "> 130mm",
      range: [130, rangeTo],
      travel: ForkSuspensionTravel.Medium
    },
    {
      label: "> 160mm",
      range: [160, rangeTo],
      travel: ForkSuspensionTravel.Medium
    },
    {
      label: "> 200mm",
      range: [200, rangeTo],
      travel: ForkSuspensionTravel.Large
    }
  ];

  const allFilters: ForkSuspensionFilter[] = [...filters, allFilter];

  const allSuspensionTravelsArray = Object.values(ForkSuspensionTravel);
  const allFiltersAreActive: boolean = isEqual(forkSuspensionRangeValue, allFilter.range);

  const [suspensionTravelRotationIndex, setSuspensionTravelRotationIndex] = React.useState<number>(0);

  useOnMount(() => {
    let intervalId: ReturnType<typeof setInterval>;

    if (allFiltersAreActive) {
      intervalId = setInterval(() => {
        setSuspensionTravelRotationIndex(current => {
          if (current < allSuspensionTravelsArray.length) {
            return current + 1;
          } else {
            return 0;
          }
        });
      }, 1300); // Total animation time of ForkSuspension
    }

    return () => {
      clearInterval(intervalId);
    };
  });

  const calculateTravel = (filters: ForkSuspensionFilter[], forkSuspensionRangeValue: RangeFilterValue) => {
    return filters.find(filter => isEqual(filter.range, forkSuspensionRangeValue))?.travel ?? ForkSuspensionTravel.None;
  };

  const renderFilters: ForkSuspensionFilterElement[] = filters.map((filter, index) => (
    <RadioListItem
      label={filter.label}
      key={`ForkSuspensionFilterElement-${index}`}
      active={isEqual(filter.range, forkSuspensionRangeValue)}
      onClick={() => onChange(filter.range)}
      outline
    />
  ));

  const renderAllFilter: ForkSuspensionFilterElement = (
    <RadioListItem
      label={allFilter.label}
      active={isEqual(allFilter.range, forkSuspensionRangeValue)}
      onClick={() => onChange(allFilter.range)}
      outline
    />
  );

  return (
    <ForkSuspensionFilterLayout
      filters={renderFilters}
      allFilter={renderAllFilter}
      forkSuspension={
        <ForkSuspension
          travel={
            allFiltersAreActive
              ? allSuspensionTravelsArray[suspensionTravelRotationIndex]
              : calculateTravel(allFilters, forkSuspensionRangeValue)
          }
        />
      }
    />
  );
};

export default ForkSuspensionTravelFilterModalPartial;
