import React from "react";
import { noop } from "lodash";
import ReactDOM from "react-dom";
import { PortalWithState } from "react-portal";

import { Component } from "../../../../commons/types/component";
import { IconSmallAngleDown, IconSmallInputClear } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

import SelectCtaButton from "./SelectCtaButton";
import SelectMenuItem, { ClickHandler } from "./SelectMenuItem";

interface Props extends Component {
  children:
    | React.ReactElement<React.ComponentProps<typeof SelectMenuItem>>
    | Array<React.ReactElement<React.ComponentProps<typeof SelectMenuItem>>>;
  value?: any;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  fixed?: boolean;
  icon?: React.ReactNode;
  ctaButtons?: (closePortal: () => void) => React.ReactElement<React.ComponentProps<typeof SelectCtaButton>>;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  onChange?: ClickHandler;
  onResetSelection?: ClickHandler;
  onClick?: ClickHandler;
  withResetSelectionButton?: boolean;
  showName?: boolean;
  minContentWidthForMenuItems?: boolean;
}

interface State {
  flyoutStyle?: React.CSSProperties;
}

class SelectField extends React.Component<Props, State> {
  public static defaultProps: Partial<Props> = {
    placeholder: "",
    error: false
  };

  public state: Readonly<State> = {
    flyoutStyle: undefined
  };

  private flyoutDom?: HTMLElement | null;

  private fieldDOM?: HTMLElement | null;

  private resetButtonDOM?: HTMLElement | null;

  private closePortal?: () => void;

  public componentDidMount() {
    document.addEventListener("touchstart", this.onGlobalTouch, false);
  }

  public componentWillUnmount() {
    document.removeEventListener("touchstart", this.onGlobalTouch, false);
  }

  public render() {
    const {
      children,
      classNames = [],
      value = "",
      name = "",
      placeholder = "",
      disabled = false,
      error = false,
      fixed = false,
      icon = null,
      ctaButtons = () => null,
      onBlur = noop,
      onResetSelection = noop,
      onClick = noop,
      withResetSelectionButton = false,
      showName = false
    } = this.props;
    const { flyoutStyle } = this.state;

    let label: React.ReactNode = null;
    React.Children.forEach(children, (item: React.ReactElement<React.ComponentProps<typeof SelectMenuItem>>) => {
      if (item.props.value === value) {
        label = item.props.label;
      }
    });

    return (
      <PortalWithState closeOnEsc closeOnOutsideClick onOpen={this.onMenuOpen} onClose={this.onMenuClose}>
        {({ openPortal, closePortal, isOpen, portal }) => [
          <div
            className={cn(
              "SelectField",
              [
                {
                  selected: !!value,
                  disabled,
                  error,
                  isOpen,
                  withResetSelectionButton,
                  withIcon: !!icon,
                  showName
                }
              ],
              classNames
            )}
            onClick={onClick}
            key={name}
          >
            <button
              type="button"
              key="button"
              className="SelectField__button"
              ref={d => {
                this.fieldDOM = d;
                this.closePortal = closePortal;
              }}
              name={name}
              onClick={event => (isOpen ? closePortal() : openPortal(event))}
              onBlur={onBlur}
              disabled={this.props.disabled}
            >
              {icon && <span className="SelectField__icon">{icon}</span>}
              {value ? label : <span className="SelectField__placeholder">{placeholder}</span>}
              <span className="SelectField__arrow">
                <Icon source={IconSmallAngleDown} />
              </span>
            </button>
            {showName && <div className="SelectField__name">{name}</div>}

            {withResetSelectionButton && (
              <button
                ref={d => (this.resetButtonDOM = d)}
                onClick={onResetSelection}
                className="SelectField__reset-button"
                type="button"
              >
                <Icon source={IconSmallInputClear} />
              </button>
            )}
          </div>,
          portal(
            <div
              className={cn("SelectField__flyout", [{ fixed }])}
              style={flyoutStyle}
              key="flyout"
              ref={d => {
                this.flyoutDom = d;
              }}
            >
              <div className="SelectField__ctaButtons">{ctaButtons(closePortal)}</div>
              <ul className="SelectField__menu">
                {React.Children.map(
                  children,
                  (item: React.ReactElement<React.ComponentProps<typeof SelectMenuItem>>, index) => (
                    <li>
                      {React.cloneElement(item, {
                        key: index,
                        onClick: (v => {
                          closePortal();
                          this.handleMenuClick(v);
                        }) as ClickHandler,
                        selected: value && item.props.value === value
                      })}
                    </li>
                  )
                )}
              </ul>
            </div>
          )
        ]}
      </PortalWithState>
    );
  }

  private onGlobalTouch: EventListener = event => {
    if (
      (this.fieldDOM && this.fieldDOM.contains(event.target as HTMLElement)) ||
      (this.flyoutDom && this.flyoutDom.contains(event.target as HTMLElement))
    ) {
      return;
    }

    if (this.closePortal) {
      this.closePortal();
    }
  };

  private onMenuOpen = () => {
    // TODO: BCD-7143 SelectField - `React.findDOMNode` muss ersetzt werden
    const selectDOM = ReactDOM.findDOMNode(this); // eslint-disable-line react/no-find-dom-node
    if (selectDOM instanceof HTMLElement && this.flyoutDom) {
      const fixed = this.props.fixed;
      const flyoutSize = this.flyoutDom.getBoundingClientRect();
      const selectSize = selectDOM.getBoundingClientRect();
      const resetButtonSize = this.resetButtonDOM?.getBoundingClientRect();

      const width =
        this.props.withResetSelectionButton && resetButtonSize
          ? selectSize?.width - resetButtonSize?.width
          : selectSize?.width;

      // Open the menu above the select field if there is not enough space
      // to the bottom
      const openAbove = selectSize.top + selectSize.height + flyoutSize.height > window.innerHeight;

      this.setState({
        flyoutStyle: {
          top: openAbove
            ? selectSize.top - flyoutSize.height + (fixed ? 0 : window.pageYOffset)
            : selectSize.top + selectSize.height + (fixed ? 0 : window.pageYOffset),
          left: selectSize.left + (fixed ? 0 : window.scrollX),
          ...(this.props.minContentWidthForMenuItems ? { minWidth: width } : { width })
        }
      });
    }
  };

  private onMenuClose = () => {
    this.setState({
      flyoutStyle: undefined
    });
  };

  private handleMenuClick: ClickHandler = value => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };
}

export default SelectField;
