import React from "react";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";

import { getBrandKeyLogoMap } from "../../../../commons/libs/brands";
import { getCustomerGroupConfig } from "../../../../commons/libs/config";
import { getProducts } from "../../../../commons/libs/content-service";
import { waitPromise } from "../../../../commons/libs/promise";
import { getProductVariants } from "../../../../commons/libs/specs";
import { getSpecConfig } from "../../../../commons/specs";
import { CustomBicycleFilterKey } from "../../../../commons/specs/bicycle";
import { FilterConfigConstKeys, FilterGroupsConstKeys, FilterKey } from "../../../../commons/specs/filters";
import { ProductSpecKey, ProductType } from "../../../../commons/specs/product";
import { AssortmentType } from "../../../../commons/types/assortment";
import { GlobalLocationState } from "../../../../commons/types/location";
import { connect } from "../../../commons/container/utils/loop";
import actions from "../../actions";
import CenteredContent from "../../components/CenteredContent/CenteredContent";
import DistributedContent from "../../components/DistributedContent/DistributedContent";
import Headline from "../../components/Headline/Headline";
import ItemList from "../../components/ItemList/ItemList";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import MultiColumnItemList from "../../components/MultiColumnItemList/MultiColumnItemList";
import config from "../../config";
import ModalContainerWithStatePartial from "../../container/Modal/ModalContainerWithStatePartial";
import FilterModalPartial from "../../container/ProductFinder/FilterModalPartial";
import { getProductFiltersFromActiveFilters, hasActiveFilters, isFilterItemActive } from "../../libs/filters";
import useFetchBrands from "../../libs/hooks/use-fetch-brands";
import useFetchData from "../../libs/hooks/use-fetch-data";
import useGetFilterKeysWithInsufficientFilterValues from "../../libs/hooks/use-get-filter-keys-with-insufficient-filter-values";
import useOnMount from "../../libs/hooks/use-on-mount";
import useProductFilter from "../../libs/hooks/use-product-filter";
import useSelectedBrands from "../../libs/hooks/use-selected-brands";
import useSizingAvailability from "../../libs/hooks/use-sizing-availability";
import useVeloconnectEndpoints from "../../libs/hooks/use-veloconnect-endpoints";
import * as selectors from "../../libs/selectors";
import { State } from "../../reducers";
import { ROUTES } from "../../routes";
import { VeloconnectEndpointsProvider } from "../utils/veloconnect-endpoints-context";

import DetailListItemTooltipPartial from "./DetailListItemTooltipPartial";
import FooterContentPartial from "./FooterContentPartial";

const mapStateToProps = (state: State) => ({
  customization: selectors.selectInitializedSettings(state).customization,
  currency: selectors.selectInitializedSettings(state).currency,
  assortment: selectors.selectInitializedSettings(state).assortment,
  assortmentFilterSettings: selectors.selectAssortmentFilterSettings(state),
  assortmentPriceSettings: selectors.selectAssortmentPriceSettings(state),
  activeBrands: state.brands.active,
  bodySizingEnabled: selectors.selectIsBodySizingEnabled(state),
  bodySizingNotificationsEnabled: selectors.selectIsBodySizingNotificationsEnabled(state),
  customerGroup: getCustomerGroupConfig(selectors.selectInitializedEnv(state).customerGroup)
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OuterProps) => ({
  onError: bindActionCreators(actions.error.set, dispatch),
  onSubmit: () => {
    dispatch(
      push(ROUTES.PRODUCT_FINDER.INDEX, {
        ...(ownProps.location.state || {}),
        goBackToDetails: true
      })
    );
  }
});

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps extends RouteComponentProps<{}, {}, GlobalLocationState> {
  isOffline: boolean;
  sizingAvailability: ReturnType<typeof useSizingAvailability>;
  productFilter: ReturnType<typeof useProductFilter>;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const DetailsContent = ({
  activeBrands,
  onSubmit,
  customization,
  assortment,
  assortmentFilterSettings,
  currency,
  bodySizingEnabled,
  bodySizingNotificationsEnabled,
  isOffline,
  sizingAvailability,
  productFilter,
  customerGroup
}: Props) => {
  const { t, i18n } = useTranslation(["commons"]);

  /** TODO: Get productType from Product.
   * @see BCD-6701 Integrate motorcycles into content service
   */
  const productType = customerGroup.productTypes[0];
  const specConfig = getSpecConfig(productType);

  const { fetchActiveBrands } = useFetchBrands();

  const veloconnectEndpoints = useVeloconnectEndpoints();

  useOnMount(() => {
    fetchActiveBrands();
  });

  const [isAnyFilterModalOpen, setIsAnyFilterModalOpen] = React.useState(false);
  const { selectedBrands } = useSelectedBrands({ activeBrands, activeFilters: productFilter.activeFilters });
  const filterKeysWithInsufficientFilterValues = useGetFilterKeysWithInsufficientFilterValues({
    selectedBrands,
    activeFilters: productFilter.activeFilters
  });

  const products = useFetchData(async () => {
    const filters = getProductFiltersFromActiveFilters(productFilter.activeFilters, specConfig.filterConfig);
    const minLoadingTimeout = waitPromise(config.shared.minLoadingTimeout);
    const products = getProducts(currency, assortment?.priceSettings, filters, undefined);

    // Keep the loading state for a minimum amount of time
    await Promise.all([minLoadingTimeout, products]);

    return products;
  }, [productFilter.activeFilters, currency, activeBrands]);

  const variantsLength = React.useMemo(
    () => (products?.data?.results ?? []).flatMap(getProductVariants).length,
    [products?.data?.results]
  );

  const renderGroups = () =>
    specConfig.filterGroups.map(group => (
      <React.Fragment key={group.key}>
        <Headline>
          {t(
            `commons:productFilterConfig.${productType}.productFilterGroup.${group.key}` as keyof {
              [T in ProductType as `commons:productFilterConfig.${T}.productFilterGroup.${FilterGroupsConstKeys<T>}`]: null;
            }
          )}
        </Headline>
        <ItemList>
          {group.filters
            .filter(key => key !== ProductSpecKey.Price || customization?.showPrices)
            // Hide assortment filter if device has no current assortment
            .filter(key => key !== ProductSpecKey.ManualAssortment || assortment?.type === AssortmentType.Manual)
            .filter(
              key =>
                key !== ProductSpecKey.AutomaticAssortment ||
                (assortment?.type === AssortmentType.Automatic &&
                  !assortmentFilterSettings.hideAutomaticAssortmentFilter)
            )
            .filter(
              key =>
                (key as FilterKey<ProductType.Bicycle>) !== CustomBicycleFilterKey.Sizing ||
                bodySizingEnabled ||
                bodySizingNotificationsEnabled
            )
            .map(filterKey => {
              const filterConfig = specConfig.filterConfig[filterKey];

              return filterConfig ? (
                <ModalContainerWithStatePartial
                  animationDuration={config.shared.transitionsDurations.fast}
                  key={filterKey}
                  modal={(isOpen, close) => (
                    <FilterModalPartial
                      isProductLoading={products.isLoading}
                      isOpen={isOpen}
                      productType={productType}
                      filterKey={filterKey}
                      filterConfig={filterConfig}
                      filterValues={productFilter.filterValues}
                      activeFilters={productFilter.activeFilters}
                      variantsLength={variantsLength}
                      activeBrandsKeyMap={getBrandKeyLogoMap(activeBrands)}
                      onFilterChange={productFilter.setActiveFilters}
                      onClose={close}
                      onOpenChange={setIsAnyFilterModalOpen}
                    />
                  )}
                >
                  {(_isOpen, open) => {
                    const titleKey =
                      `commons:productFilterConfig.${productType}.productFilter.${filterKey}.title` as keyof {
                        [T in ProductType as `commons:productFilterConfig.${T}.productFilter.${FilterConfigConstKeys<T>}.title`]: null;
                      };

                    const descriptionKey =
                      `commons:productFilterConfig.${productType}.productFilter.${filterKey}.description` as keyof {
                        [T in ProductType as `commons:productFilterConfig.${T}.productFilter.${FilterConfigConstKeys<T>}.description`]: null;
                      };

                    const title = t(titleKey);
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Description is optional
                    // @ts-ignore
                    const description: string = i18n.exists(descriptionKey) ? t(descriptionKey) : "";

                    return (
                      <DetailListItemTooltipPartial
                        filterKey={filterKey}
                        filterConfig={filterConfig}
                        filterValues={productFilter.filterValues}
                        selectedBrands={selectedBrands}
                        activeFilters={productFilter.activeFilters}
                        filterKeysWithInsufficientFilterValues={filterKeysWithInsufficientFilterValues}
                        label={title}
                        description={description}
                        active={
                          filterKey === ProductSpecKey.AutomaticAssortment
                            ? isFilterItemActive<ProductType>(
                                ProductSpecKey.VeloconnectAssortment,
                                filterConfig,
                                productFilter.activeFilters
                              ) || isFilterItemActive<ProductType>(filterKey, filterConfig, productFilter.activeFilters)
                            : isFilterItemActive<ProductType>(filterKey, filterConfig, productFilter.activeFilters)
                        }
                        onClick={open}
                        variantsLength={variantsLength}
                        isOffline={isOffline}
                        preventTooltip={isAnyFilterModalOpen}
                        disabledReason={
                          (filterKey as FilterKey<ProductType.Bicycle>) === CustomBicycleFilterKey.Sizing &&
                          bodySizingEnabled &&
                          !sizingAvailability.available
                            ? t("commons:detailsContentProductFinderOverview.sizingServiceUnavailable")
                            : undefined
                        }
                      />
                    );
                  }}
                </ModalContainerWithStatePartial>
              ) : null;
            })}
        </ItemList>
      </React.Fragment>
    ));

  return (
    <VeloconnectEndpointsProvider endpoints={veloconnectEndpoints}>
      <DistributedContent
        footer={
          <FooterContentPartial
            isLoading={products.isLoading}
            activeFilters={productFilter.activeFilters}
            resultCount={products.data?.totalFiltered ?? 0}
            onFilterChange={productFilter.setActiveFilters}
            onFilterReset={() => {
              productFilter.setActiveFilters({});
            }}
            onSubmit={onSubmit}
            isFilterResetButtonDisabled={!hasActiveFilters(productFilter.activeFilters)}
          />
        }
      >
        <CenteredContent
          childrenWidth={productFilter.isLoading ? "auto" : "full"}
          classNames={["u-padding-top-xxxl", "u-space-xxl-media-s-to-l"]}
        >
          {productFilter.isLoading ? (
            <LoadingIndicator classNames={["u-padding-top-xxxxl"]} key="loading-indicator" />
          ) : (
            <MultiColumnItemList>{renderGroups()}</MultiColumnItemList>
          )}
        </CenteredContent>
      </DistributedContent>
    </VeloconnectEndpointsProvider>
  );
};

export default connector(DetailsContent);
