import { getManualAssortment as getManualAssortmentLib, getPackages } from "../../../../commons/libs/content-service";
import { getEnv as getEnvLib } from "../../../../commons/libs/env-service";
import actions from "../../actions";

import useDispatch from "./use-dispatch";
import useFetchBrands from "./use-fetch-brands";

const useInitializeCore = () => {
  const dispatch = useDispatch();

  const initializeNetwork = () => dispatch(actions.network.init());
  const setError = (error: Error) => dispatch(actions.error.set(error));

  const handleInitFailed = (error: Error) => {
    initializeNetwork();
    setError(error);
  };

  const { fetchAvailableBrands, fetchActiveBrands } = useFetchBrands();

  const fetchAllSettings = () => dispatch(actions.settings.fetchAll());

  const getEnv = () =>
    getEnvLib()
      .then(env => dispatch(actions.core.envReceived(env)))
      .catch(handleInitFailed);

  const getContentPackages = () =>
    getPackages()
      .then(packages => dispatch(actions.core.contentPackagesReceived(packages)))
      .catch(handleInitFailed);

  const getManualAssortment = () =>
    getManualAssortmentLib()
      .then(manualAssortment => dispatch(actions.core.manualAssortmentReceived(manualAssortment)))
      .catch(handleInitFailed);

  const initializeCore = async () => {
    // TODO: Remove await from action calls when loop is removed from the respective reducers
    await getEnv();
    await fetchAllSettings();
    await getContentPackages();
    await getManualAssortment();
    await fetchAvailableBrands();
    await fetchActiveBrands();
  };

  return { initializeCore };
};

export default useInitializeCore;
