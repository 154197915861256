import { routerMiddleware } from "connected-react-router";
import { createMemoryHistory, History } from "history";
import { applyMiddleware, compose, createStore, Middleware, Store, StoreEnhancer } from "redux";
import { createLogger } from "redux-logger";
import { install, StoreCreator } from "redux-loop";

import envelope from "../../commons/libs/externals/envelope";

import createRootReducer, { State } from "./reducers";

const enhancedCreateStore = createStore as StoreCreator;

const store = (history: History = createMemoryHistory(), trackingMiddleware: Middleware): Store<{}> => {
  const middlewares = [routerMiddleware(history), trackingMiddleware];

  if (!envelope.isProductionBuild) {
    const logger = createLogger({ collapsed: true });
    middlewares.push(logger);
  }

  let enhancer: StoreEnhancer<State> = compose(
    install({ DONT_LOG_ERRORS_ON_HANDLED_FAILURES: true }),
    applyMiddleware(...middlewares)
  );

  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancer = compose(enhancer, window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return enhancedCreateStore(createRootReducer(history), undefined, enhancer);
};

export default store;
