import React from "react";
import { noop } from "lodash";

import useEffectAfterMount from "../../libs/hooks/use-effect-after-mount";

import ModalContainer, { Animation, Position as ModalContainerPosition } from "./ModalContainer";

const renderProps = (element: React.ReactNode | Function, ...props: any[]) =>
  typeof element === "function" ? element(...props) : element;

interface Props {
  children?: React.ReactNode | ((isOpen: boolean, open: () => void) => React.ReactNode);
  modal: React.ReactNode | ((isOpen: boolean, close: () => void) => React.ReactNode);
  rootNode?: HTMLElement;
  position?: ModalContainerPosition;
  hideBackground?: boolean;
  keyboardOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  animation?: Animation;
  animationDuration?: number;
}

const ModalContainerWithState = ({
  children,
  modal,
  rootNode,
  position = "center",
  hideBackground = false,
  keyboardOpen = false,
  onOpenChange = noop,
  animation = "fade-in",
  animationDuration
}: Props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const open = () => setIsOpen(true);

  useEffectAfterMount(() => {
    onOpenChange(isOpen);
  }, [isOpen]);

  return (
    <>
      {renderProps(children, isOpen, open)}
      <ModalContainer
        animationDuration={animationDuration}
        animation={animation}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        hideBackground={hideBackground}
        keyboardOpen={keyboardOpen}
        position={position}
        rootNode={rootNode}
      >
        {modal}
      </ModalContainer>
    </>
  );
};

export default ModalContainerWithState;
