import React from "react";
import { replace } from "connected-react-router";
import { keyBy } from "lodash";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";
import { Route, RouteChildrenProps, useLocation, useParams } from "react-router-dom";

import { getProduct } from "../../../../commons/libs/content-service";
import { getSpecFormatter } from "../../../../commons/libs/formatters";
import { getBrandImageUrl } from "../../../../commons/libs/resource-paths";
import { getFirstVariantId, getSingleSpecValue, getStandaloneProductVariant } from "../../../../commons/libs/specs";
import { getSpecConfig } from "../../../../commons/specs";
import { Product, ProductId, ProductKey, ProductSpecKey } from "../../../../commons/specs/product";
import { Brand } from "../../../../commons/types/brand";
import { GlobalLocationState } from "../../../../commons/types/location";
import * as icons from "../../../../resources/icons";
import { useTrackingContext } from "../../../commons/container/utils/tracking-context";
import { buildPath } from "../../../commons/libs/path";
import { decodeIdFromMatch, getNeighbouringProducts } from "../../../commons/libs/products";
import actions from "../../actions";
import AdvertisementLayout from "../../components/AdvertisementLayout/AdvertisementLayout";
import BrandImage from "../../components/BrandImage/BrandImage";
import CenteredPageLayout from "../../components/CenteredPageLayout/CenteredPageLayout";
import FlexLayout from "../../components/FlexLayout/FlexLayout";
import Icon from "../../components/Icon/Icon";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import MainContentLayout from "../../components/MainContentLayout/MainContentLayout";
import NextPrevNavigationButton from "../../components/NextPrevNavigationButton/NextPrevNavigationButton";
import SwipeableContent, { TransitionDirection } from "../../components/SwipeableContent/SwipeableContent";
import TabBarPageLayout from "../../components/TabBarPageLayout/TabBarPageLayout";
import useFetchData from "../../libs/hooks/use-fetch-data";
import useVeloconnectEndpoint, { UseVeloconnectEndpointReturn } from "../../libs/hooks/use-veloconnect-endpoint";
import { selectAssortmentPriceSettings, selectInitializedSettings } from "../../libs/selectors";
import { State } from "../../reducers";
import { ROUTES } from "../../routes";
import { connect } from "../utils/loop";
import { VeloconnectEndpointProvider } from "../utils/veloconnect-endpoint-context";

import ProductDetailsImagePartial from "./ProductDetailsImagePartial";

const mapStateToProps = (state: State) => ({
  currency: selectInitializedSettings(state).currency,
  activeBrands: state.brands.active,
  assortmentPriceSettings: selectAssortmentPriceSettings(state)
});

const mapDispatchToProps = {
  onReplace: replace,
  onError: actions.error.set
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface ProductDetailsPartialRenderProps {
  productId: ProductId;
  variantId: ProductId | undefined;
  product: Product | undefined;
  productInitializingFirstTime: boolean;
  brand: Brand | null;
  veloconnectEndpoint: UseVeloconnectEndpointReturn;
}

interface OuterProps {
  tabBar: (props: ProductDetailsPartialRenderProps) => React.ReactNode;
  overlay: (props: ProductDetailsPartialRenderProps) => React.ReactNode;
  advertisement?: (props: ProductDetailsPartialRenderProps) => React.ReactNode;
  backButton: () => React.ReactNode;
  onProductLoad?: (product: Product) => void;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const ProductDetailsPartial = ({
  onReplace,
  onError,
  currency,
  tabBar,
  backButton,
  overlay,
  advertisement,
  onProductLoad,
  activeBrands,
  assortmentPriceSettings
}: Props) => {
  const { i18n } = useTranslation(["commons"]);

  const location = useLocation<GlobalLocationState>();
  const { mixpanel } = useTrackingContext();
  const productListContext = (location.state || {}).productListContext || [];
  const variantId = location.state?.variantId;
  const { id: productId } = useParams<{ id: ProductId }>();

  const [transitionDirection, setTransitionDirection] = React.useState<TransitionDirection>(TransitionDirection.Next);

  const { isLoading: isLoading, data: { product, productVariant, specFormatter } = {} } = useFetchData(
    async () => {
      const product = await getProduct(productId, currency, assortmentPriceSettings);

      const actualVariantId = variantId ?? getFirstVariantId(product);
      const productVariant = getStandaloneProductVariant(
        product,
        product[ProductKey.Variants].find(variant => variant[ProductKey.VariantId] === actualVariantId)
      );

      const productType = product[ProductKey.ProductType];
      const specConfig = getSpecConfig(productType);

      const specFormatter = getSpecFormatter(productType, specConfig.specDefinitions, i18n);

      return { product, productVariant, specFormatter };
    },
    [productId, variantId, currency],
    {
      handleError: error => onError(error),
      onDone: ({ product }) => {
        onProductLoad?.(product);
      }
    }
  );

  const productInitializingFirstTime = !product && isLoading;

  const brandKey = product && getSingleSpecValue(ProductSpecKey.BrandKey, product);
  const brandsMap = React.useMemo(() => keyBy(activeBrands, brand => brand.key), [activeBrands]);
  const brand: Brand | null = brandKey ? brandsMap[brandKey] : null;
  const neighbours = getNeighbouringProducts(productListContext, productId);

  const veloconnectEndpoint = useVeloconnectEndpoint(brandKey);

  const renderProps: ProductDetailsPartialRenderProps = {
    productId,
    variantId,
    product,
    brand,
    productInitializingFirstTime,
    veloconnectEndpoint
  };

  React.useEffect(() => {
    if (product) {
      mixpanel?.trackProductViewed(product);
    }
    // Track only when product id changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.[ProductKey.ProductId]]);

  const buildPathWithProductId = (path: string) => buildPath(path, { id: decodeIdFromMatch(productId) });

  const handleNextProduct = (interaction: "click" | "swipe") => {
    if (!isLoading && brand && product && neighbours.next != null) {
      mixpanel?.trackProductDetailsNavigated(
        interaction,
        "next",
        { productId, brandKey: brand.key, modelName: getSingleSpecValue(ProductSpecKey.ModelName, product) },
        neighbours.next.productId
      );

      setTransitionDirection(TransitionDirection.Next);
      onReplace(buildPath(ROUTES.PRODUCT_DETAILS.INDEX, { id: String(neighbours.next.productId) }), {
        ...location.state,
        variantId: neighbours.next.variantId
      });
    }
  };

  const handlePrevProduct = (interaction: "click" | "swipe") => {
    if (!isLoading && brand && product && neighbours.prev != null) {
      mixpanel?.trackProductDetailsNavigated(
        interaction,
        "prev",
        { productId, brandKey: brand.key, modelName: getSingleSpecValue(ProductSpecKey.ModelName, product) },
        neighbours.prev.productId
      );

      setTransitionDirection(TransitionDirection.Prev);
      onReplace(buildPath(ROUTES.PRODUCT_DETAILS.INDEX, { id: String(neighbours.prev.productId) }), {
        ...location.state,
        variantId: neighbours.prev.variantId
      });
    }
  };

  return (
    <Route
      exact
      path={ROUTES.PRODUCT_DETAILS.INDEX}
      children={({ match }: RouteChildrenProps) => (
        <VeloconnectEndpointProvider endpoint={veloconnectEndpoint}>
          <TabBarPageLayout
            tabBar={tabBar(renderProps)}
            showOverlay={!productInitializingFirstTime && !match}
            overlay={overlay(renderProps)}
            onCloseOverlay={() => onReplace(buildPathWithProductId(ROUTES.PRODUCT_DETAILS.INDEX), location.state)}
          >
            <AdvertisementLayout advertisement={!isLoading && advertisement?.(renderProps)}>
              {!product && (
                <CenteredPageLayout>
                  <LoadingIndicator size="s" />
                </CenteredPageLayout>
              )}
              {product && productVariant && specFormatter && (
                <MainContentLayout
                  layout="full"
                  headerLeft={backButton()}
                  // TODO: Refactor with header component. Adding a simple margin works fine but just in this special case. It breaks out of the header
                  headerRight={
                    brand && (
                      <FlexLayout alignItems="center" gap="sl" classNames={["u-space-top-l"]}>
                        {brand?.highlighted ? <Icon source={icons.IconMediumHighlight} size="l" /> : undefined}
                        <BrandImage position="right" src={getBrandImageUrl(brand)} />
                      </FlexLayout>
                    )
                  }
                  backgroundImage={null}
                  hideHeaderBackground
                >
                  <SwipeableContent
                    transitionDirection={transitionDirection}
                    classNames={["u-space-top-s", "u-space-xl"]}
                    prevButton={
                      neighbours.prev !== null ? (
                        <NextPrevNavigationButton onClick={() => handlePrevProduct("click")} />
                      ) : undefined
                    }
                    nextButton={
                      neighbours.next !== null ? (
                        <NextPrevNavigationButton next onClick={() => handleNextProduct("click")} />
                      ) : undefined
                    }
                    onPrev={() => handlePrevProduct("swipe")}
                    onNext={() => handleNextProduct("swipe")}
                  >
                    <ProductDetailsImagePartial
                      // It is necessary to create a new key with every new product to allow SwipeableContent to detect when it has to animate!
                      key={product[ProductKey.ProductId]}
                      product={product}
                      productVariant={productVariant}
                      specFormatter={specFormatter}
                    />
                  </SwipeableContent>
                </MainContentLayout>
              )}
            </AdvertisementLayout>
          </TabBarPageLayout>
        </VeloconnectEndpointProvider>
      )}
    />
  );
};

export default connector(ProductDetailsPartial);
