import { Action } from "redux";

import { Brand } from "../../../commons/types/brand";
import { Actions } from "../actions";
import * as brandsActions from "../actions/brands";
import { InnerReducer, mapReducerStateWithKey } from "../libs/reducer";

import { State } from ".";

const NAME = "brands";

export interface BrandsState {
  [NAME]: {
    active: Brand[];
    available: Brand[];
  };
}
export const initialState: BrandsState = {
  [NAME]: {
    active: [],
    available: []
  }
};

type BrandsInnerReducer<A extends Action = Actions> = InnerReducer<State, BrandsState[typeof NAME], A>;

const handleReceivedAvailable: BrandsInnerReducer<brandsActions.ReceivedAvailableAction> = (state, action) => ({
  ...state[NAME],
  available: action.payload
});

const handleReceivedActive: BrandsInnerReducer<brandsActions.ReceivedActiveAction> = (state, action) => ({
  ...state[NAME],
  active: action.payload
});

const reducer: InnerReducer<State, BrandsState[typeof NAME], Actions> = (state, action) => {
  switch (action.type) {
    case "BRANDS.RECEIVED_AVAILABLE":
      return handleReceivedAvailable(state, action);
    case "BRANDS.RECEIVED_ACTIVE":
      return handleReceivedActive(state, action);
    default:
      return state[NAME];
  }
};

export default mapReducerStateWithKey(reducer, NAME);
