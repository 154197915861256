import { RouterState } from "connected-react-router";

import core, { CoreState, initialState as initialStateCore } from "../reducers/core";
import modal, { initialState as initialStateModal, ModalState } from "../reducers/modal";
import toast, { initialState as initialStateToasts, ToastsState } from "../reducers/toasts";

import auth, { AuthState, initialState as initialStateAuth } from "./auth";
import brands, { BrandsState, initialState as initialStateBrands } from "./brands";
import error, { ErrorState, initialState as initialStateError } from "./error";
import session, { initialState as initialSessionState, SessionState } from "./session";
import settings, { initialState as initialStateSettings, SettingsState } from "./settings";
import wawi, { initialState as initialStateWawi, WawiState } from "./wawi";

export type State = CoreState &
  AuthState &
  ModalState &
  ToastsState &
  ErrorState &
  SettingsState &
  WawiState &
  BrandsState &
  SessionState & { router: RouterState<any> };

export const initialState = {
  ...initialStateCore,
  ...initialStateAuth,
  ...initialStateModal,
  ...initialStateToasts,
  ...initialStateError,
  ...initialStateSettings,
  ...initialStateWawi,
  ...initialStateBrands,
  ...initialSessionState
};

export const reducers = [core, auth, modal, toast, error, settings, wawi, brands, session];
