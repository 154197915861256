import React from "react";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";

import { getCustomerGroupConfig } from "../../../../commons/libs/config";
import { getProducts } from "../../../../commons/libs/content-service";
import { getSpecConfig } from "../../../../commons/specs";
import { GlobalLocationState } from "../../../../commons/types/location";
import * as icons from "../../../../resources/icons/";
import { connect } from "../../../commons/container/utils/loop";
import actions from "../../actions";
import AnnotatedButton from "../../components/AnnotatedButton/AnnotatedButton";
import Button from "../../components/Button/Button";
import CenteredContent from "../../components/CenteredContent/CenteredContent";
import Headline from "../../components/Headline/Headline";
import Icon from "../../components/Icon/Icon";
import InputField from "../../components/InputField/InputField";
import SingleInputFormLayout from "../../components/SingleInputFormLayout/SingleInputFormLayout";
import { getProductFiltersFromActiveFilters } from "../../libs/filters";
import useDebounce from "../../libs/hooks/use-debounce";
import useFetchData from "../../libs/hooks/use-fetch-data";
import { selectInitializedEnv, selectInitializedSettings } from "../../libs/selectors";
import { State } from "../../reducers";
import { ROUTES } from "../../routes";

const SEARCH_TERM_DEBOUNCE_DELAY = 250;

const mapStateToProps = (state: State) => ({
  currency: selectInitializedSettings(state).currency,
  assortment: selectInitializedSettings(state).assortment,
  activeBrands: state.brands.active,
  customerGroup: getCustomerGroupConfig(selectInitializedEnv(state).customerGroup)
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: RouteComponentProps<{}, {}, GlobalLocationState>) => ({
  onError: bindActionCreators(actions.error.set, dispatch),
  onPush: bindActionCreators(push, dispatch),
  onSubmit: (searchTerm: string) => {
    dispatch(
      push(ROUTES.PRODUCT_FINDER.INDEX, {
        ...(ownProps.location.state ?? {}),
        // If `goBackToDetails` is set, we know the user visited details content page before
        // We reset that state to prevent the user from being redirected to details page after submitting the searchTerm
        goBackToDetails: undefined,
        searchTerm
      })
    );
  },
  setActiveFilters: bindActionCreators(actions.session.setActiveFilters, dispatch)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const SearchContent = ({ currency, assortment, onSubmit, setActiveFilters, customerGroup }: Props) => {
  const { t } = useTranslation(["commons"]);

  const productType = customerGroup.productTypes[0];
  const specConfig = getSpecConfig(productType);

  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_TERM_DEBOUNCE_DELAY);

  const nextActiveFilters = {};
  const products = useFetchData(
    () =>
      getProducts(
        currency,
        assortment?.priceSettings,
        getProductFiltersFromActiveFilters(nextActiveFilters, specConfig.filterConfig),
        searchTerm,
        0
      ),
    [debouncedSearchTerm, currency, assortment]
  );

  const annotation = products.isLoading
    ? t("commons:searchContentProductFinderOverview.loadingAnnotation")
    : t("commons:searchContentProductFinderOverview.resultsAnnotation", { count: products.data?.totalFiltered });

  return (
    <CenteredContent classNames={["u-padding-bottom-xxxxl"]}>
      <Headline classNames={["u-space-l"]}>{t("commons:searchContentProductFinderOverview.searchHeadline")}</Headline>
      <SingleInputFormLayout
        input={
          <InputField
            type="search"
            placeholder={t("commons:searchContentProductFinderOverview.searchInputPlaceholder")}
            value={searchTerm}
            onChange={setSearchTerm}
            icon={<Icon source={icons.IconSmallSearch} />}
          />
        }
        sideComponents={
          <AnnotatedButton inline annotation={annotation}>
            <Button
              icon={<Icon source={icons.IconSmallArrowRight} />}
              iconRight
              variant="accent"
              kind="solid"
              disabled={products.isLoading || products.data?.totalFiltered === 0}
              onClick={() => {
                setActiveFilters(nextActiveFilters);
                onSubmit(searchTerm);
              }}
            >
              {t("commons:searchContentProductFinderOverview.showButton")}
            </Button>
          </AnnotatedButton>
        }
      />
    </CenteredContent>
  );
};

export default connector(SearchContent);
