import { formatUnit } from "../libs/formatters";

import {
  AssortmentFilterKeys,
  FilterConfig,
  FilterGroups,
  FilterTypes,
  getActiveArrayFilters,
  getActiveFilters,
  getActiveRangeFilters
} from "./filters";
import {
  Category,
  CategoryGroupDefinition,
  CategoryGroupKey,
  Product,
  ProductSpec,
  specDefinitions as productSpecDefinitions,
  ProductSpecKey,
  ProductType,
  ProductVariant,
  SpecCompareDefinition,
  SpecConfig,
  SpecDefinitions,
  SpecGroup,
  StandaloneProductVariant
} from "./product";

export type BikeTypeKey = "BIKE" | "E_BIKE" | "S_PEDELEC";

export type MaterialKey = "ALUMINUM" | "CARBON" | "STEEL" | "TITANIUM" | "MAGNESIUM";

export type DriveTrainKey = "BELT" | "CHAIN";

export enum MotorcycleSpecKey {
  /** @deprecated */
  TypeId = "typeId",
  /** @deprecated */
  TypeName = "typeName",
  /** @deprecated */
  ModelVariant = "modelVariant",
  FrameTypeKey = "frameTypeKey",
  FrameSize = "frameSize",
  Frame = "frame",
  Material = "material",
  MaterialKey = "materialKey",
  Fork = "fork",
  ForkSuspensionTravel = "forkSuspensionTravel",
  Damper = "damper",
  DamperSuspensionTravel = "damperSuspensionTravel",
  DriveTrain = "driveTrain",
  DriveTrainKey = "driveTrainKey",
  HasDamper = "hasDamper",
  MarketingColor = "marketingColor",
  Color = "color",
  Gear = "gear",
  GearTypeKey = "gearTypeKey",
  HasGearHub = "hasGearHub",
  GearShiftLever = "gearShiftLever",
  GearCount = "gearCount",
  FrontDerailleur = "frontDerailleur",
  HasFrontDerailleur = "hasFrontDerailleur",
  Brakes = "brakes",
  BrakeTypeKey = "brakeTypeKey",
  BrakeLever = "brakeLever",
  HasCoasterBrake = "hasCoasterBrake",
  Saddle = "saddle",
  SeatPost = "seatPost",
  SaddleClamping = "saddleClamping",
  Handlebars = "handlebars",
  Stem = "stem",
  Handles = "handles",
  Headset = "headset",
  Pedals = "pedals",
  Rims = "rims",
  Tyres = "tyres",
  Wheelset = "wheelset",
  HasPlusSizeWheels = "hasPlusSizeWheels",
  HasMixedWheelSizes = "hasMixedWheelSizes",
  WheelSize = "wheelSize",
  Hubs = "hubs",
  Spokes = "spokes",
  Lighting = "lighting",
  HasLighting = "hasLighting",
  RearLight = "rearLight",
  Dynamo = "dynamo",
  Fenders = "fenders",
  ChainGuard = "chainGuard",
  Stand = "stand",
  Carrier = "carrier",
  FrontCarrier = "frontCarrier",
  Lock = "lock",
  BottomBracket = "bottomBracket",
  Crankset = "crankset",
  Sprockets = "sprockets",
  Chain = "chain",
  BikeTypeKey = "bikeTypeKey",
  Engine = "engine",
  EngineBrand = "engineBrand",
  EngineSpeed = "engineSpeed",
  HasEngine = "hasEngine",
  Battery = "battery",
  BatteryCapacity = "batteryCapacity",
  Display = "display",
  Extras = "extras",
  BidexInfoText = "bidexInfoText",
  Bidex = "BIDEX",
  Weight = "weight",
  MaxTotalWeight = "maxTotalWeight",

  // Motorcycle specific
  VehicleId = "vehicleId",
  VehicleCode = "vehicleCode",
  VariantCode = "variantCode",
  FrontBrake = "frontBrake",
  RearBrake = "rearBrake",
  FrameDesign = "frameDesign",
  FrameDesignKey = "frameDesignKey",
  EngineDesign = "engineDesign",
  CylinderCount = "cylinderCount",
  EngineStroke = "engineStroke",
  Lubrication = "lubrication",
  PowerKw = "powerKw",
  IsElectric = "isElectric",
  Cooling = "cooling",
  Co2emissions = "co2emissions",
  Starter = "starter",
  Stroke = "stroke",
  Bore = "bore",
  Torque = "torque",
  FuelConsumption = "fuelConsumption",
  Displacement = "displacement",
  Ems = "ems",
  Abs = "abs",
  Transmission = "transmission",
  TransmissionKey = "transmissionKey",
  Clutch = "clutch",
  TankCapacity = "tankCapacity",
  WeightEmpty = "weightEmpty",
  GroundClearance = "groundClearance",
  SeatHeight = "seatHeight",
  StearingHeadAngle = "stearingHeadAngle",
  LengthMm = "lengthMM",
  WidthMm = "widthMM",
  HeightMm = "heightMM",
  TestRideBookingUrl = "testRideBookingUrl"
}

export interface MotorcycleSpec extends ProductSpec {
  [MotorcycleSpecKey.TypeId]: number | null;
  [MotorcycleSpecKey.TypeName]: string | null;
  [MotorcycleSpecKey.ModelVariant]?: string | null;
  [MotorcycleSpecKey.FrameSize]?: string | null;
  [MotorcycleSpecKey.FrameTypeKey]: string | null;
  [MotorcycleSpecKey.Frame]: string | null;
  [MotorcycleSpecKey.Material]: string | null;
  [MotorcycleSpecKey.MaterialKey]: MaterialKey | null;
  [MotorcycleSpecKey.Fork]: string | null;
  [MotorcycleSpecKey.ForkSuspensionTravel]: number | null;
  [MotorcycleSpecKey.Damper]: string | null;
  [MotorcycleSpecKey.DamperSuspensionTravel]: number | null;
  [MotorcycleSpecKey.HasDamper]: boolean;
  [MotorcycleSpecKey.MarketingColor]?: string | null;
  [MotorcycleSpecKey.Color]?: string[];
  [MotorcycleSpecKey.Gear]: string | null;
  [MotorcycleSpecKey.GearTypeKey]: string | null;
  [MotorcycleSpecKey.HasGearHub]: boolean;
  [MotorcycleSpecKey.GearShiftLever]: string | null;
  [MotorcycleSpecKey.GearCount]: number | null;
  [MotorcycleSpecKey.FrontDerailleur]: string | null;
  [MotorcycleSpecKey.HasFrontDerailleur]: boolean;
  [MotorcycleSpecKey.Brakes]: string | null;
  [MotorcycleSpecKey.BrakeTypeKey]: string | null;
  [MotorcycleSpecKey.BrakeLever]: string | null;
  [MotorcycleSpecKey.HasCoasterBrake]?: boolean | null;
  [MotorcycleSpecKey.Saddle]: string | null;
  [MotorcycleSpecKey.SeatPost]: string | null;
  [MotorcycleSpecKey.SaddleClamping]: string | null;
  [MotorcycleSpecKey.Handlebars]: string | null;
  [MotorcycleSpecKey.Stem]: string | null;
  [MotorcycleSpecKey.Handles]: string | null;
  [MotorcycleSpecKey.Headset]: string | null;
  [MotorcycleSpecKey.Pedals]: string | null;
  [MotorcycleSpecKey.Rims]: string | null;
  [MotorcycleSpecKey.Tyres]: string | null;
  [MotorcycleSpecKey.Wheelset]: string | null;
  [MotorcycleSpecKey.HasPlusSizeWheels]: boolean;
  [MotorcycleSpecKey.HasMixedWheelSizes]: boolean;
  [MotorcycleSpecKey.WheelSize]: number | null;
  [MotorcycleSpecKey.Hubs]: string | null;
  [MotorcycleSpecKey.Spokes]: string | null;
  [MotorcycleSpecKey.Lighting]: string | null;
  [MotorcycleSpecKey.HasLighting]: boolean;
  [MotorcycleSpecKey.RearLight]: string | null;
  [MotorcycleSpecKey.Dynamo]: string | null;
  [MotorcycleSpecKey.Fenders]: string | null;
  [MotorcycleSpecKey.ChainGuard]: string | null;
  [MotorcycleSpecKey.Stand]: string | null;
  [MotorcycleSpecKey.Carrier]: string | null;
  [MotorcycleSpecKey.FrontCarrier]: string | null;
  [MotorcycleSpecKey.Lock]: string | null;
  [MotorcycleSpecKey.BottomBracket]: string | null;
  [MotorcycleSpecKey.Crankset]: string | null;
  [MotorcycleSpecKey.Sprockets]: string | null;
  [MotorcycleSpecKey.Chain]: string | null;
  [MotorcycleSpecKey.DriveTrain]?: string | null;
  [MotorcycleSpecKey.DriveTrainKey]?: DriveTrainKey | null;
  [MotorcycleSpecKey.BikeTypeKey]: BikeTypeKey | null;
  [MotorcycleSpecKey.Engine]: string | null;
  [MotorcycleSpecKey.EngineBrand]: string | null;
  [MotorcycleSpecKey.EngineSpeed]: number | null;
  [MotorcycleSpecKey.HasEngine]: boolean;
  [MotorcycleSpecKey.Battery]: string | null;
  [MotorcycleSpecKey.BatteryCapacity]?: string | null;
  [MotorcycleSpecKey.Display]: string | null;
  [MotorcycleSpecKey.Extras]: string | null;
  [MotorcycleSpecKey.BidexInfoText]: string | null;
  [MotorcycleSpecKey.Bidex]: string | null;
  [MotorcycleSpecKey.Weight]?: number | null;
  [MotorcycleSpecKey.MaxTotalWeight]?: number | null;

  // Motorcycle specific
  [MotorcycleSpecKey.VehicleId]?: number | null;
  [MotorcycleSpecKey.VehicleCode]?: string | null;
  [MotorcycleSpecKey.VariantCode]?: string | null;
  [MotorcycleSpecKey.FrontBrake]?: string | null;
  [MotorcycleSpecKey.RearBrake]?: string | null;
  [MotorcycleSpecKey.FrameDesign]?: string | null;
  [MotorcycleSpecKey.FrameDesignKey]?: string | null;
  [MotorcycleSpecKey.CylinderCount]?: number | null;
  [MotorcycleSpecKey.EngineStroke]?: string | null;
  [MotorcycleSpecKey.EngineDesign]?: string | null;
  [MotorcycleSpecKey.PowerKw]?: number | null;
  [MotorcycleSpecKey.IsElectric]?: string | null;
  [MotorcycleSpecKey.Cooling]?: string | null;
  [MotorcycleSpecKey.Co2emissions]?: string | null;
  [MotorcycleSpecKey.Lubrication]?: string | null;
  [MotorcycleSpecKey.Starter]?: string | null;
  [MotorcycleSpecKey.Stroke]?: number | null;
  [MotorcycleSpecKey.Bore]?: number | null;
  [MotorcycleSpecKey.Torque]?: number | null;
  [MotorcycleSpecKey.Displacement]?: number | null;
  [MotorcycleSpecKey.FuelConsumption]?: number | null;
  [MotorcycleSpecKey.Ems]?: string | null;
  [MotorcycleSpecKey.Abs]?: string | null;
  [MotorcycleSpecKey.Transmission]?: string | null;
  [MotorcycleSpecKey.TransmissionKey]?: string | null;
  [MotorcycleSpecKey.Clutch]?: string | null;
  [MotorcycleSpecKey.TankCapacity]?: number | null;
  [MotorcycleSpecKey.WeightEmpty]?: number | null;
  [MotorcycleSpecKey.GroundClearance]?: number | null;
  [MotorcycleSpecKey.SeatHeight]?: number | null;
  [MotorcycleSpecKey.StearingHeadAngle]?: number | null;
  [MotorcycleSpecKey.TestRideBookingUrl]?: string | null;
  [MotorcycleSpecKey.LengthMm]?: number | null;
  [MotorcycleSpecKey.WidthMm]?: number | null;
  [MotorcycleSpecKey.HeightMm]?: number | null;
  [MotorcycleSpecKey.TestRideBookingUrl]?: string | null;
}

export type MotorcycleVariant = ProductVariant<ProductType.Motorcycle>;

export type MotorcycleProduct = Product<ProductType.Motorcycle>;

export type StandaloneMotorcycleVariant = StandaloneProductVariant<ProductType.Motorcycle>;

// It should be checked via SpecDefintions if for for every spec key there is a definition
export const specDefinitions: SpecDefinitions<ProductType.Motorcycle> = {
  ...(productSpecDefinitions as SpecDefinitions<ProductType.Motorcycle>),
  [MotorcycleSpecKey.TypeId]: {},
  [MotorcycleSpecKey.TypeName]: {},
  [MotorcycleSpecKey.FrameSize]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Frame]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Material]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Fork]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.ForkSuspensionTravel]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    visibleValue: value => value > 0,
    formatValue: value => `${value} mm`
  },
  [MotorcycleSpecKey.Damper]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.DamperSuspensionTravel]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    visibleValue: value => value > 0,
    formatValue: value => `${value} mm`
  },
  [MotorcycleSpecKey.HasDamper]: {},
  [MotorcycleSpecKey.MarketingColor]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Color]: {},
  [MotorcycleSpecKey.Gear]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.HasGearHub]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    // Only show false values if there is also a true value.
    visibleValues: values => {
      if (values.length === 1) {
        return values.filter(value => value === true);
      } else {
        return values;
      }
    },
    formatValue: (value, variant, { i18n, i18nSpecKey }) => i18n.t(`${i18nSpecKey}.valueTrue`),
    formatValues: (values, product, { i18n, i18nSpecKey }) => {
      if (values.length === 1) {
        return i18n.t(`${i18nSpecKey}.valueTrue`);
      } else {
        return i18n.t(`${i18nSpecKey}.valueMixed`);
      }
    }
  },
  [MotorcycleSpecKey.GearShiftLever]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.GearCount]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.FrontDerailleur]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.HasFrontDerailleur]: {},
  [MotorcycleSpecKey.Brakes]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.BrakeLever]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Saddle]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.SeatPost]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.SaddleClamping]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Handlebars]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Stem]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Handles]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Headset]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Pedals]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Rims]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Tyres]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Wheelset]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.HasPlusSizeWheels]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    // Only show false values if there is also a true value.
    visibleValues: values => {
      if (values.length === 1) {
        return values.filter(value => value === true);
      } else {
        return values;
      }
    },
    formatValue: (value, variant, { i18n, i18nSpecKey }) => i18n.t(`${i18nSpecKey}.valueTrue`),
    formatValues: (values, product, { i18n, i18nSpecKey }) => {
      if (values.length === 1) {
        return i18n.t(`${i18nSpecKey}.valueTrue`);
      } else {
        return i18n.t(`${i18nSpecKey}.valueMixed`);
      }
    }
  },
  [MotorcycleSpecKey.WheelSize]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    visibleValue: value => value > 0,
    formatValue: value => `${value}"`
  },
  [MotorcycleSpecKey.Hubs]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Spokes]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Lighting]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.HasLighting]: {},
  [MotorcycleSpecKey.RearLight]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Dynamo]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Fenders]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.ChainGuard]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Stand]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Carrier]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.FrontCarrier]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Lock]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.BottomBracket]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Crankset]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Sprockets]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Chain]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Engine]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.EngineSpeed]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    visibleValue: value => value > 0,
    formatValue: value => `${value} km/h`
  },
  [MotorcycleSpecKey.HasEngine]: {},
  [MotorcycleSpecKey.Battery]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Display]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Extras]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.BidexInfoText]: {},
  [MotorcycleSpecKey.Bidex]: {},
  [MotorcycleSpecKey.ModelVariant]: {},
  [MotorcycleSpecKey.Weight]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    visibleValue: value => value > 0,
    formatValue: (value, _variant, { i18n: { language: locale } }) =>
      `ca. ${formatUnit(value, locale, { unit: "kilogram" })}`,
    formatValues: (values, _variant, { i18n: { language: locale } }) => {
      const minWeight = Math.min(...values);
      const maxWeight = Math.max(...values);

      if (minWeight > 0 && maxWeight > 0) {
        return minWeight === maxWeight
          ? `ca. ${formatUnit(minWeight, locale, { unit: "kilogram" })}`
          : `ca. ${formatUnit(minWeight, locale, { unit: "kilogram" })} — ${formatUnit(maxWeight, locale, {
              unit: "kilogram"
            })}`;
      } else {
        return undefined;
      }
    }
  },
  [MotorcycleSpecKey.MaxTotalWeight]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    visibleValue: value => value > 0,
    formatValue: (value, _variant, { i18n: { language: locale } }) =>
      `max. ${formatUnit(value, locale, { unit: "kilogram" })}`
  },
  [MotorcycleSpecKey.FrontBrake]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.RearBrake]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.BrakeTypeKey]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.FrameDesign]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.EngineStroke]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.EngineDesign]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.CylinderCount]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Lubrication]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Cooling]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Starter]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Stroke]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Bore]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Torque]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Displacement]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Ems]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Abs]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Transmission]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Clutch]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.TankCapacity]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.WeightEmpty]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.GroundClearance]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.SeatHeight]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.StearingHeadAngle]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.IsElectric]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.PowerKw]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.Co2emissions]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [MotorcycleSpecKey.FuelConsumption]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  }
};

export const specCompareDefinition: SpecCompareDefinition<ProductType.Motorcycle> = [
  // Shared spec-keys between motorcycles and bikes
  ProductSpecKey.BrandName,
  ProductSpecKey.ModelName,
  ProductSpecKey.ArticleNumber,
  ProductSpecKey.ModelYear,
  ProductSpecKey.Rrp,
  MotorcycleSpecKey.Fork,
  MotorcycleSpecKey.ForkSuspensionTravel,
  MotorcycleSpecKey.Damper,
  MotorcycleSpecKey.DamperSuspensionTravel,
  MotorcycleSpecKey.GearCount,
  MotorcycleSpecKey.Tyres,
  MotorcycleSpecKey.Weight,

  // MotorCycle Specific
  MotorcycleSpecKey.FrontBrake,
  MotorcycleSpecKey.RearBrake,
  MotorcycleSpecKey.FrameDesign,
  MotorcycleSpecKey.EngineStroke,
  MotorcycleSpecKey.CylinderCount,
  MotorcycleSpecKey.Lubrication,
  MotorcycleSpecKey.Cooling,
  MotorcycleSpecKey.Starter,
  MotorcycleSpecKey.Stroke,
  MotorcycleSpecKey.Bore,
  MotorcycleSpecKey.Torque,
  MotorcycleSpecKey.Displacement,
  MotorcycleSpecKey.Ems,
  MotorcycleSpecKey.Abs,
  MotorcycleSpecKey.Transmission,
  MotorcycleSpecKey.Clutch,
  MotorcycleSpecKey.TankCapacity,
  MotorcycleSpecKey.WeightEmpty,
  MotorcycleSpecKey.GroundClearance,
  MotorcycleSpecKey.SeatHeight,
  MotorcycleSpecKey.StearingHeadAngle
];

// Sync group keys with translations in specs.json
export type MotorcycleSpecGroupKey =
  | "FRAME"
  | "MOTOR"
  | "PERFORMANCE_AND_EMISSION"
  | "SIZE_AND_WEIGHT"
  | "ELECTRONIC"
  | "MODEL_INFOS";

// Sync group keys with translations in specs.json
export const specGroups: SpecGroup<ProductType.Motorcycle>[] = [
  {
    key: "FRAME",
    specKeys: [
      MotorcycleSpecKey.FrameDesign,
      MotorcycleSpecKey.Fork,
      MotorcycleSpecKey.ForkSuspensionTravel,
      MotorcycleSpecKey.Damper,
      MotorcycleSpecKey.DamperSuspensionTravel,
      MotorcycleSpecKey.FrontBrake,
      MotorcycleSpecKey.RearBrake,
      MotorcycleSpecKey.Tyres
    ]
  },
  {
    key: "MOTOR",
    specKeys: [
      MotorcycleSpecKey.EngineDesign,
      MotorcycleSpecKey.Transmission,
      MotorcycleSpecKey.Clutch,
      MotorcycleSpecKey.GearCount,
      MotorcycleSpecKey.Starter,
      MotorcycleSpecKey.Stroke,
      MotorcycleSpecKey.Bore,
      MotorcycleSpecKey.Displacement,
      MotorcycleSpecKey.Ems,
      MotorcycleSpecKey.Chain,
      MotorcycleSpecKey.Cooling,
      MotorcycleSpecKey.Lubrication
    ]
  },
  {
    key: "PERFORMANCE_AND_EMISSION",
    specKeys: [
      MotorcycleSpecKey.PowerKw,
      MotorcycleSpecKey.Torque,
      MotorcycleSpecKey.TankCapacity,
      MotorcycleSpecKey.Co2emissions,
      MotorcycleSpecKey.FuelConsumption
    ]
  },
  {
    key: "SIZE_AND_WEIGHT",
    specKeys: [
      MotorcycleSpecKey.WeightEmpty,
      MotorcycleSpecKey.MaxTotalWeight,
      MotorcycleSpecKey.GroundClearance,
      MotorcycleSpecKey.SeatHeight,
      MotorcycleSpecKey.StearingHeadAngle
    ]
  },
  {
    key: "ELECTRONIC",
    specKeys: [MotorcycleSpecKey.Abs, MotorcycleSpecKey.Extras]
  },
  {
    key: "MODEL_INFOS",
    specKeys: [
      ProductSpecKey.BrandName,
      ProductSpecKey.ArticleNumber,
      ProductSpecKey.CategoryName,
      MotorcycleSpecKey.MarketingColor,
      ProductSpecKey.Rrp
    ]
  }
];

// Specs which are used to fullfill the minimal column count in variant table
export const variantsTableFallbackSpecKeys: (keyof MotorcycleSpec)[] = [
  MotorcycleSpecKey.Frame,
  MotorcycleSpecKey.FrameSize,
  MotorcycleSpecKey.MarketingColor,
  ProductSpecKey.ModelName
];

export const variantsTableSpecKeysPriorityList: (keyof MotorcycleSpec)[] = [
  MotorcycleSpecKey.Battery,
  MotorcycleSpecKey.Gear,
  MotorcycleSpecKey.MarketingColor
];

export const variantInformationBoxSpecKeyPriorityList: (keyof MotorcycleSpec)[] = [
  ProductSpecKey.ArticleNumber,
  ProductSpecKey.Gtin,
  ProductSpecKey.Upc
];

// Filter config

export enum CustomMotorcycleFilterKey {}

export interface CustomMotorcycleFilterSpec {}

export const filterConfig = {
  [ProductSpecKey.ManualAssortment]: {
    type: "default",
    getActiveFilters: getActiveFilters(ProductSpecKey.ManualAssortment, FilterTypes.Eqs)
  },
  [ProductSpecKey.AutomaticAssortment]: {
    type: "default",
    getActiveFilters: getActiveArrayFilters(ProductSpecKey.AutomaticAssortment)
  },
  [ProductSpecKey.VeloconnectAssortment]: {
    type: "default",
    getActiveFilters: getActiveArrayFilters(ProductSpecKey.VeloconnectAssortment)
  },
  [ProductSpecKey.BrandName]: {
    type: "default",
    getActiveFilters: getActiveFilters(ProductSpecKey.BrandName)
  },
  [ProductSpecKey.ModelYear]: {
    type: "default",
    getActiveFilters: getActiveFilters(ProductSpecKey.ModelYear)
  },
  [ProductSpecKey.CategoryKey]: {
    type: "default",
    getActiveFilters: getActiveFilters(ProductSpecKey.CategoryKey)
  },
  [ProductSpecKey.Price]: {
    type: "range",
    getActiveFilters: getActiveRangeFilters(ProductSpecKey.Price)
  },
  [ProductSpecKey.ConfiguratorUrl]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Motorcycle>(ProductSpecKey.ConfiguratorUrl, FilterTypes.Has)
  },
  [MotorcycleSpecKey.IsElectric]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Motorcycle>(MotorcycleSpecKey.IsElectric, FilterTypes.Eqs)
  },
  [MotorcycleSpecKey.FrameDesignKey]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Motorcycle>(MotorcycleSpecKey.FrameDesignKey, FilterTypes.Eqs)
  },
  [MotorcycleSpecKey.SeatHeight]: {
    type: "range",
    getActiveFilters: getActiveRangeFilters<ProductType.Motorcycle>(MotorcycleSpecKey.SeatHeight)
  },
  [MotorcycleSpecKey.Color]: {
    type: "default",
    getActiveFilters: getActiveArrayFilters<ProductType.Motorcycle>(MotorcycleSpecKey.Color)
  },
  [MotorcycleSpecKey.CylinderCount]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Motorcycle>(MotorcycleSpecKey.CylinderCount, FilterTypes.Eqs)
  },
  [MotorcycleSpecKey.TransmissionKey]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Motorcycle>(MotorcycleSpecKey.TransmissionKey, FilterTypes.Eqs)
  },
  [MotorcycleSpecKey.EngineStroke]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Motorcycle>(MotorcycleSpecKey.EngineStroke, FilterTypes.Eqs)
  },
  [MotorcycleSpecKey.Abs]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Motorcycle>(MotorcycleSpecKey.Abs, FilterTypes.Has)
  },
  [MotorcycleSpecKey.Cooling]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Motorcycle>(MotorcycleSpecKey.Cooling, FilterTypes.Eqs)
  },
  [MotorcycleSpecKey.Displacement]: {
    type: "range",
    getActiveFilters: getActiveRangeFilters<ProductType.Motorcycle>(MotorcycleSpecKey.Displacement)
  },
  [MotorcycleSpecKey.GearCount]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Motorcycle>(MotorcycleSpecKey.GearCount, FilterTypes.Eqs)
  },
  [MotorcycleSpecKey.Stroke]: {
    type: "range",
    getActiveFilters: getActiveRangeFilters<ProductType.Motorcycle>(MotorcycleSpecKey.Stroke)
  },
  [MotorcycleSpecKey.DamperSuspensionTravel]: {
    type: "range",
    getActiveFilters: getActiveRangeFilters<ProductType.Motorcycle>(MotorcycleSpecKey.DamperSuspensionTravel)
  },
  [MotorcycleSpecKey.ForkSuspensionTravel]: {
    type: "range",
    getActiveFilters: getActiveRangeFilters<ProductType.Motorcycle>(MotorcycleSpecKey.ForkSuspensionTravel)
  },
  [MotorcycleSpecKey.Starter]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Motorcycle>(MotorcycleSpecKey.Starter, FilterTypes.Eqs)
  },
  [MotorcycleSpecKey.TankCapacity]: {
    type: "range",
    getActiveFilters: getActiveRangeFilters<ProductType.Motorcycle>(MotorcycleSpecKey.TankCapacity)
  },
  [MotorcycleSpecKey.WeightEmpty]: {
    type: "range",
    getActiveFilters: getActiveRangeFilters<ProductType.Motorcycle>(MotorcycleSpecKey.WeightEmpty)
  },
  [MotorcycleSpecKey.GroundClearance]: {
    type: "range",
    getActiveFilters: getActiveRangeFilters<ProductType.Motorcycle>(MotorcycleSpecKey.GroundClearance)
  }
} as const satisfies FilterConfig<ProductType.Motorcycle>;

export const filterGroups = [
  {
    key: "general",
    filters: [
      ProductSpecKey.ManualAssortment,
      ProductSpecKey.AutomaticAssortment,
      ProductSpecKey.BrandName,
      ProductSpecKey.ModelYear,
      ProductSpecKey.CategoryKey,
      ProductSpecKey.Price,
      MotorcycleSpecKey.TankCapacity,
      ProductSpecKey.ConfiguratorUrl
    ]
  },
  {
    key: "frame",
    filters: [
      MotorcycleSpecKey.FrameDesignKey,
      MotorcycleSpecKey.Color,
      MotorcycleSpecKey.ForkSuspensionTravel,
      MotorcycleSpecKey.DamperSuspensionTravel
    ]
  },
  {
    key: "engine",
    filters: [
      MotorcycleSpecKey.IsElectric,
      MotorcycleSpecKey.TransmissionKey,
      MotorcycleSpecKey.EngineStroke,
      MotorcycleSpecKey.CylinderCount,
      MotorcycleSpecKey.GearCount,
      MotorcycleSpecKey.Starter,
      MotorcycleSpecKey.Stroke,
      MotorcycleSpecKey.Displacement,
      MotorcycleSpecKey.Cooling
    ]
  },
  {
    key: "sizeAndWeight",
    filters: [MotorcycleSpecKey.WeightEmpty, MotorcycleSpecKey.SeatHeight, MotorcycleSpecKey.GroundClearance]
  }
] as const satisfies FilterGroups<ProductType.Motorcycle>;

export const assortmentFilterKeys: AssortmentFilterKeys<ProductType.Motorcycle> = [
  ProductSpecKey.ManualAssortment,
  ProductSpecKey.BrandName,
  MotorcycleSpecKey.EngineDesign,
  ProductSpecKey.CategoryKey,
  ProductSpecKey.Price,
  ProductSpecKey.ModelYear
];

// Category config

export const motorcycleCategoryGroupKeys = [
  "offroad",
  "road",
  "tour",
  "child",
  "other"
] as const satisfies CategoryGroupKey[];
export type MotorcycleCategoryGroupKeys = (typeof motorcycleCategoryGroupKeys)[number];

const categories = [
  "MOTOCROSS",
  "ENDURO",
  "TRIAL",
  "NAKED_BIKE",
  "SUPERSPORT",
  "SUPERMOTO",
  "TRAVEL",
  "SPORTS_TOURER",
  "CRUISER",
  "ELECTRIC_BALANCE_BIKES",
  "UNKNOWN"
] as const satisfies Category[];
export type MotorcycleCategory = (typeof categories)[number];
export const motorcycleCategories = categories;

export const categoryGroups: CategoryGroupDefinition[] = [
  {
    key: "offroad",
    categories: ["MOTOCROSS", "ENDURO", "TRIAL"]
  },
  {
    key: "road",
    categories: ["NAKED_BIKE", "SUPERSPORT", "SUPERMOTO"]
  },
  {
    key: "tour",
    categories: ["TRAVEL", "SPORTS_TOURER", "CRUISER"]
  },
  {
    key: "child",
    categories: ["ELECTRIC_BALANCE_BIKES"]
  },
  {
    key: "other",
    categories: ["UNKNOWN"]
  }
].map(group => ({ ...group, productType: ProductType.Motorcycle }));

const specConfig: SpecConfig<ProductType.Motorcycle> = {
  productType: ProductType.Motorcycle,
  specDefinitions,
  specCompareDefinition,
  specGroups,
  variantsTableFallbackSpecKeys,
  variantsTableSpecKeysPriorityList,
  variantInformationBoxSpecKeyPriorityList,
  assortmentFilterKeys,
  filterGroups,
  filterConfig,
  categoryGroups,
  categories: motorcycleCategories,
  categoryGroupKeys: motorcycleCategoryGroupKeys
};

export default specConfig;
