import React from "react";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";

import { getCustomerGroupConfig } from "../../../../commons/libs/config";
import { BicycleSpecKey, BicycleTypeKey } from "../../../../commons/specs/bicycle";
import { ActiveFilters, FilterKey } from "../../../../commons/specs/filters";
import { ProductType } from "../../../../commons/specs/product";
import * as icons from "../../../../resources/icons/";
import AnnotatedButton from "../../components/AnnotatedButton/AnnotatedButton";
import Button from "../../components/Button/Button";
import FlexLayout from "../../components/FlexLayout/FlexLayout";
import Icon from "../../components/Icon/Icon";
import SingleLabeledToggleField from "../../components/SingleLabeledToggleField/SingleLabeledToggleField";
import ToggleField from "../../components/ToggleField/ToggleField";
import { getActiveFilterValues, isActiveFilter, removeActiveFilterValue, setActiveFilter } from "../../libs/filters";
import { useMediaQuery } from "../../libs/hooks/use-media-query";
import { selectInitializedEnv, selectInitializedSettings } from "../../libs/selectors";
import { State } from "../../reducers";
import { connect } from "../utils/loop";

const mapStateToProps = (state: State) => ({
  assortment: selectInitializedSettings(state).assortment,
  customerGroup: getCustomerGroupConfig(selectInitializedEnv(state).customerGroup)
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type OuterProps<Type extends ProductType> = {
  onFilterChange: (activeFilters: ActiveFilters<Type>) => void;
  onFilterReset: () => void;
  onSubmit: () => void;
  activeFilters: ActiveFilters<Type>;
  resultCount: number;
  isFilterResetButtonDisabled: boolean;
  isLoading: boolean;
};

type Props<Type extends ProductType> = ConnectedProps<typeof connector> & OuterProps<Type>;

const FooterContentPartial = <Type extends ProductType>({
  activeFilters,
  onFilterChange,
  onFilterReset,
  onSubmit,
  resultCount,
  isFilterResetButtonDisabled,
  isLoading,
  customerGroup
}: Props<Type>) => {
  const { t } = useTranslation(["commons"]);
  const breakpoints = useMediaQuery();

  const eBikeType: BicycleTypeKey = "E_BIKE";

  const productType = customerGroup.productTypes[0];

  const showsOnlyEBikes = React.useMemo(
    () =>
      getActiveFilterValues<ProductType.Bicycle, BicycleSpecKey.BikeTypeKey>(
        activeFilters as ActiveFilters<ProductType.Bicycle>,
        BicycleSpecKey.BikeTypeKey
      )?.length === 1 &&
      getActiveFilterValues<ProductType.Bicycle, BicycleSpecKey.BikeTypeKey>(
        activeFilters as ActiveFilters<ProductType.Bicycle>,
        BicycleSpecKey.BikeTypeKey
      )?.includes(eBikeType),
    [activeFilters]
  );

  const activeFiltersCount = React.useMemo(
    () => Object.keys(activeFilters ?? {}).filter(key => isActiveFilter(activeFilters, key as FilterKey<Type>)).length,
    [activeFilters]
  );

  const handleEBikeFilter = (onlyShowEBikes: boolean) => {
    if (onlyShowEBikes) {
      const nextActiveFilters = setActiveFilter<ProductType.Bicycle, BicycleSpecKey.BikeTypeKey>(
        activeFilters as ActiveFilters<ProductType.Bicycle>,
        BicycleSpecKey.BikeTypeKey,
        [eBikeType]
      ) as ActiveFilters<Type>;

      onFilterChange(nextActiveFilters);
    } else {
      onFilterChange(
        removeActiveFilterValue<ProductType.Bicycle, BicycleSpecKey.BikeTypeKey>(
          activeFilters as ActiveFilters<ProductType.Bicycle>,
          BicycleSpecKey.BikeTypeKey,
          eBikeType
        ) as ActiveFilters<Type>
      );
    }
  };

  const bikeLabel = showsOnlyEBikes
    ? t("commons:footerContentPartialProductFinderOverview.actionLabel.resultsEBikes", {
        count: resultCount
      })
    : t("commons:footerContentPartialProductFinderOverview.actionLabel.resultsBikes", {
        count: resultCount
      });

  const actionLabel = isLoading
    ? t("commons:footerContentPartialProductFinderOverview.actionLabel.loading")
    : bikeLabel;

  return (
    <FlexLayout justifyContent="space-between" alignItems="flex-end">
      <div className="u-width-100 u-text-left">
        <Button
          size={!breakpoints.xl ? "s" : "default"}
          icon={<Icon source={icons.IconSmallBin} />}
          onClick={onFilterReset}
          disabled={isFilterResetButtonDisabled}
        >
          {t("commons:footerContentPartialProductFinderOverview.clearFiltersButton")}
        </Button>
      </div>
      <div className="u-width-100 u-text-center">
        <AnnotatedButton
          annotation={t("commons:footerContentPartialProductFinderOverview.activeFiltersLabel", {
            count: activeFiltersCount
          })}
        >
          <Button
            icon={<Icon source={icons.IconSmallArrowRight} />}
            iconRight
            variant="accent"
            kind="solid"
            size={!breakpoints.xl ? "s" : "default"}
            disabled={isLoading || resultCount === 0}
            onClick={onSubmit}
          >
            {actionLabel}
          </Button>
        </AnnotatedButton>
      </div>
      <div className="u-width-100 u-text-right">
        <SingleLabeledToggleField
          align="right"
          classNames={productType === ProductType.Bicycle ? [] : ["u-hide"]}
          label={t("commons:footerContentPartialProductFinderOverview.onlyEbikesToggle")}
          labelSize={breakpoints.xl ? "base" : "s"}
        >
          <ToggleField size={breakpoints.xl ? "s" : "xs"} checked={showsOnlyEBikes} onChange={handleEBikeFilter} />
        </SingleLabeledToggleField>
      </div>
    </FlexLayout>
  );
};

export default connector(FooterContentPartial);
