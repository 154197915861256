import url from "url";
import { isNil, throttle } from "lodash";
import * as R from "ramda";

import { Message } from "../types/intercom";

import { serviceBaseUrl } from "./client-utils";

export type StatusWatcher = (ev: Message) => any;

const listeners: { fns: StatusWatcher[] } = {
  fns: []
};

/**
 * Will be instantiated by connectToIntercom, which is called by watchStatusForService.
 * Variable will be used as Singleton.
 */
let socket: WebSocket | null = null;

const connectToIntercom = () => {
  const parsedUrl = url.parse(serviceBaseUrl);
  const websocketAddress = `ws://${parsedUrl.host}`;

  const onMessage = (message: any) => {
    // Only iterate if possible and log failed calls
    listeners.fns &&
      listeners.fns.forEach((fn: StatusWatcher) => {
        try {
          fn(message);
        } catch (error) {
          console.error("Failed to handle intercom message.", error);
        }
      });
  };

  const onError = (err: any) => {
    console.error("Connection error to intercom server", websocketAddress, err);
  };

  const onClose = () => {
    console.warn("Connection to intercom server closed", websocketAddress);
    connectAfterDelay();
  };

  const onOpen = () => {
    console.log("Connected successfully to intercom server", websocketAddress);
  };

  const connect = () => {
    console.log("Connect to intercom server", websocketAddress);

    try {
      socket = new WebSocket(websocketAddress);

      socket.onmessage = onMessage;
      socket.onerror = onError;
      socket.onclose = onClose;
      socket.onopen = onOpen;
    } catch (error) {
      console.error("Could not connect to intercom websocket\n", error);
      return;
    }
  };

  const connectAfterDelay = (delay = 3000) =>
    setTimeout(() => {
      console.warn("Trying to reconnect to intercom server", websocketAddress);
      connect();
    }, delay);

  if (socket === null) {
    connect();
  }
};

/**
 * normalize different Data formats
 * @param ev
 * @returns {any}
 */
const getEventData = (ev: Message) => {
  const getRelevantFields = R.pick(["service", "status", "text", "time"]);
  const extractData: Function = isNil(R.prop("service", ev))
    ? R.compose(getRelevantFields, JSON.parse, R.prop("data"))
    : getRelevantFields;
  return extractData(ev);
};

/**
 * Whenever the status of <serviceName> changes, <callback> gets called with the full MessageEvent.
 * The return value should be unregistered when the component gets unmounted to avoid memory leaks.
 *
 * @param {string} serviceName
 * @param {StatusWatcher} callback
 * @returns {StatusWatcher}
 */
export const watchStatusForService = (serviceName: string, callback: StatusWatcher): StatusWatcher => {
  connectToIntercom();

  const watcher: StatusWatcher = ev => {
    const eventData = getEventData(ev);
    const { service } = eventData;
    if (service === serviceName) {
      callback(eventData);
    }
  };

  listeners.fns.push(watcher);
  return watcher;
};

/**
 * Unregister an existing message event
 * @param {StatusWatcher} watcher, usually retrieved by calling watchStatusForService or whenServiceIsReady
 */
export const removeServiceStatusCallback = (watcher: StatusWatcher) => {
  listeners.fns = R.without([watcher], listeners.fns);
};

export const timeoutWithStatusKeepalive = <T>(
  service: string,
  handlerWithCallbacks: (resolve: (val: T) => void, reject: Function, resetTimeout: Function) => (msg: Message) => void,
  timeoutInSeconds: number = 30
): Promise<T> => {
  return new Promise((resolve, reject) => {
    let timer: NodeJS.Timeout;

    const maybeRemoveTimeout = () => {
      if (timer) {
        clearTimeout(timer);
      }
    };

    const resetTimeout = throttle(() => {
      maybeRemoveTimeout();
      timer = setTimeout(() => reject("Timeout!"), timeoutInSeconds * 1000);
    }, 1000);

    const cleanUpAndResolve = (val: T) => {
      maybeRemoveTimeout();
      removeServiceStatusCallback(watcher);
      resolve(val);
    };

    const cleanUpAndReject = (msg?: any) => {
      maybeRemoveTimeout();
      reject(msg);
    };

    resetTimeout();
    const watcher = handlerWithCallbacks(cleanUpAndResolve, cleanUpAndReject, resetTimeout);
    watchStatusForService(service, watcher);
  });
};

// For backend development
// Pipe messages from real system frontend to my local logger
// export const report = (msg: any) =>
//   fetch("http://10.10.2.106:3000", {
//     method: "POST",
//     body: JSON.stringify({ mymsg: msg }),
//     headers: { "Content-type": "application/json" }
//   }).catch(console.warn);
