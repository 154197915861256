import React from "react";
import { push } from "connected-react-router";
import { FormikErrors, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";

import { prependServiceBaseUrl } from "../../../../commons/libs/client-utils";
import { ensureError } from "../../../../commons/libs/error";
import { KeyboardType } from "../../../../commons/types/keyboard";
import { ModalProps, PrintMailModalContentProps } from "../../../../commons/types/modal";
import { PdfMetaData } from "../../../../commons/types/pdf";
import { PrintType } from "../../../../commons/types/print-mail";
import * as icons from "../../../../resources/icons";
import { ROUTES } from "../../../commons/routes";
import actions from "../../actions";
import Button from "../../components/Button/Button";
import Headline from "../../components/Headline/Headline";
import Icon from "../../components/Icon/Icon";
import IconButton from "../../components/IconButton/IconButton";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import Modal from "../../components/Modal/Modal";
import Paragraph from "../../components/Paragraph/Paragraph";
import PdfPage from "../../components/PdfPage/PdfPage";
import PinField from "../../components/PinField/PinField";
import PrintModalLayout from "../../components/PrintModalLayout/PrintModalLayout";
import PrintPreview from "../../components/PrintPreview/PrintPreview";
import RadioField from "../../components/RadioField/RadioField";
import RadioGroupWithOther from "../../components/RadioGroupWithOther/RadioGroupWithOther";
import config from "../../config";
import { compareHash } from "../../libs/externals/hash";
import useFetchData from "../../libs/hooks/use-fetch-data";
import print, { createPreviewUrl } from "../../libs/print";
import { selectInitializedSettings } from "../../libs/selectors";
import { getPdfMetadataFromUrl } from "../../libs/services/pdf-service/pdf";
import { getPrintQueue, PrinterStatusResponse } from "../../libs/services/print-service/print";
import { State } from "../../reducers";
import FormPartial from "../partials/Form";
import LabeledField from "../partials/LabeledField";
import { connect } from "../utils/loop";
import { useTrackingContext } from "../utils/tracking-context";

interface FormValues {
  code: string;
  numCopies: string;
}

const mapStateToProps = (state: State) => ({
  mailToPrintSettings: selectInitializedSettings(state).printing.mailToPrint,
  personalCode: selectInitializedSettings(state).personalCode ?? "",
  pinRequired: selectInitializedSettings(state).printing.pinRequired
});

const mapDispatchToProps = {
  onPush: push,
  showToast: actions.toasts.showToast
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps extends ModalProps<PrintMailModalContentProps> {
  isMobile?: boolean;
  isOfflineAndMailToPrintIsEnabled: boolean;
  isUsbPrinterConnectedOrMailToPrintEnabled: boolean;
  nodeRef?: React.Ref<HTMLElement>;
  printerStatus?: PrinterStatusResponse;
  requestKeyboardClose?: () => void;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const NumberOfCopies = (props: Omit<React.ComponentProps<typeof RadioGroupWithOther>, "children">) => (
  <RadioGroupWithOther {...props} block>
    <RadioField label="1" value="1" />
    <RadioField label="2" value="2" />
  </RadioGroupWithOther>
);

const PrintModalPartial = ({
  data: printData,
  mailToPrintSettings,
  personalCode,
  pinRequired,
  close,
  onPush,
  showToast,
  isMobile = false,
  isOfflineAndMailToPrintIsEnabled,
  isUsbPrinterConnectedOrMailToPrintEnabled,
  nodeRef,
  printerStatus,
  requestKeyboardClose
}: Props) => {
  const { t } = useTranslation(["commons"]);

  const { mixpanel } = useTrackingContext();

  const formik = useFormik<FormValues>({
    initialValues: {
      code: "",
      numCopies: "1"
    },
    validateOnMount: true,
    validate: values => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.numCopies || values.numCopies.match(/[^0-9]/)) {
        errors.numCopies = t("commons:printModalPartialApp.errors.invalidPrintQuantity");
      } else if (parseInt(values.numCopies, 10) <= 0) {
        errors.numCopies = t("commons:printModalPartialApp.errors.minOneCopyRequired");
      } else if (parseInt(values.numCopies, 10) > config.shared.printMaxCopies) {
        errors.numCopies = t("commons:printModalPartialApp.errors.maxCopiesAllowed", {
          count: config.shared.printMaxCopies
        });
      }

      if (pinRequired && !compareHash(values.code, personalCode)) {
        errors.code = t("commons:printModalPartialApp.errors.invalidPersonalCode");
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting, setTouched }) => {
      if (printData.type === PrintType.ProductDataSheet) {
        printData.items.map(item =>
          mixpanel?.trackProductPrinted(
            { type: printData.type, numCopies: values.numCopies, initiator: printData.initiator },
            item.product
          )
        );
      } else {
        mixpanel?.trackPrinted({ type: printData.type, numCopies: values.numCopies });
      }

      requestKeyboardClose?.();
      close();

      try {
        await print(printData, parseInt(values.numCopies, 10), mailToPrintSettings);
        const printQueue = await getPrintQueue();
        const toastTitle = t("commons:printModalPartialApp.docsInPrint", { count: printQueue.length });

        const printTypeTitle = {
          [PrintType.ProductDataSheet as string]: t("commons:printModalPartialApp.dataSheetIsPrintedTitle"),
          [PrintType.Default as string]: t("commons:printModalPartialApp.isPrintedTitle")
        };

        showToast({
          title: `${printTypeTitle[printData.type]}: ${toastTitle}`,
          timeout: 10,
          icon: icons.IconSmallPrinter
        });
      } catch (error) {
        const model =
          printerStatus && printerStatus.length > 0 && printerStatus[0]
            ? printerStatus[0].model
            : t("commons:printModalPartialApp.cloudPrinting");

        showToast({
          title: t("commons:printModalPartialApp.errorWhilePrintNotification.errorWhilePrintTitle"),
          timeout: 5,
          icon: icons.IconSmallPrinter,
          kind: "negative",
          primaryAction: {
            label: t("commons:printModalPartialApp.errorWhilePrintNotification.moreInformationLabel"),
            callback: () =>
              actions.modal.openErrorModal({
                type: "print",
                errorMessage: ensureError(error).message,
                headline: t("commons:printModalPartialApp.errorWhilePrintNotification.moreInformationHeadline"),
                paragraph: t("commons:printModalPartialApp.errorWhilePrintNotification.errorWhilePrintExplanation", {
                  product: model
                })
              })
          }
        });

        mixpanel?.trackPrintFailed();
      }

      setSubmitting(false);
      setTouched({});
    }
  });

  const previewUrl = React.useMemo(() => createPreviewUrl(printData), [printData]);
  const previewPdf = useFetchData<PdfMetaData>(() => getPdfMetadataFromUrl(previewUrl), [previewUrl]);

  React.useEffect(() => {
    if (formik.values.code.length === config.shared.personalCodeLength) {
      formik.setFieldTouched("code");
      requestKeyboardClose && requestKeyboardClose();
    }
    // formik and keyboard close function must not be deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.code]);

  const pdfPreviewSource =
    !previewPdf.isLoading && previewPdf?.data?.pages && previewPdf?.data?.pages?.length > 0
      ? prependServiceBaseUrl(previewPdf.data.pages[0])
      : "";

  const pdfUrl = !previewPdf.isLoading && previewPdf?.data?.url ? prependServiceBaseUrl(previewPdf?.data?.url) : "";

  const renderNoPrinterEnabledContent = () => (
    <>
      <Headline kind="m" classNames={["u-space-l"]}>
        {t("commons:printModalPartialApp.noPrinterEnabled.noPrinterConnectedHeadline")}
      </Headline>
      <span>
        <Button
          variant="accent"
          kind="solid"
          icon={<Icon source={icons.IconSmallArrowRight} />}
          iconRight
          onClick={() => {
            close({ preventReopenPreviousModal: false });
            onPush(ROUTES.SETTINGS.PRINTER_INDEX);
          }}
        >
          {t("commons:printModalPartialApp.noPrinterEnabled.setUpPrinterButton")}
        </Button>
      </span>
    </>
  );

  const renderNeedsOnlineConnectionContent = () => (
    <>
      kind
      <Headline kind="m" classNames={["u-space-l"]}>
        {t("commons:printModalPartialApp.needsOnlineConnection.deviceOfflineHeadline")}
      </Headline>
      <span>
        <Button
          classNames={["u-space-l"]}
          variant="accent"
          kind="solid"
          icon={<Icon source={icons.IconSmallArrowRight} />}
          iconRight
          onClick={() => {
            close({ preventReopenPreviousModal: false });
            onPush(ROUTES.SETTINGS.NETWORK_INDEX);
          }}
        >
          {t("commons:printModalPartialApp.needsOnlineConnection.connectNowButton")}
        </Button>
        <Paragraph kind="support">
          {t("commons:printModalPartialApp.needsOnlineConnection.internetIsRequiredForCloudPrinting")}
        </Paragraph>
      </span>
    </>
  );

  const renderPrintFormContent = () => (
    <>
      <Headline kind="m" classNames={["u-space-l"]}>
        {t("commons:printModalPartialApp.printPreviewHeadline")}
      </Headline>
      <FormPartial formik={formik}>
        <LabeledField formik={formik} label={t("commons:printModalPartialApp.copiesLabel")} classNames={["u-space-l"]}>
          <NumberOfCopies
            name="numCopies"
            placeholder={t("commons:printModalPartialApp.quantityLabel")}
            keyboardType={KeyboardType.Numpad}
          />
        </LabeledField>
        {pinRequired && (
          <LabeledField
            formik={formik}
            hideErrorText
            label={t("commons:printModalPartialApp.enterPersonalCodeToPrintLabel")}
            classNames={["u-space-l"]}
          >
            <PinField name="code" length={config.shared.personalCodeLength} />
          </LabeledField>
        )}
        <Button
          type="submit"
          disabled={
            formik.isSubmitting ||
            !formik.isValid ||
            !formik.values ||
            (formik.values?.code?.length !== config.shared.personalCodeLength && pinRequired)
          }
          variant="accent"
          kind="solid"
          icon={formik.isSubmitting ? <LoadingIndicator size="s" /> : <Icon source={icons.IconSmallPrinter} />}
          iconRight
        >
          {t("commons:printModalPartialApp.printNowButton")}
        </Button>
      </FormPartial>
    </>
  );

  const renderOpenInNewTabContent = () => (
    <>
      <Button
        classNames={["u-space-top-xs"]}
        icon={<Icon source={icons.IconSmallArrowRight} />}
        iconRight
        onClick={() => window.open(pdfUrl)}
        disabled={previewPdf.isLoading}
      >
        {t("commons:printModalPartialApp.openInNewTabButton")}
      </Button>
    </>
  );

  const renderDesktopContent = () =>
    !isUsbPrinterConnectedOrMailToPrintEnabled
      ? renderNoPrinterEnabledContent()
      : isOfflineAndMailToPrintIsEnabled
        ? renderNeedsOnlineConnectionContent()
        : renderPrintFormContent();

  const renderMobileContent = () => (
    <>
      {isUsbPrinterConnectedOrMailToPrintEnabled && renderPrintFormContent()}
      {renderOpenInNewTabContent()}
    </>
  );

  return (
    <Modal
      nodeRef={nodeRef}
      width="xl"
      closeButton={<IconButton onClick={() => close()} icon={<Icon source={icons.IconSmallCross} />} />}
    >
      <PrintModalLayout
        printPreview={
          <PrintPreview loaded={!previewPdf.isLoading}>
            <PdfPage source={pdfPreviewSource} />
          </PrintPreview>
        }
      >
        {isMobile ? renderMobileContent() : renderDesktopContent()}
      </PrintModalLayout>
    </Modal>
  );
};

export default connector(PrintModalPartial);
