import { compact } from "lodash";
import qs from "qs";
import * as R from "ramda";

import { Product, ProductId, ProductSpecKey, ProductType, StandaloneProductVariant } from "../specs/product";
import { Brand } from "../types/brand";
import { Currency } from "../types/currency";
import { ImageManipulationOptions } from "../types/image-manipulation";
import { ProductPrintAdvertisement } from "../types/print-mail";
import { AssortmentPriceSettings } from "../types/settings";

import { buildServiceUrl, UrlParams } from "./client-utils";
import { getSingleSpecValue } from "./specs";

export const getProductImageFile = <Type extends ProductType>(
  product: Product<Type> | StandaloneProductVariant<Type>,
  useMainImage = true
) => {
  const mainImageFile = getSingleSpecValue(ProductSpecKey.MainImageFile, product);
  return !useMainImage || R.isNil(mainImageFile)
    ? getSingleSpecValue(ProductSpecKey.ImageFile, product)
    : mainImageFile;
};

export const getProductImageUrl = <Type extends ProductType>(
  product: Product<Type> | StandaloneProductVariant<Type>,
  options: ImageManipulationOptions = {},
  useMainImage = true
) => {
  const brandKey = getSingleSpecValue(ProductSpecKey.BrandKey, product);
  return getPackageFileUrl(
    `${brandKey}-bikes/images/${getProductImageFile(product, useMainImage)}`,
    compact([
      !!options.width && { param: "width", value: options.width },
      !!options.format && { param: "format", value: options.format }
    ])
  );
};

export const getBrandImageUrl = (brand: Brand): string => getPackageFileUrl(getBrandImagePath(brand));

export const getBrandImagePath = (brand: Brand): string => `/brands/logos/${brand.logoFilename}`;

export const getPackageFileUrl = (filePath: string, params?: UrlParams, baseUrl?: string): string =>
  buildServiceUrl("content", `packages/${filePath}`, params, baseUrl).toString();

export const getPrintResourcesFileUrl = (fileName: string) => {
  return buildServiceUrl("print", `/resources/${fileName}`).toString();
};

const getProductPreviewPath =
  (ext: "pdf" | "html") =>
  (
    productId: ProductId,
    bikeCenterName: string,
    showPrices: boolean,
    advertisement: ProductPrintAdvertisement | undefined,
    currency: Currency,
    showAssortmentPrices: boolean = false,
    showFallbackPrices: boolean = false,
    showOriginalPrices: boolean = false,
    useRrpAsOriginalPrice: boolean = true,
    showSaleBadge: AssortmentPriceSettings["showSaleBadge"] = null
  ): string => {
    const query = {
      bikeCenterName,
      showPrices,
      advertisement,
      currency,
      showAssortmentPrices,
      showFallbackPrices,
      showOriginalPrices,
      useRrpAsOriginalPrice,
      showSaleBadge
    };
    const queryString = qs.stringify(query);
    return `product/preview/${encodeURIComponent(productId)}.${ext}?${queryString}`;
  };

export const getProductHtmlPreviewPath = getProductPreviewPath("html");

export const getProductPdfPreviewPath = getProductPreviewPath("pdf");

export const getProductPrintPath = (
  productId: ProductId,
  numCopies: number,
  bikeCenterName: string,
  showPrices: boolean,
  advertisement: ProductPrintAdvertisement | undefined,
  currency: Currency,
  showAssortmentPrices: boolean = false,
  showFallbackPrices: boolean = false,
  showOriginalPrices: boolean = false,
  useRrpAsOriginalPrice: boolean = true,
  showSaleBadge: AssortmentPriceSettings["showSaleBadge"] = null
): string => {
  const query = {
    bikeCenterName,
    showPrices,
    advertisement,
    currency,
    numCopies,
    showAssortmentPrices,
    showFallbackPrices,
    showOriginalPrices,
    useRrpAsOriginalPrice,
    showSaleBadge
  };
  const queryString = qs.stringify(query);
  return `product/${encodeURIComponent(productId)}?${queryString}`;
};
