import { Action } from "redux";

import { addBikeIdsToManualAssortment, deleteBikeIdsFromManualAssortment } from "../../../commons/libs/assortment";
import { ManualAssortmentItem } from "../../../commons/types/assortment";
import { PackageInfo } from "../../../commons/types/content";
import { Env } from "../../../commons/types/env";
import { Actions } from "../actions";
import * as coreActions from "../actions/core";
import { InnerReducer, mapReducerStateWithKey } from "../libs/reducer";

import { State } from "./";

export const NAME = "core";

export type CoreState = {
  [NAME]: {
    isInitialized: boolean;
    lastInteraction: number;
    env?: Env;
    contentPackages: PackageInfo[];
    manualAssortment: ManualAssortmentItem[];
  };
};

export const initialState: CoreState = {
  [NAME]: {
    isInitialized: false,
    lastInteraction: 0,
    env: undefined,
    contentPackages: [],
    manualAssortment: []
  }
};

export type CoreInnerReducer<A extends Action = Actions> = InnerReducer<State, CoreState[typeof NAME], A>;

export const handleInitDone: CoreInnerReducer = state => ({
  ...state[NAME],
  isInitialized: true,
  lastInteraction: new Date().getTime()
});

export const handleContentPackagesReceived: CoreInnerReducer<coreActions.ContentPackagesReceivedAction> = (
  state,
  action
) => ({
  ...state[NAME],
  contentPackages: action.payload
});

export const handleEnvReceived: CoreInnerReducer<coreActions.EnvReceivedAction> = (state, action) => ({
  ...state[NAME],
  env: action.payload
});

export const handleInteract: CoreInnerReducer<coreActions.InteractAction> = state => ({
  ...state[NAME],
  lastInteraction: new Date().getTime()
});

export const handleManualAssortmentReceived: CoreInnerReducer<coreActions.ManualAssortmentReceivedAction> = (
  state,
  action
) => ({
  ...state[NAME],
  manualAssortment: action.payload
});

export const handleManualAssortmentAdd: CoreInnerReducer<coreActions.AddManualAssortmentAction> = (state, action) => ({
  ...state[NAME],
  manualAssortment: addBikeIdsToManualAssortment(action.payload, state[NAME].manualAssortment)
});

export const handleManualAssortmentDelete: CoreInnerReducer<coreActions.DeleteManualAssortmentAction> = (
  state,
  action
) => ({
  ...state[NAME],
  manualAssortment: deleteBikeIdsFromManualAssortment(action.payload, state[NAME].manualAssortment)
});

const reducer: InnerReducer<State, CoreState[typeof NAME], Actions> = (state, action) => {
  switch (action.type) {
    case "CORE.INIT_DONE":
      return handleInitDone(state, action);
    case "CORE.CONTENT_PACKAGES_RECEIVED":
      return handleContentPackagesReceived(state, action);
    case "CORE.ENV_RECEIVED":
      return handleEnvReceived(state, action);
    case "CORE.INTERACT":
      return handleInteract(state, action);
    case "CORE.MANUAL_ASSORTMENT_RECEIVED":
      return handleManualAssortmentReceived(state, action);
    case "CORE.MANUAL_ASSORTMENT_ADD":
      return handleManualAssortmentAdd(state, action);
    case "CORE.MANUAL_ASSORTMENT_DELETE":
      return handleManualAssortmentDelete(state, action);
    default:
      return state[NAME];
  }
};

export default mapReducerStateWithKey(reducer, NAME);
