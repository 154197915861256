import { useCallback } from "react";

import { getActiveBrands, getAvailableBrands } from "../../../../commons/libs/content-service";
import actions from "../../actions";

import useDispatch from "./use-dispatch";
import useSelector from "./use-selector";

const useFetchBrands = () => {
  const dispatch = useDispatch();
  const activeBrands = useSelector(state => state.brands.active);
  const availableBrands = useSelector(state => state.brands.available);

  const fetchActiveBrands = useCallback(
    () =>
      getActiveBrands()
        .then(activeBrands => dispatch(actions.brands.receivedActive(activeBrands)))
        .catch(error => dispatch(actions.error.set(error))),
    [dispatch]
  );

  const fetchAvailableBrands = useCallback(
    () =>
      getAvailableBrands()
        .then(availableBrands => dispatch(actions.brands.receivedAvailable(availableBrands)))
        .catch(error => dispatch(actions.error.set(error))),
    [dispatch]
  );

  const fetchAllBrands = useCallback(
    () => Promise.all([fetchAvailableBrands(), fetchActiveBrands()]),
    [fetchActiveBrands, fetchAvailableBrands]
  );

  return { fetchAllBrands, fetchActiveBrands, fetchAvailableBrands, activeBrands, availableBrands };
};

export default useFetchBrands;
