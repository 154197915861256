import { Brand } from "../../../commons/types/brand";
import { Action } from "../../../commons/types/redux";

export type ReceivedAvailableAction = Action<"BRANDS.RECEIVED_AVAILABLE", Brand[]>;
export type ReceivedActiveAction = Action<"BRANDS.RECEIVED_ACTIVE", Brand[]>;

export type BrandsActions = ReceivedAvailableAction | ReceivedActiveAction;

export const receivedAvailable = (payload: Brand[]): ReceivedAvailableAction => ({
  type: "BRANDS.RECEIVED_AVAILABLE",
  payload
});

export const receivedActive = (payload: Brand[]): ReceivedActiveAction => ({
  type: "BRANDS.RECEIVED_ACTIVE",
  payload
});
