import React from "react";
import { replace } from "connected-react-router";
import { chunk, isUndefined, keyBy } from "lodash";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";
import { useLocation, useParams } from "react-router";

import { getBrandKeyLogoMap } from "../../../../commons/libs/brands";
import { getCustomerGroupConfig } from "../../../../commons/libs/config";
import { getProducts } from "../../../../commons/libs/content-service";
import { getBrandImageUrl } from "../../../../commons/libs/resource-paths";
import { getFirstVariantId, getProductVariants } from "../../../../commons/libs/specs";
import { getSpecConfig } from "../../../../commons/specs";
import { CustomBicycleFilterKey } from "../../../../commons/specs/bicycle";
import {
  ActiveFilters,
  FilterConfigConstKeys,
  FilterKey,
  getTypedFilterConfigEntries
} from "../../../../commons/specs/filters";
import {
  Category,
  CategoryMap,
  Product,
  ProductId,
  ProductKey,
  ProductSpecKey,
  ProductType
} from "../../../../commons/specs/product";
import { AssortmentType } from "../../../../commons/types/assortment";
import { GlobalLocationState } from "../../../../commons/types/location";
import { ProductFinderViewMode } from "../../../../commons/types/settings";
import * as icons from "../../../../resources/icons";
import actions from "../../actions";
import BrandImage from "../../components/BrandImage/BrandImage";
import Button from "../../components/Button/Button";
import CenteredContent from "../../components/CenteredContent/CenteredContent";
import ContentTile from "../../components/ContentTile/ContentTile";
import CurrentPageBreadcrumb from "../../components/CurrentPageBreadcrumb/CurrentPageBreadcrumb";
import FlexLayout from "../../components/FlexLayout/FlexLayout";
import Headline from "../../components/Headline/Headline";
import Icon from "../../components/Icon/Icon";
import ItemList from "../../components/ItemList/ItemList";
import LazyContent from "../../components/LazyContent/LazyContent";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import Paragraph from "../../components/Paragraph/Paragraph";
import ProductFilterList from "../../components/ProductFilterList/ProductFilterList";
import ProductFinderPageLayout from "../../components/ProductFinderPageLayout/ProductFinderPageLayout";
import SearchListItem from "../../components/SearchListItem/SearchListItem";
import TileGrid from "../../components/TileGrid/TileGrid";
import config from "../../config";
import { getProductFiltersFromActiveFilters } from "../../libs/filters";
import useDebounce from "../../libs/hooks/use-debounce";
import useFetchData from "../../libs/hooks/use-fetch-data";
import { useMediaQuery } from "../../libs/hooks/use-media-query";
import useProductFilter from "../../libs/hooks/use-product-filter";
import useRestoreScrollPosition from "../../libs/hooks/use-restore-scroll-position";
import useSelector from "../../libs/hooks/use-selector";
import useSizingAvailability from "../../libs/hooks/use-sizing-availability";
import useVeloconnectEndpoints from "../../libs/hooks/use-veloconnect-endpoints";
import { buildPath } from "../../libs/path";
import {
  CategorizedProduct,
  createProductListContextFromProducts,
  getGroupedProductsByView,
  GroupedProductsByBrand
} from "../../libs/products";
import * as selectors from "../../libs/selectors";
import { State } from "../../reducers";
import { ROUTES } from "../../routes";
import FilterListItemTooltipPartial from "../App/FilterListItemTooltipPartial";
import ModalContainerWithStatePartial from "../Modal/ModalContainerWithStatePartial";
import { connect } from "../utils/loop";
import { useTrackingContext } from "../utils/tracking-context";
import { VeloconnectEndpointsProvider } from "../utils/veloconnect-endpoints-context";

import FilterModalPartial from "./FilterModalPartial";
import ProductTilePartial from "./ProductTilePartial";

// Important: These sizes need to be manually synchronized to the stylings
const TITLE_GRID_HEADER_HEIGHT = 68;
const BIKE_TILE_HEIGHT = 240;
const BIKE_TILE_HEIGHT_S = 200;
const GRID_GAP = 2;

interface ProductFinderRendererProps<Type extends ProductType> {
  highlightedBrandKeys: string[];
  products: Product[] | null;
  productType: ProductType;
  brandKey?: string;
  searchTerm: string;
  viewMode: ProductFinderViewMode;
  scrollRef: React.RefObject<HTMLDivElement>;
  activeFilters: ActiveFilters<Type>;
}

const ProductFinderRenderer = React.memo(
  <Type extends ProductType>({
    highlightedBrandKeys,
    products,
    productType,
    brandKey,
    searchTerm,
    viewMode,
    scrollRef,
    activeFilters
  }: ProductFinderRendererProps<Type>) => {
    const brands = useSelector(state => state.brands.active);

    const { t } = useTranslation(["commons"]);
    const breakpoints = useMediaQuery();
    const gridColumns = breakpoints.xl ? 4 : 3;

    const brandsMap = React.useMemo(() => keyBy(brands, brand => brand.key), [brands]);

    const onPushAndStoreScrollPosition = useRestoreScrollPosition(scrollRef, !!products && products.length > 0);

    const goToProduct = (dataSource: Product[] | GroupedProductsByBrand, id: ProductId, variantId?: ProductId) => {
      const state: GlobalLocationState = {
        backLabel: t("commons:productFinderPartialProductFinder.backToProductFinderButton"),
        productListContext: createProductListContextFromProducts(dataSource),
        variantId
      };
      onPushAndStoreScrollPosition(buildPath(ROUTES.PRODUCT_DETAILS.INDEX, { id: String(id) }), state);
    };

    const renderUngroupedView = (products: Product[]) => {
      const gridRows = 2;

      return (
        <div>
          {chunk(products, gridColumns * gridRows).map((chunk, index) => (
            <LazyContent
              key={index}
              height={
                Math.ceil(chunk.length / gridColumns) * (breakpoints.xl ? BIKE_TILE_HEIGHT : BIKE_TILE_HEIGHT_S) +
                (Math.ceil(chunk.length / gridColumns) - 1) * GRID_GAP
              }
            >
              {({ isVisible }) =>
                isVisible /* Space-top modifier is needed to keep space between TileGrids in sync with space between TileGrid rows. */ ? (
                  <TileGrid columns={gridColumns} fill classNames={[index > 0 ? "u-space-top-xxxs" : ""]}>
                    {chunk.map(product => (
                      <ProductTilePartial
                        key={product[ProductKey.ProductId]}
                        product={product}
                        brandKey={brandKey}
                        activeFilters={activeFilters}
                        asCategoryViewMode={!brandKey}
                        onClick={() => goToProduct(products, product[ProductKey.ProductId], getFirstVariantId(product))}
                      />
                    ))}
                  </TileGrid>
                ) : null
              }
            </LazyContent>
          ))}
        </div>
      );
    };

    const renderGroupedView = (productType: ProductType, groupedProducts: GroupedProductsByBrand) =>
      Object.entries(groupedProducts).map(([currentBrandKey, categories]) => (
        <React.Fragment key={currentBrandKey}>
          {!brandKey && brandsMap[currentBrandKey] && (
            <ContentTile>
              <FlexLayout gap="s" justifyContent="center" alignItems="center">
                {brandsMap[currentBrandKey].highlighted ? (
                  <Icon source={icons.IconMediumHighlight} size="l" />
                ) : undefined}
                <BrandImage position="center" src={getBrandImageUrl(brandsMap[currentBrandKey])} />
              </FlexLayout>
            </ContentTile>
          )}
          {(Object.entries(categories) as [Category, CategorizedProduct][]).map(([categoryKey, categorizedProduct]) =>
            Object.entries(categorizedProduct)
              .filter(([, categoryProducts]) => categoryProducts.length !== 0)
              .map(([engineKey, categoryProducts]) => (
                <LazyContent
                  key={`${currentBrandKey}-${categoryKey}-${engineKey}`}
                  height={
                    // Placeholder height
                    TITLE_GRID_HEADER_HEIGHT +
                    Math.ceil(categoryProducts.length / gridColumns) *
                      (breakpoints.xl ? BIKE_TILE_HEIGHT : BIKE_TILE_HEIGHT_S) +
                    (Math.ceil(categoryProducts.length / gridColumns) - 1) * GRID_GAP
                  }
                >
                  {({ isVisible }) => {
                    return (
                      isVisible && (
                        <TileGrid
                          columns={gridColumns}
                          key={`${currentBrandKey}-${categoryKey}-${engineKey}`}
                          stickyHeader
                          fill
                          header={
                            <FlexLayout gap="s" justifyContent="center" alignItems="center">
                              {!brandKey && brandsMap[currentBrandKey]?.highlighted ? (
                                <Icon source={icons.IconSmallHighlight} />
                              ) : undefined}
                              <Headline kind={breakpoints.l ? "m" : "base"}>
                                {brandsMap[currentBrandKey] && brandsMap[currentBrandKey].displayName}{" "}
                                {/* Limitation of TypeScript:
                              https://github.com/microsoft/TypeScript/issues/57388 */}
                                {t(
                                  `commons:specs.${productType}.categories.${categoryKey}.${engineKey === "withEngine" ? "withEngine" : "default"}` as keyof {
                                    [T in ProductType as `commons:specs.${T}.categories.${CategoryMap[T]}.${T extends ProductType.Bicycle ? "default" | "withEngine" : "default"}`]: null;
                                  }
                                )}
                              </Headline>
                            </FlexLayout>
                          }
                        >
                          {categoryProducts.map(product => (
                            <ProductTilePartial
                              key={product[ProductKey.ProductId]}
                              product={product}
                              activeFilters={activeFilters}
                              brandKey={brandKey}
                              asCategoryViewMode={viewMode === ProductFinderViewMode.GroupedByCategoryOnly}
                              onClick={() =>
                                goToProduct(groupedProducts, product[ProductKey.ProductId], getFirstVariantId(product))
                              }
                            />
                          ))}
                        </TileGrid>
                      )
                    );
                  }}
                </LazyContent>
              ))
          )}
        </React.Fragment>
      ));

    if (products === null) {
      return (
        <CenteredContent>
          <LoadingIndicator />
        </CenteredContent>
      );
    }

    if (products && products.length === 0) {
      return (
        <CenteredContent>
          <Paragraph size="l" classNames={["u-text-center"]}>
            {t("commons:productFinderPartialProductFinder.noResultsFound")}
          </Paragraph>
        </CenteredContent>
      );
    }

    if (searchTerm) {
      return renderUngroupedView(products);
    }

    return renderGroupedView(productType, getGroupedProductsByView(products, viewMode, highlightedBrandKeys));
  }
);

ProductFinderRenderer.displayName = "ProductFinderRenderer";

const mapStateToProps = (state: State) => ({
  brands: state.brands.available,
  activeBrands: state.brands.active,
  customization: selectors.selectInitializedSettings(state).customization,
  currency: selectors.selectInitializedSettings(state).currency,
  assortment: selectors.selectInitializedSettings(state).assortment,
  assortmentFilterSettings: selectors.selectAssortmentFilterSettings(state),
  assortmentPriceSettings: selectors.selectAssortmentPriceSettings(state),
  bodySizingEnabled: selectors.selectIsBodySizingEnabled(state),
  bodySizingNotificationsEnabled: selectors.selectIsBodySizingNotificationsEnabled(state),
  customerGroup: getCustomerGroupConfig(selectors.selectInitializedEnv(state).customerGroup)
});

const mapDispatchToProps = {
  onError: actions.error.set,
  onReplace: replace
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps {
  backButton: () => React.ReactNode;
  isOffline: boolean;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const ProductFinderPartial = ({
  assortment,
  assortmentFilterSettings,
  assortmentPriceSettings,
  currency,
  brands,
  activeBrands,
  customization,
  onError,
  onReplace,
  backButton,
  bodySizingEnabled,
  bodySizingNotificationsEnabled,
  isOffline,
  customerGroup
}: Props) => {
  const { t, i18n } = useTranslation(["commons"]);

  /** TODO: Get productType from Product.
   * @see BCD-6701 Integrate motorcycles into content service
   */
  const productType = customerGroup.productTypes[0];
  const specConfig = getSpecConfig(productType);
  const filterConfigEntries = getTypedFilterConfigEntries(specConfig.filterConfig);

  const scrollRef = React.useRef<HTMLDivElement>(null);
  const sidebarScrollRef = React.useRef<HTMLDivElement>(null);

  const { brand: brandKey } = useParams<{ brand?: string }>();
  const activeBrand = brandKey ? activeBrands.find(brand => brand.key === brandKey) : undefined;
  const highlightedBrandKeys = activeBrands.filter(brand => brand.highlighted).map(brand => brand.key);

  const { mixpanel } = useTrackingContext();
  const breakpoints = useMediaQuery();

  const location = useLocation<GlobalLocationState>();

  const [searchTerm, setSearchTerm] = React.useState(location?.state?.searchTerm ?? "");

  const veloconnectEndpoints = useVeloconnectEndpoints();

  const {
    activeFilters,
    clearAllFilters,
    clearFilter,
    clearFilters,
    error,
    filterValues,
    hasActiveFilters,
    selectedBrands,
    filterKeysWithInsufficientFilterValues,
    isFilterItemActive,
    isLoading: isFiltersLoading,
    setActiveFilters,
    wereDefaultActiveFiltersEvaluated
  } = useProductFilter({
    productType,
    assortmentFilterSettings,
    assortmentPriceSettings,
    automaticAssortmentFilter: assortment[AssortmentType.Automatic]?.filter,
    brandKey,
    shouldSetDefaultActiveFilters: location.state?.shouldSetDefaultActiveFilters
  });

  React.useEffect(() => {
    if (error) {
      onError(error);
    }
  }, [error, onError]);

  const [isAnyFilterModalOpen, setIsAnyFilterModalOpen] = React.useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, config.shared.filter.filterFetchDelay);

  const viewMode = isUndefined(brandKey)
    ? customization.productFinderViewMode
    : ProductFinderViewMode.GroupedByBrandAndCategory;

  const {
    data: { products = null, totalFiltered = 0, variantsLength = 0, usedSearchTerm = debouncedSearchTerm } = {},
    isLoading
  } = useFetchData(
    async () => {
      const specFilters = getProductFiltersFromActiveFilters(activeFilters, specConfig.filterConfig);
      const nextProductSpecFilters = brandKey
        ? [...specFilters, { key: ProductSpecKey.BrandKey, value: brandKey }]
        : specFilters;

      const { results: products, totalFiltered } = await getProducts<typeof productType>(
        currency,
        assortmentPriceSettings,
        nextProductSpecFilters,
        debouncedSearchTerm
      );

      const variantsLength = products.flatMap(getProductVariants).length;

      return { products, totalFiltered, variantsLength, usedSearchTerm: debouncedSearchTerm };
    },
    [currency, activeFilters, debouncedSearchTerm, brandKey, viewMode],
    {
      isEnabled: wereDefaultActiveFiltersEvaluated
    }
  );

  const brandsMap = React.useMemo(() => keyBy(brands, brand => brand.key), [brands]);

  React.useEffect(() => {
    const nextLocationState: GlobalLocationState = {
      ...(location.state ?? {}),
      activeFilters,
      searchTerm: debouncedSearchTerm
    };

    if (wereDefaultActiveFiltersEvaluated) {
      delete nextLocationState.shouldSetDefaultActiveFilters;
    }

    const nextPath = [location.pathname, location.search, location.hash].join("");

    // This doubles the number of renders in this partial and even in ProductFinderRenderer. But there seems to be no way to prevent this.
    onReplace(nextPath, nextLocationState);
    // Only update the location when the listed deps change otherwise the effect will trigger indefinitely.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, activeFilters, wereDefaultActiveFiltersEvaluated]);

  React.useEffect(() => {
    const hasActiveFiltersOrSearchTerm = Object.keys(activeFilters ?? {}).length > 0 || debouncedSearchTerm.length > 0;

    if (hasActiveFiltersOrSearchTerm) {
      mixpanel?.trackFilterChanged(activeFilters, debouncedSearchTerm);
    }
  }, [activeFilters, debouncedSearchTerm, mixpanel]);

  const onClearAllFiltersAndSearchTermAndBodyValues = () => {
    clearAllFilters(activeFilters);
    setSearchTerm("");
  };

  const sizingAvailability = useSizingAvailability(bodySizingEnabled && !isOffline);

  const renderSidebar = () => (
    <ProductFilterList
      scrollRef={sidebarScrollRef}
      header={
        <SearchListItem
          placeholder={t("commons:productFinderPartialProductFinder.searchForPlaceholder")}
          value={searchTerm}
          onChange={setSearchTerm}
        />
      }
      footer={
        <>
          <Button
            size="s"
            block
            variant="standout"
            icon={<Icon source={icons.IconSmallBin} />}
            disabled={!hasActiveFilters && searchTerm.length === 0}
            onClick={onClearAllFiltersAndSearchTermAndBodyValues}
          >
            {breakpoints.l
              ? t("commons:productFinderPartialProductFinder.deleteAllFiltersButton")
              : t("commons:productFinderPartialProductFinder.deleteFiltersButton")}
          </Button>
          <Paragraph kind="support" size={!breakpoints.xl ? "s" : "base"}>
            {isLoading
              ? t("commons:productFinderPartialProductFinder.loading")
              : t("commons:productFinderPartialProductFinder.foundModels", { count: totalFiltered })}
          </Paragraph>
        </>
      }
    >
      <ItemList>
        {filterConfigEntries
          // Hide assortment filter if device has no current assortment
          .filter(([key]) => key !== "manualAssortment" || assortment.type === AssortmentType.Manual)
          .filter(
            ([key]) =>
              key !== "automaticAssortment" ||
              (assortment.type === AssortmentType.Automatic && !assortmentFilterSettings.hideAutomaticAssortmentFilter)
          )
          // Hide this because this is handled in the FilterModalPartial for `AutomaticAssortment`
          .filter(([key]) => key !== ProductSpecKey.VeloconnectAssortment)
          // Hide brandName filter if brandKey is already defined via route parameter
          .filter(([key]) => key !== "brandName" || !brandKey)
          // Hide price filter if showPrices in settings is false
          .filter(([key]) => key !== "price" || customization.showPrices)
          .filter(
            ([key]) =>
              // TODO: This partial should be rebuilt to be more generic
              // `as` is used to cast the type to ProductType.Bicycle, because the current implementation is only used for bicycles
              (key as FilterKey<ProductType.Bicycle>) !== CustomBicycleFilterKey.Sizing ||
              bodySizingEnabled ||
              bodySizingNotificationsEnabled
          )
          .map(([key, productFilter], index) => (
            <ModalContainerWithStatePartial
              animationDuration={config.shared.transitionsDurations.fast}
              key={index}
              hideBackground={breakpoints.xl}
              position={
                // Since the `AvailabilityFilterModal` does expand and shrink its heigt,
                // we want to prevent too much layout shifting by using a different position for the `ModalContainer`
                // which keeps the top of the FilterModal in the top right but can expand to the bottom.
                breakpoints.xl && key === ProductSpecKey.AutomaticAssortment
                  ? "top-right"
                  : breakpoints.xl
                    ? "right"
                    : "center"
              }
              onOpenChange={isOpen => {
                if (isOpen) {
                  mixpanel?.trackFilterModalOpened(key);
                } else {
                  mixpanel?.trackFilterModalClosed(key);
                }
              }}
              modal={(isOpen, close) => (
                <FilterModalPartial
                  isProductLoading={isLoading}
                  isOpen={isOpen}
                  alignRight={breakpoints.xl}
                  productType={productType}
                  filterKey={key}
                  filterConfig={productFilter}
                  filterValues={filterValues}
                  activeFilters={activeFilters}
                  variantsLength={variantsLength}
                  onFilterChange={setActiveFilters}
                  clearFilter={clearFilter}
                  activeBrandsKeyMap={getBrandKeyLogoMap(activeBrands)}
                  onClose={close}
                  onOpenChange={setIsAnyFilterModalOpen}
                />
              )}
            >
              {(_isOpen, open) => {
                const titleKey = `commons:productFilterConfig.${productType}.productFilter.${key}.title` as keyof {
                  [T in ProductType as `commons:productFilterConfig.${T}.productFilter.${FilterConfigConstKeys<T>}.title`]: null;
                };

                const descriptionKey =
                  `commons:productFilterConfig.${productType}.productFilter.${key}.description` as keyof {
                    [T in ProductType as `commons:productFilterConfig.${T}.productFilter.${FilterConfigConstKeys<T>}.description`]: null;
                  };

                const title = t(titleKey);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Description is optional
                // @ts-ignore
                const description: string = i18n.exists(descriptionKey) ? t(descriptionKey) : "";

                return (
                  <FilterListItemTooltipPartial
                    filterKey={key}
                    filterConfig={productFilter}
                    filterValues={filterValues}
                    selectedBrands={selectedBrands}
                    activeFilters={activeFilters}
                    filterKeysWithInsufficientFilterValues={filterKeysWithInsufficientFilterValues}
                    label={title}
                    description={description}
                    active={
                      key === ProductSpecKey.AutomaticAssortment
                        ? isFilterItemActive(ProductSpecKey.VeloconnectAssortment, productFilter) ||
                          isFilterItemActive(key, productFilter)
                        : isFilterItemActive(key, productFilter)
                    }
                    variantsLength={variantsLength}
                    isOffline={isOffline}
                    preventTooltip={isAnyFilterModalOpen}
                    scrollRef={sidebarScrollRef}
                    onClick={open}
                    onRemove={() => {
                      mixpanel?.trackFilterCleared(key);
                      clearFilter(key);

                      // Since the FilterListItem for AutomaticAssortment, handles two spec keys, we clear both spec keys
                      if (key === ProductSpecKey.AutomaticAssortment) {
                        clearFilters([ProductSpecKey.AutomaticAssortment, ProductSpecKey.VeloconnectAssortment]);
                      }
                    }}
                    disabledReason={
                      (key as FilterKey<ProductType.Bicycle>) === CustomBicycleFilterKey.Sizing &&
                      bodySizingEnabled &&
                      !sizingAvailability.available
                        ? t("commons:productFinderPartialProductFinder.sizingServiceUnavailable")
                        : undefined
                    }
                  />
                );
              }}
            </ModalContainerWithStatePartial>
          ))}
      </ItemList>
    </ProductFilterList>
  );

  return (
    <VeloconnectEndpointsProvider endpoints={veloconnectEndpoints}>
      <ProductFinderPageLayout
        scrollRef={scrollRef}
        headerLeft={backButton()}
        headerRight={
          brandKey ? (
            <FlexLayout alignItems="center" gap="sl" classNames={["u-space-top-l"]}>
              {activeBrand?.highlighted ? <Icon source={icons.IconMediumHighlight} size="l" /> : undefined}
              <BrandImage position="right" src={getBrandImageUrl(brandsMap[brandKey])} />
            </FlexLayout>
          ) : (
            <CurrentPageBreadcrumb>
              {t("commons:productFinderPartialProductFinder.bikeFinderLabel")}
            </CurrentPageBreadcrumb>
          )
        }
        aside={!isFiltersLoading && renderSidebar()}
      >
        <ProductFinderRenderer
          highlightedBrandKeys={highlightedBrandKeys}
          products={products}
          activeFilters={activeFilters}
          productType={productType}
          brandKey={brandKey}
          searchTerm={usedSearchTerm}
          viewMode={viewMode}
          scrollRef={scrollRef}
        />
      </ProductFinderPageLayout>
    </VeloconnectEndpointsProvider>
  );
};

export default connector(ProductFinderPartial);
