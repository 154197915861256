import { Language } from "./localization";

export interface State {
  activeElement: HTMLInputElement | null;
  currentWebview: Electron.WebviewTag | null;
}

export interface InputFocusEvent extends FocusEvent {
  target: HTMLInputElement;
}

export interface ContextMenuEvent extends MouseEvent {
  target: HTMLElement;
}

export interface KeyboardPartialKeyHandler {
  onKey?: (key: string) => void;
  onKeyDown?: (key: string) => void;
  onKeyUp?: (key: string) => void;
}

export enum KeyboardBridgeEvent {
  RequestKeyboardOpen = "requestKeyboardOpen",
  RequestKeyboardOpenByHandle = "requestKeyboardOpenByHandle",
  RequestKeyboardClose = "requestKeyboardClose",
  RequestKeyboardHandleShow = "requestKeyboardHandleShow",
  RequestKeyboardHandleHide = "requestKeyboardHandleHide",
  KeyDown = "keyDown",
  KeyUp = "keyUp",
  KeyPress = "keyPress",
  KeyboardClosed = "keyboardClosed",
  KeyboardSetType = "keyboardSetType",
  KeyboardSetLanguageOrLocale = "keyboardSetLanguageOrLocale",
  KeyboardSetColorScheme = "keyboardSetColorScheme"
}

export enum KeyboardType {
  Pin = "pin",
  Letters = "letters",
  Numpad = "numpad"
}

export const MAIN_APP_KEYBOARD_CHANNEL = "mainAppKeyboardChannel";

export const KEYBOARD_APP_KEYBOARD_CHANNEL = "keybordAppKeyboardChannel";

export const KEYBOARD_HANDLE_APP_KEYBOARD_CHANNEL = "keyboardHandleAppKeyboardChannel";

// Keys are according to `keysymdef.h`
// Keys with the `__`-prefix are our internal keys
export enum Key {
  BackSpace = "BackSpace",
  Shift = "__SHIFT",
  AllSymbols = "__ALL_SYMBOLS",
  Symbols = "__SYMBOLS",
  Left = "Left",
  Right = "Right",
  Action = "__ACTION",
  Return = "Return",
  Space = "space",
  Bar = "bar"
}

export enum KeyboardMode {
  LowerCase = "LOWERCASE",
  UpperCase = "UPPERCASE",
  CapsLock = "CAPSLOCK",
  Symbols = "SYMBOLS",
  AllSymbols = "ALL_SYMBOLS"
}

// First element is the Label on the keyboard
// The second element is the keycode
export type KeyboardSymbolMap = Array<[string, string]>;

export type KeyboardModeMap = {
  [key in KeyboardMode]: KeyboardSymbolMap;
};

export type KeyboardMap = {
  [key in Language]: KeyboardModeMap;
};
