import React from "react";
import { intersection, mapValues } from "lodash";
import { useLocation } from "react-router";

import { waitPromise } from "../../../../commons/libs/promise";
import { getSpecConfig } from "../../../../commons/specs";
import { CustomBicycleFilterKey } from "../../../../commons/specs/bicycle";
import {
  ActiveFilters,
  FilterConfigItem,
  FilterKey,
  FilterValue,
  ProductFilterValues
} from "../../../../commons/specs/filters";
import { ProductSpecKey, ProductType } from "../../../../commons/specs/product";
import { AssortmentType } from "../../../../commons/types/assortment";
import { GlobalLocationState } from "../../../../commons/types/location";
import {
  AssortmentPriceSettings,
  AutomaticAssortmentFilter,
  ProductFinderAssortmentFilterSettings
} from "../../../../commons/types/settings";
import * as sessionActions from "../../actions/session";
import config from "../../config";
import { getPossibleValues } from "../content-service";
import {
  clearActiveFilter,
  clearActiveFilters,
  getActiveFilterValues,
  getDefaultActiveFilters,
  hasActiveFilters,
  isFilterItemActive as isFilterItemActiveLib
} from "../filters";
import { selectInitializedSettings } from "../selectors";

import useDispatch from "./use-dispatch";
import useFetchData from "./use-fetch-data";
import useGetFilterKeysWithInsufficientFilterValues from "./use-get-filter-keys-with-insufficient-filter-values";
import useSelectedBrands from "./use-selected-brands";
import useSelector from "./use-selector";

type Params<Type extends ProductType> = {
  productType: Type;
  assortmentFilterSettings: ProductFinderAssortmentFilterSettings;
  assortmentPriceSettings: AssortmentPriceSettings;
  automaticAssortmentFilter?: AutomaticAssortmentFilter;
  brandKey?: string;
  useSessionState?: boolean;
  shouldSetDefaultActiveFilters?: boolean;
};

type GetEvaluatedDefaultActiveFilterOptions<Type extends ProductType> = {
  assortmentType: AssortmentType | null;
  assortmentFilterSettings: ProductFinderAssortmentFilterSettings;
  activeFilters: ActiveFilters<Type>;
  productFilterValues: ProductFilterValues<Type>;
  automaticAssortmentFilter?: AutomaticAssortmentFilter;
};

export const getEvaluatedDefaultActiveFilters = <Type extends ProductType>({
  automaticAssortmentFilter,
  assortmentType,
  assortmentFilterSettings,
  activeFilters,
  productFilterValues
}: GetEvaluatedDefaultActiveFilterOptions<Type>): ActiveFilters<Type> => {
  const { showAllModelYears, selectAssortmentAutomatically, enableSupplierAvailabilitiesFilterAutomatically } =
    assortmentFilterSettings;
  // Respect a manually disabled assortment filter by the user, when evaluating the default active filters
  // Note: An empty array is our indicator that a filter was active, and got cleared again
  const isAssortmentFilterManuallyDisabled =
    (assortmentType === AssortmentType.Manual &&
      getActiveFilterValues(activeFilters, ProductSpecKey.ManualAssortment)?.length === 0) ||
    (assortmentType === AssortmentType.Automatic &&
      getActiveFilterValues(activeFilters, ProductSpecKey.AutomaticAssortment)?.length === 0);

  const shouldModifyAssortment: boolean = !isAssortmentFilterManuallyDisabled && selectAssortmentAutomatically;

  const possibleValues: ActiveFilters<Type> = mapValues(productFilterValues, values =>
    (values ?? []).map(({ value }) => value)
  );
  const possibleActiveFilters = mapValues(
    activeFilters,
    (values: FilterValue<Type, FilterKey<Type>>[], key: FilterKey<Type>) =>
      key === CustomBicycleFilterKey.Sizing
        ? getActiveFilterValues(activeFilters, key)
        : intersection(values, possibleValues[key])
  );
  const defaultActiveFilters = getDefaultActiveFilters(assortmentType, {
    automaticAssortmentFilter,
    showAllModelYears,
    productFilterValues,
    selectAssortmentAutomatically: shouldModifyAssortment,
    enableSupplierAvailabilitiesFilterAutomatically
  });

  return {
    ...possibleActiveFilters,
    ...defaultActiveFilters
  } as ActiveFilters<Type>;
};

const useSessionOrLocalState = <Type extends ProductType>(useSessionState: boolean) => {
  const location = useLocation<GlobalLocationState<Type>>();
  // Disable syncing the active filters with the session state
  const localState = React.useState<ActiveFilters<Type>>(location.state?.activeFilters ?? null);

  const dispatch = useDispatch();
  const activeFilters = useSelector(state => state.session.activeFilters as ActiveFilters<Type>);
  const setActiveFilters = React.useCallback(
    (activeFilters: ActiveFilters<Type>) => dispatch(sessionActions.setActiveFilters(activeFilters)),
    [dispatch]
  );

  if (!useSessionState) {
    return localState;
  }

  return [activeFilters, setActiveFilters] as const;
};

const useProductFilter = <Type extends ProductType>({
  productType,
  assortmentFilterSettings,
  assortmentPriceSettings,
  automaticAssortmentFilter,
  brandKey = "",
  useSessionState = true,
  shouldSetDefaultActiveFilters = false
}: Params<Type>) => {
  const specConfig = getSpecConfig(productType);

  const [activeFilters, setActiveFilters] = useSessionOrLocalState<Type>(useSessionState);
  const currency = useSelector(state => selectInitializedSettings(state).currency);
  const assortmentType = useSelector(state => selectInitializedSettings(state).assortment.type);
  const activeBrands = useSelector(state => state.brands.active);

  const [wereDefaultActiveFiltersEvaluated, setWereDefaultActiveFiltersEvaluated] = React.useState<boolean>(false);

  const filterValues = useFetchData(
    async () => {
      const keys = Object.keys(specConfig.filterConfig) as FilterKey<typeof productType>[];
      const nextFilters = brandKey ? [{ key: ProductSpecKey.BrandKey, value: brandKey }] : [];

      const minLoadingTimeout = waitPromise(config.shared.minLoadingTimeout);

      const possibleValues = getPossibleValues(keys, currency, assortmentPriceSettings, nextFilters);

      // Keep the loading state for a minimum amount of time
      await Promise.all([minLoadingTimeout, possibleValues]);

      return possibleValues;
    },
    [currency, brandKey],
    {
      onDone: data => {
        if (!wereDefaultActiveFiltersEvaluated) {
          if (activeFilters !== null && !shouldSetDefaultActiveFilters) {
            setWereDefaultActiveFiltersEvaluated(true);
          } else {
            const defaultActiveFilters = getEvaluatedDefaultActiveFilters<Type>({
              assortmentType,
              assortmentFilterSettings,
              automaticAssortmentFilter,
              activeFilters,
              productFilterValues: data
            });

            setActiveFilters(defaultActiveFilters);

            setWereDefaultActiveFiltersEvaluated(true);
          }
        }
      }
    }
  );

  const { selectedBrands } = useSelectedBrands({ activeBrands, activeFilters, brandKey });

  const filterKeysWithInsufficientFilterValues = useGetFilterKeysWithInsufficientFilterValues({
    selectedBrands,
    activeFilters
  });

  const clearAllFilters = (activeFilters: ActiveFilters<Type>) => {
    setActiveFilters(clearActiveFilters(activeFilters));
  };

  const clearFilters = (filterKeys: FilterKey<Type>[]) => {
    setActiveFilters(clearActiveFilters(activeFilters, filterKeys));
  };

  const clearFilter = (filterKey: FilterKey<Type>) => {
    setActiveFilters(clearActiveFilter(activeFilters, filterKey));
  };

  const isFilterItemActive = (key: FilterKey<Type>, filterConfigItem: FilterConfigItem<Type, FilterKey<Type>>) =>
    isFilterItemActiveLib(key, filterConfigItem, activeFilters);

  return {
    activeFilters,
    filterValues: filterValues.data ?? {},
    selectedBrands,
    filterKeysWithInsufficientFilterValues,
    error: filterValues.error,
    isLoading: filterValues.isLoading,
    hasActiveFilters: hasActiveFilters(activeFilters),
    setActiveFilters,
    clearAllFilters,
    clearFilters,
    clearFilter,
    isFilterItemActive,
    wereDefaultActiveFiltersEvaluated
  };
};

export default useProductFilter;
