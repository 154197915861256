import React, { useEffect, useState } from "react";
import { noop } from "lodash";
import {
  TransformComponent,
  TransformWrapper,
  useControls,
  useTransformContext,
  useTransformEffect
} from "react-zoom-pan-pinch";

import { Component } from "../../../../commons/types/component";
import { IconSmallCross, IconSmallZoomIn, IconSmallZoomOut } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import useDoubleClick from "../../libs/hooks/use-double-click";
import CircularButton from "../CircularButton/CircularButton";
import Icon from "../Icon/Icon";

const MIN_SCALE = 0.8;
const INITIAL_SCALE = 1.2;
const MAX_SCALE = 2.5;

type Scales = {
  min: number;
  initial: number;
  max: number;
};

interface Props extends Component {
  imageUrl: string;
  openButton: HTMLButtonElement | null;
  onClose?: () => void;
  notice?: React.ReactNode;
  showNotice?: boolean;
  onHideNotice?: () => void;
}

interface ContentProps {
  imageUrl: string;
  openButton: HTMLButtonElement | null;
  onClose?: () => void;
  scales?: Scales;
}

const getImageWidth = (imageUrl: string): Promise<number> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img.naturalWidth);
    img.onerror = err => reject(err);
    img.src = imageUrl;
  });

const getScales = async (imageUrl: string, modalElement: HTMLDivElement | null) => {
  if (modalElement) {
    const imageWidth = await getImageWidth(imageUrl);
    const modalWidth = modalElement.clientWidth;

    return {
      min: (modalWidth / imageWidth) * MIN_SCALE,
      initial: (modalWidth / imageWidth) * INITIAL_SCALE,
      max: (modalWidth / imageWidth) * MAX_SCALE
    };
  }
};

const Content = ({ imageUrl, openButton, onClose = noop, scales }: ContentProps) => {
  const { contentComponent: image } = useTransformContext();

  const [scale, setScale] = useState<number>(0);

  const { left } = openButton?.getBoundingClientRect() ?? {};

  const { zoomIn, zoomOut, centerView, setTransform } = useControls();

  const isInitialized = scale && scales;
  const isZoomedIn = isInitialized ? scale > scales.initial : false;

  useTransformEffect(({ state: { scale } }) => {
    setScale(scale);
  });

  useEffect(() => {
    // Init the view after initial scale has been calculated
    if (scales?.initial) {
      centerView(scales.initial, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Do not trigger effect when centerView instance changes
  }, [scales?.initial]);

  const handleToggleZoom = () => {
    if (isInitialized) {
      if (isZoomedIn) {
        const step = Math.log(scale / scales.initial);
        zoomOut(step);
      } else {
        const step = Math.log(scales.max / scale);
        zoomIn(step);
      }
    }
  };

  const handleDoubleClick = ({ clientX, clientY }: React.MouseEvent) => {
    if (scales && image) {
      const { left: imageLeft, top: imageTop, width: imageWidth, height: imageHeight } = image.getBoundingClientRect();

      let nextScale;
      if (isZoomedIn) {
        nextScale = scales.initial;
      } else {
        nextScale = scales.max;
      }
      const nextImageWidth = (imageWidth * nextScale) / scale;
      const nextImageHeight = (imageHeight * nextScale) / scale;

      // With these coordinates set, the image would be centered at next scale
      const centerRatio = (nextScale - 1) / scale / 2;
      const centerX = imageWidth * -centerRatio;
      const centerY = imageHeight * -centerRatio;

      // Calculate the relative position of the click in the image and transform it onto the next size of the image
      const imageClickXPercentage = (clientX - imageLeft) / imageWidth;
      const imageClickYPercentage = (clientY - imageTop) / imageHeight;
      const nextCenterX = imageClickXPercentage * nextImageWidth;
      const nextCenterY = imageClickYPercentage * nextImageHeight;

      // Calculate the translation in relation to the center of the image
      const translateX = nextCenterX - nextImageWidth / 2;
      const translateY = nextCenterY - nextImageHeight / 2;

      setTransform(centerX - translateX, centerY - translateY, nextScale);
    }
  };

  const { onClick, onBlur } = useDoubleClick(handleDoubleClick);

  return (
    <>
      <TransformComponent wrapperClass="ZoomImageModal__image" contentProps={{ onClick, onBlur }}>
        <img src={imageUrl} alt="" />
      </TransformComponent>
      <div className="ZoomImageModal__controls" style={{ left }}>
        <CircularButton variant="accent" elevated onClick={handleToggleZoom}>
          <Icon source={isZoomedIn ? IconSmallZoomOut : IconSmallZoomIn} />
        </CircularButton>
        <CircularButton size="s" elevated onClick={() => onClose()}>
          <Icon source={IconSmallCross} />
        </CircularButton>
      </div>
    </>
  );
};

const ZoomImageModal = ({
  classNames = [],
  imageUrl,
  openButton,
  onClose = noop,
  notice,
  showNotice,
  onHideNotice = noop
}: Props) => {
  const modalRef = React.useRef<HTMLDivElement>(null);

  const [scales, setScales] = useState<Scales>();

  const calculateAndUpdateScales = async () => {
    const nextScales = await getScales(imageUrl, modalRef.current);
    nextScales && setScales(nextScales);
  };

  return (
    <div className={cn("ZoomImageModal", [{ showNotice }], classNames)} ref={modalRef}>
      <TransformWrapper
        initialScale={0} // will be overriden by the calculated initial scale in an effect
        maxScale={scales?.max}
        minScale={scales?.min}
        centerOnInit
        limitToBounds={false}
        centerZoomedOut={false}
        onInit={calculateAndUpdateScales}
        onZoomStart={showNotice ? onHideNotice : undefined}
        onPanningStart={showNotice ? onHideNotice : undefined}
        onPinchingStart={showNotice ? onHideNotice : undefined}
        onWheelStart={showNotice ? onHideNotice : undefined}
        doubleClick={{
          disabled: true
        }}
      >
        <Content openButton={openButton} imageUrl={imageUrl} scales={scales} onClose={onClose} />
        {showNotice && <div className="ZoomImageModal__notice">{notice}</div>}
      </TransformWrapper>
    </div>
  );
};

export default ZoomImageModal;
