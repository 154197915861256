// Currently all customer groups will be part of the build and deployed on every device.
// A solution to this would require loading the specific customer group from a cloud service during setup and with every update.
// For now this is ok.

import { ProductType } from "../specs/product";
import { ExternalToolKey } from "../types/tool";

export enum WallpaperId {
  Abstract0 = "abstract0",
  Abstract2 = "abstract2",
  Landscape0 = "landscape0",
  Landscape1 = "landscape1",
  Lifestyle0 = "lifestyle0",
  Lifestyle1 = "lifestyle1",
  Lifestyle2 = "lifestyle2",
  Lifestyle3 = "lifestyle3",
  Lifestyle4 = "lifestyle4",
  Lifestyle5 = "lifestyle5",
  Lifestyle6 = "lifestyle6",
  Lifestyle7 = "lifestyle7",
  Mountain0 = "mountain0",
  Mountain1 = "mountain1",
  Mountain2 = "mountain2",
  Mountain3 = "mountain3",
  Mountain4 = "mountain4",
  Mountain5 = "mountain5",
  Mountain6 = "mountain6",
  Road0 = "road0",
  Road1 = "road1",
  Road2 = "road2",
  Technical0 = "technical0"
}

export enum NavigationConfigId {
  Bicycle = "bicycle",
  Motorcycle = "motorcycle"
}

export type ColorSchemeId = "mono";

export enum CustomerGroupId {
  Bicycle = "bicycle",
  BicycleBico = "bicycle-bico",
  BicycleOrbea = "bicycle-orbea",
  Motorcycle = "motorcycle"
}

export interface CustomerGroupConfig {
  id: CustomerGroupId;
  productTypes: ProductType[];
  permanentPackageGroups: string[];
  navigation: NavigationConfigId;
  colorScheme: ColorSchemeId;
  defaultWallpaper: WallpaperId;
  wallpapers: WallpaperId[];
  externalTools: ExternalToolKey[];
  minVariantsToShowVariantsTab: number;
  undismissableHighlightedBrands: string[];
}

export const defaultCustomerGroupId: CustomerGroupId = CustomerGroupId.Bicycle;

const bicycleCustomerGroup: CustomerGroupConfig = {
  id: CustomerGroupId.Bicycle,
  productTypes: [ProductType.Bicycle],
  permanentPackageGroups: ["default"],
  navigation: NavigationConfigId.Bicycle,
  colorScheme: "mono",
  undismissableHighlightedBrands: [],
  defaultWallpaper: WallpaperId.Lifestyle3,
  wallpapers: [
    WallpaperId.Abstract0,
    WallpaperId.Abstract2,
    WallpaperId.Landscape0,
    WallpaperId.Landscape1,
    WallpaperId.Lifestyle0,
    WallpaperId.Lifestyle1,
    WallpaperId.Lifestyle2,
    WallpaperId.Lifestyle3,
    WallpaperId.Lifestyle4,
    WallpaperId.Lifestyle5,
    WallpaperId.Lifestyle6,
    WallpaperId.Lifestyle7,
    WallpaperId.Mountain0,
    WallpaperId.Mountain1,
    WallpaperId.Mountain2,
    WallpaperId.Mountain3,
    WallpaperId.Mountain4,
    WallpaperId.Mountain5,
    WallpaperId.Mountain6,
    WallpaperId.Road0,
    WallpaperId.Road1,
    WallpaperId.Road2,
    WallpaperId.Technical0
  ],
  externalTools: [
    ExternalToolKey.Adac,
    ExternalToolKey.Alteos,
    ExternalToolKey.Bikmo,
    ExternalToolKey.BcRemoteShop,
    ExternalToolKey.BodyScanningCrm,
    ExternalToolKey.BodyScanningAppointmentPrep,
    ExternalToolKey.Enra,
    ExternalToolKey.JobRad,
    ExternalToolKey.SqlabProfiler,
    ExternalToolKey.Wertgarantie,
    ExternalToolKey.LeaseABike,
    ExternalToolKey.BusinessBike,
    ExternalToolKey.EasyBikeLeasing,
    ExternalToolKey.BikeLeasing,
    ExternalToolKey.LeaseMyBike,
    ExternalToolKey.DeutscheDienstrad,
    ExternalToolKey.FirmenRadl,
    ExternalToolKey.EuroRad,
    ExternalToolKey.KazenMaier,
    ExternalToolKey.MeinDienstrad,
    ExternalToolKey.ZegPlusGarantie,
    ExternalToolKey.Smartfit
  ],
  minVariantsToShowVariantsTab: 0
};

const bicoCustomerGroup: CustomerGroupConfig = {
  ...bicycleCustomerGroup,
  id: CustomerGroupId.BicycleBico,
  permanentPackageGroups: [...bicycleCustomerGroup.permanentPackageGroups, "bico"],
  externalTools: [...bicycleCustomerGroup.externalTools, ExternalToolKey.BicoB2bShop, ExternalToolKey.BicoAssessment]
};

const orbeaCustomerGroup: CustomerGroupConfig = {
  ...bicycleCustomerGroup,
  id: CustomerGroupId.BicycleOrbea,
  undismissableHighlightedBrands: ["orbea"]
};

const motorcycleCustomerGroup: CustomerGroupConfig = {
  ...bicycleCustomerGroup, // Just for now. We probably define motorcycle without sharing the same config with bicycle in the future.
  id: CustomerGroupId.Motorcycle,
  productTypes: [ProductType.Motorcycle],
  permanentPackageGroups: ["default-mobility-center"],
  navigation: NavigationConfigId.Motorcycle,
  defaultWallpaper: WallpaperId.Abstract0,
  wallpapers: [WallpaperId.Abstract0, WallpaperId.Abstract2, WallpaperId.Landscape0, WallpaperId.Landscape1],
  externalTools: [
    ExternalToolKey.KtmConfigurator,
    ExternalToolKey.KtmBookATestride,
    ExternalToolKey.KtmParts,
    ExternalToolKey.KtmWear,
    ExternalToolKey.HusqvarnaConfigurator,
    ExternalToolKey.HusqvarnaBookATestride,
    ExternalToolKey.HusqvarnaParts,
    ExternalToolKey.HusqvarnaWear,
    ExternalToolKey.GasgasBookATestride,
    ExternalToolKey.GasGasConfigurator,
    ExternalToolKey.GasGasParts,
    ExternalToolKey.GasGasWear
  ],
  minVariantsToShowVariantsTab: 1
};

const customerGroups: CustomerGroupConfig[] = [
  bicycleCustomerGroup,
  bicoCustomerGroup,
  orbeaCustomerGroup,
  motorcycleCustomerGroup
];

export default customerGroups;
