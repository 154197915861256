import React from "react";
import { noop } from "lodash";
import ReactDOM from "react-dom";
import { Portal } from "react-portal";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import { getScrollParents } from "../../libs/externals/scroll-parent";
import useOnMount from "../../libs/hooks/use-on-mount";

const renderProps = (element: React.ReactNode | Function, props: any) =>
  typeof element === "function" ? element(props) : element;

export enum Position {
  AboveLeft = "above-left",
  AboveRight = "above-right",
  BelowLeft = "below-left",
  BelowRight = "below-right"
}

export interface PositionStrategy<T = Position> {
  top: number;
  left: number;
  position: T;
}

const getPosition = (openAbove: boolean, left: number): Position => {
  if (openAbove && left) {
    return Position.AboveLeft;
  } else if (openAbove && !left) {
    return Position.AboveRight;
  } else if (!openAbove && left) {
    return Position.BelowLeft;
  } else {
    return Position.BelowRight;
  }
};

const defaultPositionStrategy = (sourceRect: DOMRect, portalRect: DOMRect): PositionStrategy => {
  // Open the content portal above the child if there is not enough space to the bottom,
  // but if there also isn't enough space at the top, open to the bottom.
  const openAbove =
    sourceRect.top + sourceRect.height + portalRect.height >
      (window.document.documentElement || window.document.body).clientHeight && sourceRect.top - portalRect.height > 0;

  const top = openAbove
    ? sourceRect.top - portalRect.height + window.scrollY
    : sourceRect.top + sourceRect.height + window.scrollY;

  // Open the content portal to the left if there is not enough space at the right,
  // but if there also isn't enough space at the right, open to the left.
  const alignRight =
    sourceRect.left + portalRect.width > (window.document.documentElement || window.document.body).clientWidth &&
    sourceRect.left - portalRect.width > 0;

  const left = !alignRight
    ? sourceRect.left + window.scrollX
    : window.scrollX + sourceRect.left - portalRect.width + sourceRect.width;

  const position = getPosition(openAbove, left);

  return {
    top,
    left,
    position
  };
};

interface Props extends Component {
  children: React.ReactNode;
  portalContent:
    | React.ReactNode
    | ((params: {
        close: () => void;
        isOpen: boolean;
        position: string;
        relatedWidth: number;
        transitionStarted: () => void;
        transitionEnded: () => void;
      }) => React.ReactNode);
  onShouldClose?: () => void;
  closeOnOutsideClick?: boolean;
  isOpen?: boolean;
  positionStrategy?: (sourceRect: DOMRect, portalRect: DOMRect, props: Props) => PositionStrategy<string>;
  rootNode?: HTMLElement;
}

// we need to use an class component internally
// because we need a ref of the children
// and we can't achieve this with fragment refs
// see: https://github.com/reactjs/rfcs/pull/97
class PositioningPortalChildren extends React.Component<Pick<Props, "children">> {
  public render() {
    return this.props.children;
  }
}

const PositioningPortal = (props: Props) => {
  const {
    portalContent,
    children,
    rootNode,
    isOpen = false,
    onShouldClose = noop,
    closeOnOutsideClick = true,
    positionStrategy = defaultPositionStrategy
  } = props;

  const [top, setTop] = React.useState<number>();
  const [left, setLeft] = React.useState<number>();
  const [portalRect, setPortalRect] = React.useState<DOMRect>();
  const [sourceRect, setSourceRect] = React.useState<DOMRect>();
  const [isPositioned, setIsPositioned] = React.useState(false);
  const [transitionActive, setTransitionActive] = React.useState(false);
  const [scrollParents, setScrollParents] = React.useState<HTMLElement[]>([]);
  const [position, setPosition] = React.useState<string>();

  const [sourceDom, _setSourceDom] = React.useState<HTMLElement>();
  const [isOpenInternal, _setIsOpenInternal] = React.useState(false);

  const sourceDomRef = React.useRef<HTMLElement | null>(null);
  const portalDomRef = React.useRef<HTMLDivElement | null>(null);
  const isOpenInternalRef = React.useRef(false);

  const setSourceDom = (dom: HTMLElement) => {
    sourceDomRef.current = dom;
    _setSourceDom(dom);
  };

  const setPortalDom = (dom: HTMLDivElement) => {
    portalDomRef.current = dom;
  };

  const setIsOpenInternal = (isOpen: boolean) => {
    isOpenInternalRef.current = isOpen;
    _setIsOpenInternal(isOpen);
  };

  const childrenRefCallback = React.useCallback((instance: React.ReactInstance | null) => {
    if (!!instance) {
      // TODO: BCD-5644 Refactor PositioningPortal to use Hooks
      // we can assume there is only one child, and that it is of type React.ReactNode
      // which is why we can cast it as HTMLElement.
      // Futhermore, we have to use ReactDOM.findDOMNode, because fragment refs are not supported
      // in react. See https://github.com/reactjs/rfcs/pull/97
      setSourceDom(ReactDOM.findDOMNode(instance) as HTMLElement); // eslint-disable-line react/no-find-dom-node
    }
  }, []);

  const portalRefCallback = React.useCallback((node: HTMLDivElement) => {
    setPortalDom(node);

    if (!!node) {
      setPortalRect(node.getBoundingClientRect());
    } else {
      setPortalRect(undefined);
    }
  }, []);

  useOnMount(() => {
    window.document.addEventListener("click", handleOutsideMouseClick, false);

    if (isOpen) {
      handleOpen();
    }

    return () => {
      window.document.removeEventListener("click", handleOutsideMouseClick, false);

      // Remove scroll event listeners
      scrollParents.forEach(node => node.removeEventListener("scroll", close, false));
    };
  });

  const close = React.useCallback(() => {
    onShouldClose();
  }, [onShouldClose]);

  const open = React.useCallback(() => {
    if (sourceRect && portalRect) {
      const { top, left, position } = (positionStrategy || defaultPositionStrategy)(sourceRect, portalRect, props);

      setIsPositioned(true);
      setLeft(left);
      setTop(top);
      setPosition(position);
    }
  }, [portalRect, positionStrategy, props, sourceRect]);

  const handleClose = React.useCallback(() => {
    if (!isOpenInternal) {
      return;
    }

    // Remove scroll event listeners
    scrollParents.forEach(node => node.removeEventListener("scroll", close, false));

    setIsOpenInternal(false);
    setScrollParents([]);
  }, [close, isOpenInternal, scrollParents]);

  const handleOpen = React.useCallback(() => {
    if (!isOpenInternal) {
      if (sourceDom && sourceDom.nodeType === Node.ELEMENT_NODE) {
        const sourceRect = sourceDom.getBoundingClientRect();

        // Register scroll listener on all scrollable parents to close the portal on scroll
        // This is done to prevent the tooltip from still being visible, even after it has been
        // scrolled out of view within a scrollable container
        const scrollParents = getScrollParents(sourceDom);
        scrollParents.forEach(node => node.addEventListener("scroll", close, false));

        setIsOpenInternal(true);
        setTransitionActive(false);
        setIsPositioned(false);
        setLeft(0);
        setTop(0);
        setPosition(undefined);
        setSourceRect(sourceRect);
        setPortalRect(undefined);
        setScrollParents(scrollParents);

        // we will render the portal content, once we have the
        // portal reference, so we can calculate the position
        // once that is done, the open() function will be triggered
      }
    }
  }, [close, isOpenInternal, sourceDom]);

  const handleOutsideMouseClick = React.useCallback(
    (event: Event) => {
      if (!closeOnOutsideClick) {
        return;
      } else if (!isOpenInternalRef.current) {
        return;
      } else if (portalDomRef.current && portalDomRef.current.contains(event.target as Node)) {
        return;
      } else if (sourceDomRef.current && sourceDomRef.current.contains(event.target as Node)) {
        return;
      } else {
        close();
      }
    },
    [close, closeOnOutsideClick]
  );

  if (isOpen) {
    handleOpen();
  } else {
    handleClose();
  }

  if (isOpenInternal && !isPositioned && portalRect && sourceRect) {
    open();
  }

  const relatedWidth = sourceRect ? sourceRect.width : 0;

  const portalStyle: React.CSSProperties = {
    width: portalRect ? `${portalRect.width}px` : "auto",
    left: `${left}px`,
    top: `${top}px`
  };

  const renderPortal = () => (
    <Portal node={rootNode}>
      <div className={cn("PositioningPortal", [{ isPositioned }])} ref={portalRefCallback} style={portalStyle}>
        {renderProps(portalContent, {
          close,
          transitionStarted: () => setTransitionActive(true),
          transitionEnded: () => setTransitionActive(false),
          position,
          isOpen: isOpenInternal,
          relatedWidth
        })}
      </div>
    </Portal>
  );

  return (
    <>
      <PositioningPortalChildren ref={childrenRefCallback}>{children}</PositioningPortalChildren>
      {(isOpenInternal || transitionActive) && renderPortal()}
    </>
  );
};

export default PositioningPortal;
