import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import useOnMount from "../../libs/hooks/use-on-mount";
import Balloon, { Variant as BalloonVariant } from "../Balloon/Balloon";
import PositioningPortal, { PositionStrategy } from "../PositioningPortal/PositioningPortal";

const CLOSE_TIMEOUT = 3000;

export enum TooltipPosition {
  Bottom = "bottom",
  Top = "top",
  Left = "left",
  Right = "right"
}

export interface HandleProps {
  open: () => void;
  close: () => void;
  toggle: () => void;
}

type NodeOrFunction = React.ReactNode | ((params: HandleProps) => React.ReactNode);

const renderProps = (element: NodeOrFunction, props: HandleProps) =>
  typeof element === "function" ? element(props) : element;

const positionStrategy =
  (preferredPosition: TooltipPosition) =>
  (sourceRect: DOMRect, portalRect: DOMRect): PositionStrategy<TooltipPosition> => {
    const horizontalCenter = (sourceRect.width - portalRect.width) / 2;
    const verticalCenter = (sourceRect.height - portalRect.height) / 2;

    const positions = {
      [TooltipPosition.Bottom]: {
        position: TooltipPosition.Bottom,
        top: sourceRect.top + sourceRect.height + window.scrollY,
        left: sourceRect.left + window.scrollX + horizontalCenter,
        enoughSpace: sourceRect.top + sourceRect.height + portalRect.height < window.innerHeight
      },
      [TooltipPosition.Top]: {
        position: TooltipPosition.Top,
        top: sourceRect.top - portalRect.height + window.scrollY,
        left: sourceRect.left + window.scrollX + horizontalCenter,
        enoughSpace: sourceRect.top - portalRect.height > 0
      },
      [TooltipPosition.Left]: {
        position: TooltipPosition.Left,
        top: sourceRect.top + window.scrollY + verticalCenter,
        left: sourceRect.left + window.scrollX - portalRect.width,
        enoughSpace: sourceRect.left - portalRect.width > 0
      },
      [TooltipPosition.Right]: {
        position: TooltipPosition.Right,
        top: sourceRect.top + window.scrollY + verticalCenter,
        left: sourceRect.left + window.scrollX + sourceRect.width,
        enoughSpace: sourceRect.left + sourceRect.width + portalRect.width < window.innerWidth
      }
    };

    // Horizontal fallback preferred
    let sortedPositions = [
      positions[preferredPosition],
      positions[TooltipPosition.Bottom],
      positions[TooltipPosition.Top],
      positions[TooltipPosition.Right],
      positions[TooltipPosition.Left]
    ];

    // Vertical fallback preferred
    if (preferredPosition === TooltipPosition.Left || preferredPosition === TooltipPosition.Right) {
      sortedPositions = [
        positions[preferredPosition],
        positions[TooltipPosition.Right],
        positions[TooltipPosition.Left],
        positions[TooltipPosition.Bottom],
        positions[TooltipPosition.Top]
      ];
    }

    const pickedPosition = sortedPositions.find(({ enoughSpace }) => enoughSpace) || positions[preferredPosition];

    return {
      top: pickedPosition.top,
      left: pickedPosition.left,
      position: pickedPosition.position
    };
  };

const getMappedArrowPosition = (position: string) => {
  switch (position) {
    case TooltipPosition.Top:
      return "bottom";
    case TooltipPosition.Right:
      return "left";
    case TooltipPosition.Left:
      return "right";
    case TooltipPosition.Bottom:
    default:
      return "top";
  }
};

interface Props extends Component, Pick<React.ComponentProps<typeof Balloon>, "size" | "variant" | "maxWidth"> {
  children: NodeOrFunction;
  content: NodeOrFunction;
  preferredPosition?: TooltipPosition;
  openOnMountDelay?: number;
  openOnMount?: boolean;
  closeOnOutsideClick?: boolean;
  shouldCloseAutomatically?: boolean;
  isCloseable?: boolean;
  onClose?: () => void;
  rootNode?: HTMLElement;
  variant?: BalloonVariant;
  extraHorizontalPadding?: boolean;
}

const Tooltip: React.ForwardRefRenderFunction<HandleProps, Props> = (
  {
    classNames = [],
    children,
    content,
    preferredPosition = TooltipPosition.Top,
    openOnMountDelay = 0,
    openOnMount = false,
    closeOnOutsideClick = true,
    shouldCloseAutomatically = openOnMount,
    isCloseable = false,
    onClose = noop,
    rootNode,
    size = "default",
    variant = "primary",
    maxWidth = "default",
    extraHorizontalPadding = false
  }: Props,
  ref
) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const open: HandleProps["open"] = () => setIsOpen(true);

  const close: HandleProps["close"] = React.useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [onClose]);

  const toggle: HandleProps["toggle"] = React.useCallback(() => {
    setIsOpen(!isOpen);
    if (isOpen) {
      onClose();
    }
  }, [isOpen, onClose]);

  const closeTimeoutTime: number = openOnMountDelay ? CLOSE_TIMEOUT + openOnMountDelay : CLOSE_TIMEOUT;

  useOnMount(() => {
    const openDelayTimeout: NodeJS.Timeout | undefined = openOnMount ? setTimeout(open, openOnMountDelay) : undefined;

    return () => {
      openDelayTimeout && clearTimeout(openDelayTimeout);
    };
  });

  React.useEffect(() => {
    const autoCloseTimeout: NodeJS.Timeout | undefined =
      isOpen && shouldCloseAutomatically ? setTimeout(close, closeTimeoutTime) : undefined;

    return () => {
      autoCloseTimeout && clearTimeout(autoCloseTimeout);
    };
  }, [close, closeTimeoutTime, isOpen, shouldCloseAutomatically]);

  // For usage with from LabeledStatus
  React.useImperativeHandle(ref, () => ({
    open: () => {
      open();
    },
    close: () => {
      close();
    },
    toggle: () => {
      toggle();
    }
  }));

  const getInitialAnimationBasedOnPosition = (position: string): { x: number; y: number; opacity: number } => {
    const translateDistance: number = 40;

    switch (position) {
      case TooltipPosition.Top:
        return { x: 0, y: translateDistance, opacity: 0 };
      case TooltipPosition.Right:
        return { x: -translateDistance, y: 0, opacity: 0 };
      case TooltipPosition.Left:
        return { x: translateDistance, y: 0, opacity: 0 };
      case TooltipPosition.Bottom:
      default:
        return { x: 0, y: -translateDistance, opacity: 0 };
    }
  };
  return (
    <PositioningPortal
      classNames={classNames}
      positionStrategy={positionStrategy(preferredPosition)}
      isOpen={isOpen}
      onShouldClose={close}
      closeOnOutsideClick={closeOnOutsideClick}
      rootNode={rootNode}
      portalContent={({ position, transitionStarted, transitionEnded }) => (
        <AnimatePresence onExitComplete={transitionEnded}>
          {isOpen && (
            <motion.div
              // Style needs to be used because the initial will is closed after the first render. Only a change in animate will cause a reload of the motion.div logic, but initial will be not recalculated, like the style
              style={getInitialAnimationBasedOnPosition(position)}
              animate={!!position ? { x: 0, y: 0, opacity: 1 } : {}}
              exit={{ opacity: 0 }}
              transition={{ style: "tween" }}
              onAnimationStart={transitionStarted}
              className={cn("Tooltip")}
            >
              <Balloon
                size={size}
                maxWidth={maxWidth}
                variant={variant}
                onClose={isCloseable ? close : undefined}
                arrowPosition={getMappedArrowPosition(position)}
                extraHorizontalPadding={extraHorizontalPadding}
              >
                {renderProps(content, { open, close, toggle })}
              </Balloon>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    >
      {renderProps(children, { open, close, toggle })}
    </PositioningPortal>
  );
};

Tooltip.displayName = "Tooltip";

export default React.forwardRef(Tooltip);
