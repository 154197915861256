import { flatten, isNil, omit } from "lodash";

import { UpstreamItemStatus } from "../../../commons/libs/externals/veloconnect-proxy";
import { byDefined } from "../../../commons/libs/filter-guards";
import { BicycleSpecKey, CustomBicycleFilterKey } from "../../../commons/specs/bicycle";
import {
  ActiveFilters,
  FilterConfig,
  FilterConfigItem,
  FilterKey,
  FilterValue,
  ProductFilter,
  ProductFilterValues
} from "../../../commons/specs/filters";
import { ProductSpecKey, ProductType } from "../../../commons/specs/product";
import { AssortmentType } from "../../../commons/types/assortment";
import {
  AutomaticAssortmentAvailabilityState,
  ManualAssortmentAvailabilityState
} from "../../../commons/types/availability";
import { Brand, InsufficientFilterValues } from "../../../commons/types/brand";
import { AutomaticAssortmentFilter } from "../../../commons/types/settings";

import { getFilteredModelYearsForBrand } from "./brand";

export interface FilterItemDisabledConfig {
  brand: string;
  years: number[];
}

export enum FilterItemDisabledType {
  InsufficientFilterOptions,
  InsufficientDataQuality,
  Offline
}

type GetDefaultActiveFiltersOptions<Type extends ProductType> = {
  productFilterValues?: ProductFilterValues<Type>;
  showAllModelYears?: boolean;
  selectAssortmentAutomatically?: boolean;
  automaticAssortmentFilter?: AutomaticAssortmentFilter;
  enableSupplierAvailabilitiesFilterAutomatically?: boolean;
};

const getDefaultFilterValuesForAutomaticAssortmentFilter = <Type extends ProductType>(
  automaticAssortmentFilter?: AutomaticAssortmentFilter,
  possibleValues?: ProductFilterValues<Type>
): AutomaticAssortmentAvailabilityState[] => {
  if (!automaticAssortmentFilter) {
    return [];
  }

  const possibleFilterValues = (possibleValues?.[ProductSpecKey.AutomaticAssortment] ?? []).map(
    filterValue => filterValue.value
  );

  return {
    [AutomaticAssortmentFilter.OnlyIfAvailable]: [
      AutomaticAssortmentAvailabilityState.InStock,
      AutomaticAssortmentAvailabilityState.InStockElsewhere,
      AutomaticAssortmentAvailabilityState.AvailableAtSupplier
    ],
    [AutomaticAssortmentFilter.NotUnknown]: Object.values(AutomaticAssortmentAvailabilityState).filter(
      status => status !== AutomaticAssortmentAvailabilityState.Unknown
    ),
    [AutomaticAssortmentFilter.AvailableOrOrdered]: [
      AutomaticAssortmentAvailabilityState.InStock,
      AutomaticAssortmentAvailabilityState.InStockElsewhere,
      AutomaticAssortmentAvailabilityState.AvailableAtSupplier,
      AutomaticAssortmentAvailabilityState.Ordered
    ]
  }[automaticAssortmentFilter].filter(state => possibleFilterValues.includes(state));
};

export const getDefaultActiveFilters = <Type extends ProductType>(
  assortmentType: AssortmentType | null,
  {
    productFilterValues = {},
    automaticAssortmentFilter = undefined,
    showAllModelYears = false,
    selectAssortmentAutomatically = true,
    enableSupplierAvailabilitiesFilterAutomatically = false
  }: GetDefaultActiveFiltersOptions<Type> = {}
): ActiveFilters<Type> => {
  const activeFilters = {};

  const shouldModifyModelYears = shouldShowAllModelYears(showAllModelYears, selectAssortmentAutomatically);

  const activeFiltersWithModelYears: ActiveFilters<Type> = shouldModifyModelYears
    ? setActiveFilter(
        activeFilters,
        ProductSpecKey.ModelYear,
        (productFilterValues[ProductSpecKey.ModelYear] ?? []).map(({ value }) => value)
      )
    : activeFilters;

  if (selectAssortmentAutomatically) {
    if (assortmentType === AssortmentType.Manual) {
      const defaultFilterValue = ManualAssortmentAvailabilityState.Current as FilterValue<
        Type,
        ProductSpecKey.ManualAssortment
      >;

      return setActiveFilter(activeFiltersWithModelYears, ProductSpecKey.ManualAssortment, [defaultFilterValue]);
    } else if (assortmentType === AssortmentType.Automatic) {
      const defaultFilterValues = getDefaultFilterValuesForAutomaticAssortmentFilter(
        automaticAssortmentFilter,
        productFilterValues
      ) as FilterValue<Type, ProductSpecKey.AutomaticAssortment>;

      const activeFiltersWithAutomaticAssortment = setActiveFilter(
        activeFiltersWithModelYears,
        ProductSpecKey.AutomaticAssortment,
        [defaultFilterValues]
      );

      if (enableSupplierAvailabilitiesFilterAutomatically) {
        const defaultFilterValues = (productFilterValues[ProductSpecKey.VeloconnectAssortment] ?? [])
          .map(({ value }) => value)
          .filter(value => value === UpstreamItemStatus.AVAILABLE) as FilterValue<
          Type,
          ProductSpecKey.VeloconnectAssortment
        >[];

        return setActiveFilter(
          activeFiltersWithAutomaticAssortment,
          ProductSpecKey.VeloconnectAssortment,
          defaultFilterValues
        );
      } else {
        return activeFiltersWithAutomaticAssortment;
      }
    }
  }

  return activeFiltersWithModelYears;
};

export const addActiveFilterValue = <Type extends ProductType, K extends FilterKey<Type>>(
  activeFilters: ActiveFilters<Type>,
  filterKey: K,
  filterValue: FilterValue<Type, K>
) =>
  ({
    ...(activeFilters ?? {}),
    [filterKey]: [...(getActiveFilterValues(activeFilters, filterKey) || []), filterValue]
  }) as ActiveFilters<Type>;

export const removeActiveFilterValue = <Type extends ProductType, K extends FilterKey<Type>>(
  activeFilters: ActiveFilters<Type>,
  filterKey: K,
  filterValue: FilterValue<Type, K>
) =>
  ({
    ...(activeFilters ?? {}),
    [filterKey]: (getActiveFilterValues(activeFilters, filterKey) || []).filter(
      currentValue => currentValue !== filterValue
    )
  }) as ActiveFilters<Type>;

export const setActiveFilter = <Type extends ProductType, K extends FilterKey<Type>>(
  activeFilters: ActiveFilters<Type>,
  filterKey: K,
  filterValues: FilterValue<Type, K>[]
) =>
  ({
    ...(activeFilters ?? {}),
    [filterKey]: filterValues
  }) as ActiveFilters<Type>;

export const setActiveFilters = <Type extends ProductType, K extends FilterKey<Type>>(
  activeFilters: ActiveFilters<Type>,
  newActiveFilters: {
    [key in K]: FilterValue<Type, K>[];
  }
) => ({ ...activeFilters, ...newActiveFilters }) as ActiveFilters<Type>;

export const clearActiveFilter = <Type extends ProductType, K extends FilterKey<Type>>(
  activeFilters: ActiveFilters<Type>,
  filterKey: K
): ActiveFilters<Type> => clearActiveFilters(activeFilters, [filterKey]);

export const clearActiveFilters = <Type extends ProductType>(
  activeFilters: ActiveFilters<Type>,
  filterKeys?: FilterKey<Type>[]
): ActiveFilters<Type> => {
  const keysToClear = filterKeys ? filterKeys : Object.keys(activeFilters ?? {});
  return omit(activeFilters, keysToClear);
};

export const isActiveFilter = <Type extends ProductType, K extends FilterKey<Type>>(
  activeFilters: ActiveFilters<Type>,
  filterKey: K,
  filterValue?: FilterValue<Type, K>
): boolean => {
  const hasMissingValueArg = isNil(filterValue);
  const activeFilterValue = getActiveFilterValues(activeFilters, filterKey);

  if (hasMissingValueArg) {
    return Array.isArray(activeFilterValue) ? activeFilterValue.length > 0 : !!activeFilterValue;
  }

  return (activeFilterValue ?? []).includes(filterValue);
};

export const hasActiveFilters = <Type extends ProductType>(activeFilters: ActiveFilters<Type>): boolean =>
  Object.keys(activeFilters ?? {}).filter(key => isActiveFilter(activeFilters, key as FilterKey<Type>)).length > 0;

export const getActiveFilterValues = <Type extends ProductType, K extends FilterKey<Type>>(
  activeFilters: ActiveFilters<Type>,
  filterKey: K
) => {
  if (activeFilters && filterKey in activeFilters) {
    return flatten(activeFilters[filterKey]);
  }

  return undefined;
};

export const shouldShowAllModelYears = (showAllModelYears: boolean, selectAssortmentAutomatically: boolean) =>
  showAllModelYears && selectAssortmentAutomatically;

export const hasMatchingInsufficientFilter = <Type extends ProductType>(
  filterKey: FilterKey<Type>,
  insufficientKeys: FilterKey<Type>[] = []
): boolean => insufficientKeys.includes(filterKey);

const hasInsufficientFilterOptions = <Type extends ProductType, K extends FilterKey<Type>>(
  filterKey: K,
  filterConfigItem: FilterConfigItem<Type, K>,
  filterValues: ProductFilterValues<Type>
): boolean =>
  (filterConfigItem.type === "default" ||
    filterConfigItem.type === "range" ||
    filterKey === ProductSpecKey.Price ||
    filterKey === BicycleSpecKey.ForkSuspensionTravel) &&
  (filterValues?.[filterKey] ?? []).length < 1;

const isOfflineUnavailableFilter = <Type extends ProductType>(
  filterKey: FilterKey<Type>,
  isOffline: boolean
): boolean => filterKey === CustomBicycleFilterKey.Sizing && isOffline;

export const getFilterItemDisabledYearsByActiveFilters =
  <Type extends ProductType>(filterKey: FilterKey<Type>, insufficientFilterValues: InsufficientFilterValues<Type>) =>
  (acc: number[], year: number): number[] =>
    hasMatchingInsufficientFilter(filterKey, insufficientFilterValues[year]) ? [...acc, year] : acc;

export const getFilterItemDisabledType = <Type extends ProductType, K extends FilterKey<Type>>(
  filterKey: K,
  filterConfigItem: FilterConfigItem<Type, K>,
  filterValues: ProductFilterValues<Type>,
  filterKeysWithInsufficientFilterValues: FilterKey<Type>[] = [],
  isOffline: boolean
): FilterItemDisabledType | null => {
  const hasMatchingInsufficientFilterValues = hasMatchingInsufficientFilter(
    filterKey,
    filterKeysWithInsufficientFilterValues
  );

  if (hasMatchingInsufficientFilterValues) {
    return FilterItemDisabledType.InsufficientDataQuality;
  } else if (hasInsufficientFilterOptions(filterKey, filterConfigItem, filterValues)) {
    return FilterItemDisabledType.InsufficientFilterOptions;
  } else if (isOfflineUnavailableFilter<Type>(filterKey, isOffline)) {
    return FilterItemDisabledType.Offline;
  } else {
    return null;
  }
};

export const getFilterItemDisabledConfig = <Type extends ProductType>(
  brand: Brand,
  filterKey: FilterKey<Type>,
  activeFilters: ActiveFilters<Type>
): FilterItemDisabledConfig => {
  const insufficientFilterValues = brand.insufficientFilterValues ?? {};

  const filteredModelYearsForBrand = getFilteredModelYearsForBrand<Type>(brand, activeFilters ?? {});

  return {
    brand: brand.displayName,
    years: filteredModelYearsForBrand.reduce(
      getFilterItemDisabledYearsByActiveFilters(filterKey, insufficientFilterValues),
      []
    )
  };
};

/**
 * Either returns a reason why it was disabled, or null.
 * This indicates if the item should be disabled or not.
 */
export const getFilterItemDisabledSettings = <Type extends ProductType, K extends FilterKey<Type>>(
  filterKey: K,
  filterConfigItem: FilterConfigItem<Type, K>,
  filterValues: ProductFilterValues<Type>,
  brands: Brand[],
  activeFilters: ActiveFilters<Type>,
  filterKeysWithInsufficientFilterValues: FilterKey<Type>[] = [],
  isOffline: boolean
): { filterItemDisabledType: FilterItemDisabledType | null; disabledConfigs: FilterItemDisabledConfig[] } => {
  const filterItemDisabledType = getFilterItemDisabledType(
    filterKey,
    filterConfigItem,
    filterValues,
    filterKeysWithInsufficientFilterValues,
    isOffline
  );

  if (filterItemDisabledType === null) {
    return { filterItemDisabledType: null, disabledConfigs: [] };
  } else {
    const disabledConfigs = brands.map(brand => getFilterItemDisabledConfig(brand, filterKey, activeFilters));

    return { filterItemDisabledType, disabledConfigs };
  }
};

export const isFilterItemActive = <Type extends ProductType, K extends FilterKey<Type> = FilterKey<Type>>(
  filterKey: K,
  filterConfigItem: FilterConfigItem<Type, FilterKey<Type>>,
  activeFilters: ActiveFilters<Type>
): number => {
  const activeCount = getActiveFilterValues(activeFilters, filterKey)?.length ?? 0;
  const isActive = activeCount > 0;

  const isActiveSizingType = filterKey === CustomBicycleFilterKey.Sizing && isActive;
  const isActiveRangeType = filterConfigItem.type === "range" && isActive;
  const isActiveForkSuspensionTravelType = filterKey === BicycleSpecKey.ForkSuspensionTravel && isActive;

  const shouldActiveCountBeOne = isActiveRangeType || isActiveForkSuspensionTravelType || isActiveSizingType;

  return shouldActiveCountBeOne ? 1 : activeCount;
};

export const getProductFiltersFromActiveFilters = <Type extends ProductType>(
  activeFilters: ActiveFilters<Type>,
  filterConfig: FilterConfig<Type>
): ProductFilter[] => {
  const activeFilterEntries = Object.entries(activeFilters ?? {});

  return activeFilterEntries
    .flatMap(([key, values]) => {
      const filterConfigItem = filterConfig[key as keyof typeof filterConfig];
      return filterConfigItem?.getActiveFilters(values);
    })
    .filter(byDefined);
};
