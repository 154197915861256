import { WallpaperId } from "../config/customer-groups";

import type { AssortmentType } from "./assortment";
import type { Bookmarks } from "./bookmarks";
import type { Currency } from "./currency";
import type { BadLicenseReason } from "./license";
import type { TranslationKey } from "./localization";
import { SmartfitAuth } from "./sizing";

export enum InitialSetupStep {
  Welcome = "welcome",
  Locale = "locale",
  Network = "network",
  InitialUpdate = "initialUpdate",
  PersonalCode = "personalCode",
  StartupTimes = "startupTimes",
  BrandActivation = "brandActivation",
  SecondUpdate = "secondUpdate",
  Done = "done",
  Finished = "finished"
}

export enum ProductFinderViewMode {
  GroupedByBrandAndCategory = "grouped_by_brand_and_category",
  GroupedByCategoryOnly = "grouped_by_category_only"
}

export enum ProductFinderOverviewInitialTab {
  Brands = "brands",
  Categories = "categories",
  Details = "details",
  Search = "search"
}

export interface InitialSetupSettings {
  firstFinished?: string;
  finishedSteps: InitialSetupStep[];
}

export interface CustomerWorldSettings {
  delay: number;
}

export interface ToolSystemSettings {
  isPrintingActive: boolean;
  isMailActive: boolean;
}

export interface ToolSetting {
  meta: {
    toolVisible?: boolean;
    pinRequired?: boolean;
    touchedSettings?: boolean;
    viewedSettings?: boolean;
    entryFile?: string | Partial<Record<TranslationKey, string>>;
  };
  // Reserved for injecting ToolSystemSettings
  system?: never;
}

export interface BikepriceCalculatorToolSetting extends ToolSetting {
  margin: number;
  regionalAdjustment: number;
  defaultBatteryCapacity: number;
  showPurchasingPrice: boolean;
  showMarketValue: boolean;
}

export interface FinancialCalculatorCreditPeriod {
  months: number;
  value: number;
  active: boolean;
}

export interface BikeFinancialCalculatorToolSetting extends ToolSetting {
  showInterestRate?: boolean;
  showDeposit?: boolean;
  showTotalInterestRateCosts?: boolean;
  creditPeriods?: FinancialCalculatorCreditPeriod[];
}

export interface FrameRange {
  min: number;
  max: number;
}

export interface FrameSize {
  range?: FrameRange;
  mtb: FrameRange;
  trekking: FrameRange;
  city: FrameRange;
  race: FrameRange;
}

export interface InseamFactor {
  mtb: number;
  trekking: number;
  city: number;
  race: number;
}

export interface BikeFrameSizeCalculatorSetting extends ToolSetting {
  showInseamSlider: boolean;
  sizeTolerance: number;
  showAdditionalCategories: boolean;
  additionalSizeToleranceForMtbSport: number;
  showSizesInInches: boolean;
  inseamFactor: InseamFactor;
  frameSizes: FrameSize[];
}

export interface EnraToolSetting extends ToolSetting {
  dealerId?: string;
}

export interface AdacToolSetting extends ToolSetting {
  distributorNumber: string;
}

export interface AlteosToolSetting extends ToolSetting {
  shopUrl: string;
}

export interface ZegPlusGarantieToolSetting extends ToolSetting {
  marketCode: string;
}

export interface BikmoToolSetting extends ToolSetting {
  ref?: string;
}

export interface BicoShopToolSetting extends ToolSetting {
  dealerId?: string;
  isDealerIdSet?: boolean;
}

export interface BicoAssessmentToolSetting extends ToolSetting {
  assessmentUrl: string;
}

export interface BodyScanningCrmToolSetting extends ToolSetting {
  crmLoginToken?: string;
}

export interface BodyScanningAppointmentPrepToolSetting extends ToolSetting {
  appointmentPrepLoginToken?: string;
}

export interface SqlabProfilerToolSetting extends ToolSetting {
  username?: string;
  password?: string;
}

export interface BrandWorldToolSetting {
  veloconnect?: { key: string; username: string }[];
}

export interface KtmBookATestRideToolSetting extends ToolSetting {
  dealerTestRideUuid?: string;
}

export interface HusqvarnaBookATestRideToolSetting extends ToolSetting {
  dealerTestRideUuid?: string;
}

export interface GasGasBookATestRideToolSetting extends ToolSetting {
  dealerTestRideUuid?: string;
}

export interface SmartfitToolSetting extends ToolSetting {
  smartfitApiAuth?: SmartfitAuth;
}

export interface ToolSettings {
  bikepriceCalculator: BikepriceCalculatorToolSetting;
  bikeFinancialCalculator: BikeFinancialCalculatorToolSetting;
  enra: EnraToolSetting;
  adac: AdacToolSetting;
  alteos: AlteosToolSetting;
  bikmo: BikmoToolSetting;
  bodyScanningCrm: BodyScanningCrmToolSetting;
  bodyScanningAppointmentPrep: BodyScanningAppointmentPrepToolSetting;
  jobRad: ToolSetting;
  bcRemoteShop: ToolSetting;
  bicoShop: BicoShopToolSetting;
  bikeFrameSizeCalculator: BikeFrameSizeCalculatorSetting;
  bicoAssessment: BicoAssessmentToolSetting;
  sqlabProfiler: SqlabProfilerToolSetting;
  wertgarantie: ToolSetting;
  leaseABike: ToolSetting;
  businessbike: ToolSetting;
  easyBikeLeasing: ToolSetting;
  firmenRadl: ToolSetting;
  bikeleasing: ToolSetting;
  leaseMyBike: ToolSetting;
  deutscheDienstrad: ToolSetting;
  euroRad: ToolSetting;
  kazenMaier: ToolSetting;
  meinDienstrad: ToolSetting;
  zegPlusGarantie: ZegPlusGarantieToolSetting;
  ktmConfigurator: ToolSetting;
  ktmBookATestride: KtmBookATestRideToolSetting;
  ktmParts: ToolSetting;
  ktmWear: ToolSetting;
  husqvarnaConfigurator: ToolSetting;
  husqvarnaBookATestride: HusqvarnaBookATestRideToolSetting;
  husqvarnaParts: ToolSetting;
  husqvarnaWear: ToolSetting;
  gasgasConfigurator: ToolSetting;
  gasgasBookATestride: GasGasBookATestRideToolSetting;
  gasgasParts: ToolSetting;
  gasgasWear: ToolSetting;
  smartfit: SmartfitToolSetting;
}

export interface MailSettings {
  active: boolean;
  fromName?: string;
  fromMail?: string;
  message?: string;
  ccActive: boolean;
  ccTo?: string;
}

export interface MailToPrintSettings {
  email?: string;
  subject?: string;
}

export interface PrintingSettings {
  active: boolean;
  pinRequired: boolean;
  mailToPrint: MailToPrintSettings;
}

export interface UpdaterSettings {
  lastSuccessfulUpdate?: number;
}

export enum AvailabilityPrioritization {
  InStockElsewhere = "inStockElsewhere",
  Supplier = "supplier"
}

export interface AvailabilityPrioritizationOptions {
  availabilityPrioritization: AvailabilityPrioritization;
  downgradeSoonAvailableAtSupplierThresholdInWeeks?: number;
}

export interface CustomizationSettings {
  bikeCenterName?: string;
  wallpaper?: WallpaperId;
  showPrices: boolean;
  showPricesFeaturesOnly: boolean;
  skipBrandWorldAnimationEnabled: boolean;
  productFinderViewMode: ProductFinderViewMode;
  productFinderOverviewInitialTab: ProductFinderOverviewInitialTab;
  availabilityPrioritizationOptions: AvailabilityPrioritizationOptions;
  dealerWebsite?: string;
  noPinRequiredForDealerWebsite?: boolean;
}

export interface LicenseSettings {
  lastLicenseCheck: number;
  licenseErrorReason?: BadLicenseReason;
}

export interface VeloconnectEndpointSetting {
  modalDismissed?: boolean;
}

export interface VeloconnectSettings {
  [key: string]: VeloconnectEndpointSetting;
}

export enum AutomaticAssortmentFilter {
  OnlyIfAvailable = "onlyIfAvailable",
  NotUnknown = "notUnknown",
  AvailableOrOrdered = "availableOrOrdered"
}

export interface ProductFinderAssortmentFilterSettings {
  showAllModelYears: boolean;
  selectAssortmentAutomatically: boolean;
  hideAutomaticAssortmentFilter: boolean;
  enableSupplierAvailabilitiesFilterAutomatically: boolean;
}

export interface AssortmentPriceSettings {
  showAssortmentPrices: boolean;
  showFallbackPrices: boolean;
  useRrpAsOriginalPrice: boolean;
  showOriginalPrices: boolean;
  showSaleBadge: null | "word" | "percentage";
}

export interface AssortmentSettings {
  type: AssortmentType | null;
  productFinderFilterSettings: ProductFinderAssortmentFilterSettings;
  priceSettings: AssortmentPriceSettings;

  [AssortmentType.Automatic]?: {
    filter: AutomaticAssortmentFilter;
  };
}

export interface FeatureFlags {
  mailEnabled: boolean;
  customAssortmentEnabled: boolean;
  videoConferenceEnabled: boolean;
  wawiEnabled: boolean;
  mobileEnabled: boolean;
  nextCustomerWorldEnabled: boolean;
}

export interface FeatureMetaInfo {
  visible: boolean;
}

export interface FeaturesMetaInfo {
  mail: FeatureMetaInfo;
  customAssortment: FeatureMetaInfo;
  videoConference: FeatureMetaInfo;
  wawi: FeatureMetaInfo;
  mobile: FeatureMetaInfo;
  nextCustomerWorld: FeatureMetaInfo;
}

export interface Mobile {
  registrationUrl: string | null;
  masterToken: string | null;
}

export interface Settings {
  personalCode?: string;
  mobile: Mobile;
  currency: Currency;
  bookmarks: Bookmarks;
  initialSetup: InitialSetupSettings;
  customerWorld: CustomerWorldSettings;
  mail: MailSettings;
  printing: PrintingSettings;
  toolSettings: ToolSettings;
  updater: UpdaterSettings;
  customization: CustomizationSettings;
  license: LicenseSettings;
  veloconnect: VeloconnectSettings;
  assortment: AssortmentSettings;
  features: FeatureFlags;
  featuresMeta: FeaturesMetaInfo;
}
