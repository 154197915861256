import actions from "../../../commons/actions";
import useDispatch from "../../../commons/libs/hooks/use-dispatch";
import useInitializeCore from "../../../commons/libs/hooks/use-initialize-core";
import useOnMount from "../../../commons/libs/hooks/use-on-mount";

const useInitializeApp = () => {
  const dispatch = useDispatch();
  const { initializeCore } = useInitializeCore();

  useOnMount(() => {
    const fetchWawiConnectionInfo = () => dispatch(actions.wawi.fetchConnectionInfo());
    const initDone = () => dispatch(actions.core.initDone());

    const initializeApp = async () => {
      // TODO: Remove await from action calls when loop is removed from the respective reducers
      await initializeCore();
      await fetchWawiConnectionInfo();
      initDone();
    };

    initializeApp();
  });
};

export default useInitializeApp;
