import { uniq } from "lodash";
import mixpanel, { Dict } from "mixpanel-browser";
import PQueue from "p-queue";
import { Dispatch, Middleware } from "redux";

import { getServiceTag } from "../../../../commons/libs/env-service";
import { ActiveFilters, FilterKey } from "../../../../commons/specs/filters";
import { Product, ProductId, ProductKey, ProductSpecKey, ProductType } from "../../../../commons/specs/product";
import { Slide } from "../../../../commons/types/customer-world";
import { PrintType } from "../../../../commons/types/print-mail";
import { Actions } from "../../../commons/actions";
import { MixpanelInitConfig } from "../../config/config-types";
import { State } from "../../reducers";
import { selectInitializedEnv, selectInitializedSettings } from "../selectors";

import { Sentry } from "./sentry";

const session: {
  active: boolean;
  idle: boolean;
  started: number;
  interactionCount: number;
  timeout: NodeJS.Timeout | undefined;
} = {
  active: false,
  idle: false,
  started: 0,
  interactionCount: 0,
  timeout: undefined
};

export enum TrackingEvent {
  AppStarted = "App started",
  PageViewed = "Page viewed",
  BookmarkCreated = "Bookmark created",
  BookmarkRenamed = "Bookmark renamed",
  BookmarkRemoved = "Bookmark removed",
  BookmarkCleared = "Bookmark cleared",
  AddedToBookmark = "Added to bookmark",
  RemovedFromBookmark = "Removed from bookmark",
  Printed = "Printed",
  ProductPrinted = "Product printed",
  PrintFailed = "Print failed",
  Mailed = "Mailed",
  ProductMailed = "Product mailed",
  MailFailed = "Mail failed",
  ErrorSet = "Error set",
  SessionStarted = "Session started",
  SessionEnded = "Session ended",
  SessionIdled = "Session idled",
  ToolStarted = "Tool started",
  ToolEnded = "Tool ended",
  BrowserPageViewed = "Browser page viewed",
  ToolConverted = "Tool converted",
  ProductsCompared = "Products compared",
  FilterModalOpened = "Filter modal opened",
  FilterModalClosed = "Filter modal closed",
  FilterModalChanged = "Filter changed",
  FilterCleared = "Filter cleared",
  ProductViewed = "Product viewed",
  ProductAvailabilityViewed = "Product availability viewed",
  CustomerWorldViaTimeout = "CustomerWorld started via timeout",
  CustomerWorldStarted = "CustomerWorld started",
  CustomerWorldEnded = "CustomerWorld ended",
  CustomerWorldEndedBy = "CustomerWorld ended by",
  CustomerWorldSlideInteracted = "CustomerWorld slide interacted",
  VideoConferenceStarted = "VideoConference started",
  VideoConferenceEnded = "VideoConference ended",
  ProductConfiguratorOpened = "Product configurator opened",
  ProductHighlightsOpened = "Product highlights opened",
  ModalOpened = "Modal opened",
  ModalClosed = "Modal closed",
  AlteosAdvertismentClicked = "Alteos advertisement clicked",
  AdacAdvertisementClicked = "ADAC advertisement clicked",
  ZegPlusGarantieAdvertisementClicked = "ZegPlusGarantie advertisement clicked",
  ProductDetailsNavigated = "Product details navigated",
  ProductDetailsImageZoomOpened = "Product details image zoom opened",
  ProductDetailsImageZoomClosed = "Product details image zoom closed",
  ProductSpecificationsOpened = "Product specifications opened",
  ProductSpecificationsClosed = "Product specifications closed",
  ProductVariantsOpened = "Product variants opened",
  ProductVariantsClosed = "Product variants closed",
  BrandWorldClickedOnProductDetails = "BrandWorld clicked on product details"
}

interface PrintContext {
  type: PrintType;
  numCopies: string;
  initiator?: string;
}

interface MailContext {
  type: PrintType;
  initiator?: string;
}

const convertSlideToMixpanelParams = (slide: Slide) => ({
  slideType: slide.type,
  id: slide.id,
  package: slide.package,
  associatedTools: slide.associated ? slide.associated.tools : undefined,
  associatedBrands: slide.associated ? slide.associated.brands : undefined
});

const getProductProperties = (product: Product) => ({
  productId: product[ProductKey.ProductId],
  modelName: product[ProductSpecKey.ModelName],
  brandId: product[ProductSpecKey.BrandId],
  brandName: product[ProductSpecKey.BrandName],
  brandKey: product[ProductSpecKey.BrandKey]
});

export interface MixpanelConfig {
  appVersion: string;
  sentry: Sentry;
  config: MixpanelInitConfig;
}

const initMixpanel = ({ appVersion, sentry, config }: MixpanelConfig) => {
  const onInteraction = () => {
    session.interactionCount += 1;
    beginOrRefreshSession();
  };

  const trackingQueue = new PQueue({ autoStart: false, concurrency: 1 });

  /**
   * Initializes mixpanel and identifies the user.
   *
   * @param token
   * @param debug If you want to enable the debug mode of mixpanel, set this parameter to `true`.
   */
  const init = async (token: string, debug: boolean = false) => {
    mixpanel.init(token, { disable_cookie: true, debug });
    trackAppStarted();

    (window as Window).addEventListener("mousedown", onInteraction);
    (window as Window).addEventListener("touchstart", onInteraction);

    try {
      const serviceTag = await getServiceTag();

      if (serviceTag) {
        mixpanel.identify(serviceTag);
        trackingQueue.start();
      }
    } catch (error) {
      console.error("Couldn't configure serviceTag as Mixpanel unique_id.", error);
      sentry.captureException(new Error("Couldn't configure serviceTag as Mixpanel unique_id."));
    }
  };

  const refreshSession = () => {
    if (!session.active) {
      return;
    }

    if (session.timeout) {
      clearTimeout(session.timeout);
    }

    session.timeout = setTimeout(() => endSession(true), config.sessionTimeout);
  };

  const beginSession = () => {
    if (session.active) {
      return;
    }

    session.active = true;
    session.started = new Date().getTime();

    track(TrackingEvent.SessionStarted);
    timeEvent(TrackingEvent.SessionEnded); // To track session duration

    if (session.idle) {
      track(TrackingEvent.SessionIdled);
      session.idle = false;
    }

    // Init session timeout
    refreshSession();
  };

  const beginOrRefreshSession = () => {
    if (session.active) {
      refreshSession();
    } else {
      beginSession();
    }
  };

  const endSession = (timeout: boolean = false) => {
    if (!session.active) {
      return;
    }

    track(TrackingEvent.SessionEnded, { timeout, interactionCount: session.interactionCount });
    timeEvent(TrackingEvent.SessionIdled); // To track idle time with next session start event

    clearTimeout(session.timeout);

    session.timeout = undefined;
    session.started = 0;
    session.interactionCount = 0;
    session.idle = true;
    session.active = false;
  };

  const trackUserProfile = async (state: State) => {
    const env = selectInitializedEnv(state);
    const settings = selectInitializedSettings(state);

    // Register some properties for all events
    mixpanel.register({
      serviceTag: env.serviceTag,
      appVersion,
      deviceType: env.deviceType,
      customerGroup: env.customerGroup,
      currency: settings.currency
    });
  };

  const track = (event: TrackingEvent, properties?: Dict) => {
    trackingQueue.add(() => mixpanel.track(event, properties));
  };

  const timeEvent = (event: string) => {
    trackingQueue.add(() => mixpanel.time_event(event));
  };

  const trackAppStarted = () => {
    track(TrackingEvent.AppStarted);
  };

  const trackPageViewed = (url: string, path: string, params: { [param: string]: string } = {}) => {
    track(TrackingEvent.PageViewed, { url, path, params });
  };

  const trackPrinted = (context: PrintContext) => {
    track(TrackingEvent.Printed, context);
  };

  const trackProductPrinted = (context: PrintContext, product: Product) => {
    track(TrackingEvent.ProductPrinted, { ...context, ...getProductProperties(product) });
  };

  const trackPrintFailed = () => {
    track(TrackingEvent.PrintFailed);
  };

  const trackProductMailed = (context: MailContext, product: Product) => {
    track(TrackingEvent.ProductMailed, { ...context, ...getProductProperties(product) });
  };

  const trackBrandWorldClickedOnProductDetails = (brandWorldName: string, product: Product) => {
    track(TrackingEvent.BrandWorldClickedOnProductDetails, {
      brandWorldName,
      ...getProductProperties(product)
    });
  };

  const trackMailed = (context: MailContext) => {
    track(TrackingEvent.Mailed, context);
  };

  const trackMailFailed = () => {
    track(TrackingEvent.MailFailed);
  };

  const trackProductsCompared = (products: Product[]) => {
    track(TrackingEvent.ProductsCompared, {
      numProducts: products.length,
      numBrands: uniq(products.map(product => product[ProductSpecKey.BrandId])).length
    });
  };

  const trackFilterModalOpened = (type: string) => {
    track(TrackingEvent.FilterModalOpened, { type });
  };

  const trackFilterModalClosed = (type: string) => {
    track(TrackingEvent.FilterModalClosed, { type });
  };

  const trackFilterCleared = (type: string) => {
    track(TrackingEvent.FilterCleared, { type });
  };

  const trackFilterChanged = <Type extends ProductType>(filters: ActiveFilters<Type>, searchTerm: string) => {
    const filterParams = Object.keys(filters ?? {}).reduce(
      (aggr, key) => ({ ...aggr, [`filter.${key}`]: filters?.[key as FilterKey<Type>] }),
      {}
    );

    track(TrackingEvent.FilterModalChanged, {
      ...filterParams,
      searchTerm,
      filters: Object.keys(filters ?? {})
    });
  };

  const trackProductViewed = (product: Product): void => {
    track(TrackingEvent.ProductViewed, {
      ...getProductProperties(product)
    });
  };

  const trackProductAvailabilityViewed = (
    product: Product,
    isWawiActive: boolean,
    isVeloConnectActive: boolean
  ): void => {
    track(TrackingEvent.ProductAvailabilityViewed, {
      isWawiActive,
      isVeloConnectActive,
      ...getProductProperties(product)
    });
  };

  const trackProductConfiguratorOpened = (product: Product): void => {
    track(TrackingEvent.ProductConfiguratorOpened, {
      ...getProductProperties(product),
      configuratorUrl: product[ProductSpecKey.ConfiguratorUrl]
    });
  };

  const trackProductHighlightsOpened = (product: Product): void => {
    track(TrackingEvent.ProductHighlightsOpened, {
      ...getProductProperties(product),
      productHighlightsToolKey: product[ProductSpecKey.ProductHighlightsToolKey]
    });
  };

  const trackToolStarted = (toolName: string) => {
    track(TrackingEvent.ToolStarted, { toolName });
    timeEvent(TrackingEvent.ToolEnded); // To track tool duration
  };

  const trackToolEnded = (toolName: string) => {
    track(TrackingEvent.ToolEnded, { toolName });
  };

  const trackToolConverted = () => {
    track(TrackingEvent.ToolConverted);
  };

  const trackBrowserPageViewed = (url: string, toolName?: string) => {
    track(TrackingEvent.BrowserPageViewed, { url, toolName });
  };

  const trackCustomerWorldViaTimeout = () => {
    track(TrackingEvent.CustomerWorldViaTimeout);
  };

  const trackCustomerWorldStarted = () => {
    track(TrackingEvent.CustomerWorldStarted);
    timeEvent(TrackingEvent.CustomerWorldEnded); // To track customer world duration
  };

  const trackCustomerWorldEnded = () => {
    track(TrackingEvent.CustomerWorldEnded);
  };

  const trackCustomerWorldEndedBy = (endedBy: "Call To Action" | "Home Button") => {
    track(TrackingEvent.CustomerWorldEndedBy, { endedBy });
  };

  const trackCustomerWorldSlideInteracted = (slide: Slide) => {
    track(TrackingEvent.CustomerWorldSlideInteracted, convertSlideToMixpanelParams(slide));
  };

  const trackModalOpened = (contentType: string) => {
    track(TrackingEvent.ModalOpened, { contentType });
    timeEvent(TrackingEvent.ModalClosed); // To track session duration
  };

  const trackModalClosed = () => {
    track(TrackingEvent.ModalClosed);
  };

  const trackVideoConferenceStarted = () => {
    track(TrackingEvent.VideoConferenceStarted);
    timeEvent(TrackingEvent.VideoConferenceEnded); // To track duration
  };

  const trackVideoConferenceEnded = () => {
    track(TrackingEvent.VideoConferenceEnded);
  };

  const trackAlteosAdvertisementClicked = (shopUrl: string, product: Product) => {
    track(TrackingEvent.AlteosAdvertismentClicked, { shopUrl, ...getProductProperties(product) });
  };

  const trackAdacAdvertisementClicked = (shopUrl: string, product: Product) => {
    track(TrackingEvent.AdacAdvertisementClicked, { shopUrl, ...getProductProperties(product) });
  };

  const trackZegPlusGarantieAdvertisementClicked = (product: Product) => {
    track(TrackingEvent.ZegPlusGarantieAdvertisementClicked, { ...getProductProperties(product) });
  };

  const trackProductDetailsNavigated = (
    interaction: "click" | "swipe",
    direction: "next" | "prev",
    fromProduct: {
      productId: ProductId;
      brandKey: string;
      modelName: string;
    },
    toProductId: ProductId
  ) => {
    track(TrackingEvent.ProductDetailsNavigated, { interaction, direction, fromProduct, toProductId });
  };

  const trackProductDetailsImageZoomOpened = (product: Product) => {
    track(TrackingEvent.ProductDetailsImageZoomOpened, getProductProperties(product));
    timeEvent(TrackingEvent.ProductDetailsImageZoomClosed); // To track duration
  };

  const trackProductDetailsImageZoomClosed = (product: Product) => {
    track(TrackingEvent.ProductDetailsImageZoomClosed, getProductProperties(product));
  };

  const trackProductSpecificationsOpened = (product: Product): void => {
    track(TrackingEvent.ProductSpecificationsOpened, getProductProperties(product));
    timeEvent(TrackingEvent.ProductSpecificationsClosed); // To track duration
  };

  const trackProductSpecificationsClosed = (product: Product): void => {
    track(TrackingEvent.ProductSpecificationsClosed, getProductProperties(product));
  };

  const trackProductVariantsOpened = (product: Product): void => {
    track(TrackingEvent.ProductVariantsOpened, getProductProperties(product));
    timeEvent(TrackingEvent.ProductVariantsClosed); // To track duration
  };

  const trackProductVariantsClosed = (product: Product): void => {
    track(TrackingEvent.ProductVariantsClosed, getProductProperties(product));
  };

  const trackingMiddleware: Middleware<{}, State, Dispatch<Actions>> = store => next => (action: Actions) => {
    next(action);

    const state = store.getState();

    switch (action.type) {
      // Init
      case "CORE.INIT_DONE":
        trackUserProfile(state);
        break;

      // Bookmarks
      case "BOOKMARKS.SAVE":
        track(TrackingEvent.BookmarkCreated);
        break;
      case "BOOKMARKS.RENAME":
        track(TrackingEvent.BookmarkRenamed);
        break;
      case "BOOKMARKS.CLEAR":
        track(TrackingEvent.BookmarkCleared);
        break;
      case "BOOKMARKS.DELETE":
        track(TrackingEvent.BookmarkRemoved);
        break;
      case "BOOKMARKS.ADD_PRODUCT":
        track(TrackingEvent.AddedToBookmark);
        break;
      case "BOOKMARKS.REMOVE_PRODUCT":
        track(TrackingEvent.RemovedFromBookmark);
        break;
      case "BOOKMARKS.COMPARE_SETUP":
        trackProductsCompared(action.payload.products);
        break;

      // Modal
      case "MODAL.OPEN":
        trackModalOpened(action.payload.contentType);
        break;
      case "MODAL.CLOSE":
        trackModalClosed();
        break;

      case "CUSTOMER_WORLD.VIA_TIMEOUT":
        endSession();
        trackCustomerWorldViaTimeout();
        break;

      // Error
      case "ERROR.SET":
        track(TrackingEvent.ErrorSet);
        break;

      default:
        break;
    }
  };

  return {
    init,
    refreshSession,
    beginSession,
    beginOrRefreshSession,
    endSession,
    track,
    trackAppStarted,
    trackPageViewed,
    trackPrinted,
    trackProductPrinted,
    trackPrintFailed,
    trackMailed,
    trackProductMailed,
    trackMailFailed,
    trackProductsCompared,
    trackFilterModalOpened,
    trackFilterModalClosed,
    trackFilterCleared,
    trackFilterChanged,
    trackProductViewed,
    trackProductAvailabilityViewed,
    trackProductConfiguratorOpened,
    trackProductHighlightsOpened,
    trackToolStarted,
    trackToolEnded,
    trackToolConverted,
    trackBrowserPageViewed,
    trackCustomerWorldViaTimeout,
    trackCustomerWorldStarted,
    trackCustomerWorldSlideInteracted,
    trackCustomerWorldEnded,
    trackCustomerWorldEndedBy,
    trackModalOpened,
    trackModalClosed,
    trackVideoConferenceStarted,
    trackVideoConferenceEnded,
    trackAlteosAdvertisementClicked,
    trackAdacAdvertisementClicked,
    trackZegPlusGarantieAdvertisementClicked,
    trackProductDetailsNavigated,
    trackProductDetailsImageZoomOpened,
    trackProductDetailsImageZoomClosed,
    trackProductSpecificationsOpened,
    trackProductSpecificationsClosed,
    trackProductVariantsOpened,
    trackProductVariantsClosed,
    trackBrandWorldClickedOnProductDetails,
    trackingMiddleware
  };
};

export default initMixpanel;
