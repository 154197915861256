import React from "react";

import { waitPromise } from "../../../../commons/libs/promise";
import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import useIntersect from "../../libs/hooks/use-intersect";

interface Props extends Component {
  source?: string;
}

const TRANSPARENT_PIXEL = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
const NUM_RETRIES = 10;
const POLL_DELAY = 1000;

const loadImage = (source: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = resolve;
    img.onerror = reject;
    img.src = source;
  });

const PdfPage = ({ classNames = [], source = "" }: Props) => {
  const [setNode, observerEntry] = useIntersect({ rootMargin: "300px 300px", once: true });
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const pollRef = React.useRef<string>();

  const isIntersecting = observerEntry && observerEntry.isIntersecting;

  React.useEffect(() => {
    const clearPoll = () => {
      pollRef.current;
    };

    const pollImage = async (src: string, retries: number) => {
      try {
        await loadImage(source);

        if (pollRef.current !== src) {
          return;
        }

        setLoaded(true);
        clearPoll();
      } catch (error) {
        if (retries > 0) {
          await waitPromise(POLL_DELAY);
          await pollImage(src, retries - 1);
        } else {
          clearPoll();
        }
      }
    };

    const startPoll = (src: string, retries: number) => {
      pollRef.current = src;
      pollImage(src, retries);
    };

    if (pollRef.current !== source) {
      if (source) {
        startPoll(source, NUM_RETRIES);
      } else {
        clearPoll();
        setLoaded(false);
      }
    }

    return () => {
      clearPoll();
    };
  }, [isIntersecting, source]);

  return (
    <div className={cn("PdfPage", [{ loaded }], classNames)} ref={setNode}>
      <img className="PdfPage__image" src={loaded && source ? source : TRANSPARENT_PIXEL} alt="" />
    </div>
  );
};

export default PdfPage;
